import React, { useEffect, useState } from 'react'
import { OPEN_CATS_URL } from '../../config';
import { getToken } from '../../storage';
import Candidate from './candidate';
import Contact from './contact';
import Modal from 'react-modal';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';

export default function ExistEvent(props) {

    const [type, setType] = useState('candidate')
    const [datePicker, setDatePicker] = useState(false)
    const [dateRange, setDateRange] = useState([{
        startDate: moment().startOf('week').toDate(),
        endDate: moment().endOf('week').toDate(),
        key: 'selection',
    }])

    useEffect(() => {
        setDateRange([{
            startDate: moment().startOf('week').toDate(),
            endDate: moment().endOf('week').toDate(),
            key: 'selection',
        }])
    }, [new URLSearchParams(window.location.search).get('type')])

    const handleDateRange = (dateRange) => {
        setDateRange(dateRange)
    }
    const handleSearch = async () => {
        setDatePicker(false)
    }

    return (
        <div>
            <div className="p-2">
                {
                    props.existEvent && (
                        <div>
                            <div className="mt-1 mb-2 ml-3 col-8 row">
                                <select className="form-control col-4 mr-1" onChange={(e) => setType(e.target.value)}>
                                    <option value={type}>{type}</option>
                                    <option value="candidate">Candidates</option>
                                    <option value="contact">Contacts</option>
                                </select>

                            </div>
                            <div className='mt-1 mb-2 ml-3 col-8 row'>
                                <button type="button col-2" className='btn btn-outline-dark btn-sm col-3' onClick={() => setDatePicker(!datePicker)}>
                                    Select Date Range
                                </button>
                                <label className='mb-1 mt-1 col-6'> Date Range Between {moment(dateRange[0]?.startDate).format('MM-DD-YYYY')} and {moment(dateRange[0]?.endDate).format('MM-DD-YYYY')}</label>
                            </div>
                            {
                                <div style={{ margin: 'auto', width: '95%', fontSize: '13px' }}>
                                    <div className="mb-5">
                                        {
                                            type === "candidate" ?
                                                <Candidate url={`${OPEN_CATS_URL}/eventRouter/getEventsInfo?id=${getToken('id')}&event=${type}&dateRange=${JSON.stringify(dateRange)}`} />
                                                :
                                                <Contact url={`${OPEN_CATS_URL}/eventRouter/getEventsInfo?id=${getToken('id')}&event=${type}&dateRange=${JSON.stringify(dateRange)}`} />
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    )
                }

            </div>
            <Modal
                isOpen={datePicker}
                onRequestClose={() => setDatePicker(false)}
                style={customStyles}
                shouldCloseOnOverlayClick={true}
            >
                <div>
                    <DateRangePicker
                        ranges={dateRange}
                        onChange={(item) => handleDateRange([item.selection])}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        direction="horizontal"
                    />
                </div>
                <center>
                    <button type="button" className='btn btn-outline-dark btn-sm' onClick={() => handleSearch()}>
                        Search
                    </button>
                    <button type="button" className='btn btn-outline-dark btn-sm ml-2' onClick={() => setDatePicker(false)}>
                        Cancel
                    </button>
                </center>
            </Modal>
        </div >
    )
}

const customStyles = {
    content: {
        position: 'absolute',
        inset: '40px',
        border: '1px solid rgb(204, 204, 204)',
        background: 'rgb(255, 255, 255)',
        overflow: 'auto',
        borderRadius: '4px',
        outline: 'none',
        padding: '20px',
        width: 'fit-content',
        height: 'fit-content',
        margin: 'auto'
    }
};