import React, { useEffect, useState } from 'react'
import { OPEN_CATS_URL } from '../../config';
import $ from 'jquery'
import Modal from 'react-modal';
import moment from 'moment';
import EditCandidate from '../Candidates/editCandidate';
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const GetRecruiters = (props) => {
    const [count, setCount] = useState([])
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        getCandidateRecruiters()
        //eslint-disable-next-line
    }, [])

    const getCandidateRecruiters = async () => {
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/listRouter/getCandidateRecruiters`,
            {
                method: 'POST',
                body: JSON.stringify({
                    list_id: props.list_id,
                    candidate_id: props.candidate_id
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => response.json())
            .then((result) => {
                setCount(result.data)
                setLoader(false)
            })
            .catch((error) => {
                setLoader(false)
                console.error('Error:', error);
            });
        setLoader(false)
    }



    const arrayUniqueByKey = [...new Map(count?.map(item =>
        [item['user_id'], item])).values()];

    return (<div style={{ height: '70px', overflow: 'auto', fontSize: '10px' }}>
        <label>{loader ? 'Loading...' : arrayUniqueByKey?.filter(y => {
            return y.removed_at === null
        }).map(x => {
            return `${x.first_name} ${x.last_name}`
        }).join(', ')}  </label>

    </div>
    )
}

const Status = (props) => {
    const [count, setCount] = useState()

    useEffect(() => {
        getLatestStatus()
        //eslint-disable-next-line
    }, [])

    const getLatestStatus = async (id) => {
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getLatestStatus`, {
            method: 'POST',
            body: JSON.stringify({ candidateId: props.candidateId, joborderId: props.joborderId }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {
                setCount(result.data[0].short_description)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    return (
        <div>{count || "1.No Contact"}  </div>
    )
}

export default function List(props) {
    const [listData, setListData] = useState([])
    const [loader, setLoader] = useState(false)
    const [listView, setListView] = useState()
    const [listModal, setListModal] = useState(false)
    const [listInfoData, setListInfoData] = useState([])
    const [selectedListData, setSelectedListData] = useState()
    const [user, setUser] = useState()
    const [modalIsOpen, setIsOpen] = useState(false);
    const [pipeLineInfo, setPipeLineInfo] = useState([])
    const [activityInfo, setActivityInfo] = useState([])
    const [fileUrl, setFileUrl] = useState(null)
    const [isFileOpen, setIsFileOpen] = useState(false)
    const [candidateId, setCandidateId] = useState()
    const [attachmentId, setAttachmentId] = useState(0)
    const [viewType, setViewType] = useState('none')
    const [recrutierModal, setRecrutierModal] = useState(false)
    const [recruiterData, setRecruiterData] = useState([])
    const [selectedRecruiter, setSelectedRecruiter] = useState([])
    const [selectedData, setSelectedData] = useState()
    const [candidateRecruiterData, setCandidateRecruiterData] = useState([])

    useEffect(() => {
        if (listView) {
            getAllList()
        }
    }, [listView])

    useEffect(() => {
        if (listData.length > 0) {
            $(document).ready(function () {
                $('#listTable tfoot th').each(function () {
                    var title = $(this).text();
                    $(this).html('<input type="text" placeholder="Search ' + title + '" />');
                });
                var table = window.$('#listTable').DataTable({
                    "order": [[0, "desc"]],
                    mark: true,
                    bDestroy: true,
                })
                table.columns().every(function () {
                    var that = this;

                    $('input', this.footer()).addClass('input-class-footer').on('keyup change', function () {
                        if (that.search() !== this.value) {
                            that
                                .search(this.value)
                                .draw();
                        }
                    });

                });
                $('input.toggle-vis').on('change', function (e) {
                    e.preventDefault()
                    var columnIndex = parseInt($(this).attr('data-column'));
                    table.column(columnIndex).visible(!this.checked);
                });
            })

        }
    }, [listData, new URLSearchParams(window.location.search).get('page')])

    useEffect(() => {
        if (listInfoData.length > 0) {
            $(document).ready(function () {
                window.$('#listInfoTable').DataTable({
                    "order": [[0, "desc"]],
                    mark: true,
                    bDestroy: true,
                })
            })
        }
    }, [listInfoData, new URLSearchParams(window.location.search).get('page')])

    useEffect(() => {
        if (candidateRecruiterData.length > 0) {
            $(document).ready(function () {
                window.$('#candidateRecruiterTable').DataTable({
                    "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
                    dom: 'Bfrtip',
                    "order": [[0, "desc"]],
                    mark: true,
                    "bDestroy": true,
                    buttons: [
                        'pageLength',
                        'selectAll',
                        'selectNone',
                        {
                            extend: 'excelHtml5',
                            autoFilter: true,
                            sheetName: 'Exported data',
                            text: 'Export'

                        }],
                    select: true
                })
            })
        }
    }, [candidateRecruiterData, loader, recrutierModal])

    const getAllList = async () => {
        setLoader(true)
        setListData([])
        await fetch(
            `${OPEN_CATS_URL}/listRouter/getAllList`)
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setListData(result.data)

            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const handleView = (type) => {
        if (type === 'view') {
            setListView(true)
        }
    }

    useEffect(() => {
        if (new URLSearchParams(window.location.search).get('type') === 'view') {
            handleView('view')
        }
    }, [new URLSearchParams(window.location.search).get('type')])

    const handleViewList = async (data) => {
        setSelectedListData(data)
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/listRouter/listInfo`, {
            method: 'POST',
            body: JSON.stringify({
                "saved_list_id": data.savedListID
            }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setListInfoData(result.data)
                setListModal(true)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    function openModal(x) {
        getSingleCandidate(x.candidate_id)
        getCandidateActivity(x)
        getCandidatePipeLines(x)
        // setIsOpen(true)
    }

    const getSingleCandidate = async (id) => {
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getSingleCandidate`, {
            method: 'POST',
            body: JSON.stringify({ candidate_id: id }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {
                setUser(result.data)
                setIsOpen(true)
                setLoader(false)
            })
            .catch((error) => {
                setLoader(false)
                console.error('Error:', error);
            });
    }

    const getCandidatePipeLines = async (data) => {
        setPipeLineInfo([])
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getCandidatePipeLines`,
            {
                method: 'POST',
                body: JSON.stringify({ candidateId: data.candidate_id || data.candidateId }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                setPipeLineInfo(result.data)

            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const getCandidateActivity = async (data) => {
        setActivityInfo([])
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getCandidateActivity`,
            {
                method: 'POST',
                body: JSON.stringify({ candidateId: data.candidate_id || data.candidateId }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                setActivityInfo(result.data)

            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }

    function closeModal() {
        setIsOpen(false);
    }

    const getData = (data) => {
        if (data) {
            return (<p dangerouslySetInnerHTML={{ __html: data.concat(" ") }} />)
        }
    }

    const getValidUrl = (url) => {

        if (url.indexOf("http") === 0) {
            return true
        } else {
            return false
        }
    };

    const downloadUrl = (url, candidate_id, type = "none", attachment_id = 0) => {
        const newUrl = new URL(url);
        if (newUrl.origin === 'http://nats.novisync.com') {
            window.open(newUrl, '_blank').focus();
            props.closeResume()
        } else {
            setFileUrl(`https://docs.google.com/gview?url=${url}&embedded=true`)
            setIsFileOpen(true)
            setCandidateId(candidate_id)
            setAttachmentId(attachment_id)
            setViewType(type)
        }
    }

    const refreshModal = () => {
        var iframe = document.getElementById('gview');
        iframe.src = iframe.src;
    }

    const handleAddRecruiter = (candidate_id, list_id) => {
        setSelectedData({
            candidate_id: candidate_id,
            list_id: list_id
        })
        getRecruiters()
        getCandidateRecruiters(candidate_id, list_id)
    }

    const getRecruiters = async () => {
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/authRouter/getUsers`)
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setRecruiterData(result.data)
                setRecrutierModal(true)
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoader(false)
            });
        setLoader(false)
    }

    const getCandidateRecruiters = async (candidate_id, list_id) => {
        setCandidateRecruiterData([])
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/listRouter/getCandidateRecruiters`,
            {
                method: 'POST',
                body: JSON.stringify({
                    list_id: list_id,
                    candidate_id: candidate_id
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setCandidateRecruiterData(result.data)
            })
            .catch((error) => {
                setLoader(false)
                console.error('Error:', error);
            });
        setLoader(false)
    }

    const usersData = recruiterData?.filter(y => {
        return y.user_name !== "cats@rootadmin" && y.email !== "admin@testdomain.com"
    })?.map(x => {
        return {
            label: `${x.user_name}-${x.email}`,
            value: x.user_id,
        }
    })

    const handleSelect = (selectedOption) => {
        setSelectedRecruiter(selectedOption)
    }

    const handleSaveRecruitercandidate = async () => {
        if (selectedRecruiter.length !== 0) {
            setLoader(true)
            await fetch(
                `${OPEN_CATS_URL}/listRouter/handleSaveRecruitercandidate`,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        recruiterData: selectedRecruiter,
                        selectedData: selectedData
                    }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            )
                .then((response) => response.json())
                .then((result) => {
                    toast.success(result.message)
                    getCandidateRecruiters(selectedData.candidate_id, selectedData.list_id)
                    setSelectedRecruiter([])
                    setLoader(false)

                })
                .catch((error) => {
                    setLoader(false)
                    toast.warning(error.message)
                    console.error('Error:', error);
                });
            setLoader(false)
        }
    }

    const handleRemoveRecruiter = async (id) => {
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/listRouter/handleRemoveRecruiter`,
            {
                method: 'POST',
                body: JSON.stringify({
                    id: id,
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                toast.success(result.message)
                getCandidateRecruiters(selectedData.candidate_id, selectedData.list_id)
            })
            .catch((error) => {
                setLoader(false)
                toast.success(error.message)
                console.error('Error:', error);
            });
        setLoader(false)
    }

    const removeCandidateFromList = async (saved_list_entry_id, list_id) => {
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/listRouter/removeCandidateFromList`,
            {
                method: 'POST',
                body: JSON.stringify({
                    saved_list_entry_id: saved_list_entry_id,
                    list_id: list_id,
                    numberEntries: selectedListData.numberEntries
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setListModal(false)
                getAllList()
                toast.success(result.message)
                setListInfoData(result.data)
                setListModal(true)
            })
            .catch((error) => {
                setLoader(false)
                toast.success(error.message)
                console.error('Error:', error);
            });
        setLoader(false)
    }

    return (
        props?.showList && (
            <div className="ml-4">
                <ToastContainer style={{ zIndex: 9999999999999999 }} />
                <div style={{ textAlign: 'center' }}>
                    {
                        loader &&
                        <label style={{ color: 'black', fontSize: '18px', fontWeight: '500' }}>Loading...</label>
                    }
                </div>
                <div>
                    <div style={{ width: '90%', margin: 'auto', marginBottom: '10px', fontSize: '13px' }}>
                        {
                            listView && listData?.length > 0 &&
                            <div className="row justify-content-center" style={{ overflow: 'auto' }}>
                                <div className="col-auto">
                                    <label>Hide/Unhide Columns</label>
                                    <div style={{ fontSize: '10px' }}>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="1" /> </label><label className='m-1'>Count</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="2" /> </label><label className='m-1'>Description</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="3" /> </label><label className='m-1'>Data Types</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="4" /> </label><label className='m-1'>List</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="5" /> </label><label className='m-1'>Owner</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="6" /> </label><label className='m-1'>Created</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="7" /> </label><label className='m-1'>Modified</label></label>
                                    </div>
                                    <br />
                                    <table id="listTable" className='table table-hover table-sm'>
                                        <thead>
                                            <tr>
                                                <th style={{ display: 'none' }}>savedListID</th>
                                                <th>Count</th>
                                                <th>Description</th>
                                                <th>Data Type</th>
                                                <th>List</th>
                                                <th>Owner</th>
                                                <th>Created</th>
                                                <th>Modified</th>
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr>
                                                <th style={{ display: 'none' }}>savedListID</th>
                                                <th>Count</th>
                                                <th>Description</th>
                                                <th>Data Type</th>
                                                <th>List</th>
                                                <th>Owner</th>
                                                <th>Created</th>
                                                <th>Modified</th>
                                            </tr>
                                        </tfoot>
                                        <tbody>
                                            {
                                                listData?.map((x) => {
                                                    return <tr key={x?.savedListID}>
                                                        <th style={{ display: 'none' }}>{x?.savedListID}</th>
                                                        <td>{x?.numberEntries}</td>
                                                        <td style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleViewList(x)}>{x?.description}</td>
                                                        <td>{x?.dataItemTypeSortDesc}</td>
                                                        <td>{x?.isDynamic === 1 ? "Dynamic" : "Static"}</td>
                                                        <td>{x?.ownerFirstName} {x?.ownerLastName}</td>
                                                        <td>{x?.dateCreated}</td>
                                                        <td>{x?.dateModified}</td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <Modal
                    isOpen={listModal}
                    onRequestClose={() => setListModal(false)}
                    style={customStyles}
                    shouldCloseOnOverlayClick={false}
                >
                    <div>
                        <div>
                            <label><b>List: {selectedListData?.description}</b></label>
                            <button style={{ float: 'right' }} className="btn btn-sm btn-danger" onClick={() => setListModal(false)}>close</button>
                        </div>
                        <br />
                        {
                            listInfoData.length > 0 ?
                                <div>
                                    <table id="listInfoTable" className='table table-hover table-sm'>
                                        <thead>
                                            <tr>
                                                <th style={{ display: 'none' }}>Candidate ID</th>
                                                <th>Resume</th>
                                                <th>Name</th>
                                                <th>Immigration Status</th>
                                                <th>City</th>
                                                <th>State</th>
                                                <th>Owner</th>
                                                <th>Recruiter</th>
                                                <th>Added To List</th>
                                                <th>Add/View</th>
                                                <th>Remove</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                listInfoData?.map(x => {
                                                    return (
                                                        <tr key={x?.removeCandidateFromList}>
                                                            <td style={{ display: 'none' }}>{x.candidate_id}</td>
                                                            <td>
                                                                <label style={{ paddingLeft: '10px', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => {
                                                                    if (getValidUrl(x.url)) {
                                                                        downloadUrl(x.url, x.candidate_id, 'addCandidate', x.attachment_id)
                                                                    } else {
                                                                        downloadUrl(`https://nats.novisync.com/attachments/${x.url}/${x.original_filename}`, x.candidate_id, 'addCandidate', x.attachment_id)
                                                                    }

                                                                }}>

                                                                    <i title="View Resume" class="bi bi-eye"></i>
                                                                </label>

                                                            </td>

                                                            <td style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => openModal(x)}>{x.first_name} {x.last_name}</td>
                                                            <td>{x.immigration_status}</td>
                                                            <td>{x.city}</td>
                                                            <td>{x.state}</td>
                                                            <td>{x.created_first_name} {x.created_last_name}</td>
                                                            <td><GetRecruiters key={loader} candidate_id={x.candidate_id} list_id={x.saved_list_id} /></td>
                                                            <td>{moment(x.saved_list_created_date).format('lll')}</td>
                                                            <td><button className='btn btn-sm btn-outline-dark' type="button" onClick={() => handleAddRecruiter(x.candidate_id, x.saved_list_id)}>Add/View Recruiter</button></td>
                                                            <td><button className='btn btn-sm btn-outline-dark' type="button" onClick={() => removeCandidateFromList(x.saved_list_entry_id, x.saved_list_id)}>Remove From List</button></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <div>No Data Found</div>
                        }


                    </div>
                </Modal >
                <div>
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        style={customStyles}
                        shouldCloseOnOverlayClick={false}
                    >
                        <label><b>{`${user?.result1?.first_name}'s Information`}</b></label>
                        <button className="btn btn-danger float-right mb-2" onClick={closeModal}>close</button>
                        <div>
                            <div>
                                <EditCandidate candidate={user} access={props.access} />
                            </div>
                            <label>Pipeline Information</label>
                            {
                                pipeLineInfo.length > 0 ?
                                    (
                                        <div className="">
                                            <table className="table table-hover table-sm" style={{ width: '100%' }}>
                                                <thead>
                                                    <th>Title</th>
                                                    <th>Company</th>
                                                    <th>Owner</th>
                                                    <th>Added</th>
                                                    <th>Entered By</th>
                                                    <th>Status</th>
                                                    {/* <th>Action</th> */}
                                                </thead>
                                                <tbody>
                                                    {
                                                        pipeLineInfo.map((x, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td style={x.is_hot ? { color: 'red' } : { color: "" }} > {x.title}</td>
                                                                    <td>{x.companyName}</td>
                                                                    <td>{x.first_name} {x.last_name}</td>
                                                                    <td>{moment(x.date_modified).format("MM-DD-YY hh:mm a")}</td>
                                                                    <td>{x.user_name}</td>
                                                                    <td id={`previousStatus${x.candidate_id}`}>{<Status candidateId={x.candidate_id} joborderId={x.joborder_id} />}</td>
                                                                    {/* <td><button className="btn btn-sm btn-outline-primary" onClick={() => handleUpdateJobOrder(x.candidate_id, x.joborder_id, x.title, x.status)}>Update</button></td> */}
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                        </div>

                                    )
                                    :
                                    <div>No Pipeline Data Found</div>
                            }

                            <hr />
                            <label>Activity Information</label>
                            {
                                activityInfo.length > 0 ?
                                    <div className="">
                                        <table className="table table-hover table-sm" style={{ width: '100%' }}>
                                            <thead>
                                                <th>Date</th>
                                                <th>Type</th>
                                                <th>Entered</th>
                                                <th>Regarding</th>
                                                <th>Notes</th>
                                                {/* <th>Action</th> */}
                                            </thead>
                                            <tbody>
                                                {
                                                    activityInfo.map((x, index) => {
                                                        return (
                                                            <tr id={index}>
                                                                <td>{moment(x.date_created).format("MM-DD-YY hh:mm a")}</td>
                                                                <td>{x.short_description}</td>
                                                                <td>{x.user_first_name} {x.user_last_name}</td>
                                                                <td>{x.regarding}</td>
                                                                <td>{getData(x.notes)}</td>
                                                                {/* <td><button className="btn btn-sm btn-outline-primary" onClick={() => handleUpdateActivity(x.candidate_id, x.activity_id, x.regarding, x.short_description, x.notes, x.type)}>Update</button></td> */}
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    <div>No Activity Data Found</div>
                            }
                        </div>
                    </Modal>
                </div>
                <Modal
                    isOpen={isFileOpen}
                    onRequestClose={() => {
                        setIsFileOpen(false)
                    }}
                    style={customStyles}
                    shouldCloseOnOverlayClick={false}
                >

                    <button className="btn btn-danger float-right mb-2" onClick={() => setIsFileOpen(false)}>close</button>
                    <button className="btn btn-info float-right mb-2 mr-2" onClick={() => refreshModal()}>Refresh</button>

                    <div>
                        <iframe frameborder="0" id="gview" key={isFileOpen} title='view' src={fileUrl} style={{ height: '100vh', width: '100%' }}
                            sandbox="allow-scripts allow-same-origin"
                        ></iframe>
                    </div>

                </Modal>
                <Modal
                    isOpen={recrutierModal}
                    onRequestClose={() => {
                        setRecrutierModal(false)
                    }}
                    style={customStyles}
                    shouldCloseOnOverlayClick={false}
                >
                    <button className="btn btn-danger btn-sm float-right mb-2" onClick={() => setRecrutierModal(false)}>close</button>
                    <br />
                    <br />
                    <div className='form-group col-6'>
                        <label>Select Recruiter</label>
                        <Select
                            value={selectedRecruiter}
                            isMulti={true}
                            onChange={(selectedOption) => handleSelect(selectedOption)}
                            options={usersData}
                        />
                        <br />
                        <button className='btn btn-sm btn-outline-dark' type="button" onClick={() => { handleSaveRecruitercandidate() }}>{loader ? "Loading..." : "Add"}</button>
                    </div>
                    {
                        loader ?
                            <center>Loading....</center> :
                            candidateRecruiterData?.length > 0 ?
                                <div>
                                    <table className='table' id="candidateRecruiterTable">
                                        <thead>
                                            <tr>
                                                <th style={{ display: 'none' }}>id</th>
                                                <th>List</th>
                                                <th>Candidate</th>
                                                <th>Recruiter</th>
                                                <th>Added date</th>
                                                <th>Removed date</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                candidateRecruiterData?.map(x => {
                                                    return <tr key={x}>
                                                        <td style={{ display: 'none' }}>{x.id}</td>
                                                        <td>{selectedListData?.description}</td>
                                                        <td>{x.candidate_first_name} {x.candidate_last_name}</td>
                                                        <td>{x.first_name} {x.last_name}</td>
                                                        <td>{moment(x.created_at).format('lll')}</td>
                                                        <td>{moment(x.removed_at).format('lll') === 'Invalid date' ? "-" : moment(x.removed_at).format('lll')}</td>
                                                        <td>{moment(x.removed_at).format('lll') === 'Invalid date' && <button className='btn btn-sm btn-outline-dark' type="button" onClick={() => handleRemoveRecruiter(x.id)}>Remove</button>}
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>


                                :
                                <center>No Data Found</center>
                    }

                </Modal>
            </div >
        )
    )
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        height: '85%',
    },
    overlay: { zIndex: 99999999 }
}