import moment from 'moment'
import React, { useState } from 'react'
import { OPEN_CATS_URL } from '../../config'
import ResumeView from '../Common/ResumeView'
import UpdateFileUpload from '../FileUpload/updateFileUpload'

export default function EditCandidate(props) {
    const [resumeView, setResumeView] = useState(false)
    const [resumeData, setResumeData] = useState()
    const [candidateEdit, setCandidateEdit] = useState(false)
    const [attachNewFile, setAttachNewFile] = useState(false)
    const [candidateUpdatedData, setCandidateUpdatedData] = useState(props.candidate)
    const [loader, setLoader] = useState(false)

    const handleUpdateChange = (e) => {
        setCandidateUpdatedData({
            ...candidateUpdatedData,
            result1: { ...candidateUpdatedData.result1, [e.target.name]: e.target.value }
        })
    }

    const handleUpdate = async () => {
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/updateCandidate`, {
            method: 'POST',
            body: JSON.stringify(candidateUpdatedData),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                // setCandidateUpdatedData(result.data)
                getSingleCandidate(candidateUpdatedData.result1.candidate_id)
                setCandidateEdit(false)
            })
            .catch((error) => {
                setLoader(true)
                console.error('Error:', error);
            });
    }

    const getSingleCandidate = async (id) => {
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getSingleCandidate`, {
            method: 'POST',
            body: JSON.stringify({ candidate_id: id }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {

                setCandidateUpdatedData(result.data)
                setLoader(false)
            })
            .catch((error) => {
                setLoader(false)
                console.error('Error:', error);
            });
    }


    return (
        <div className=''>
            {
                !candidateEdit ?
                    loader ?
                        <center>Loading...</center>
                        :
                        <div className='mt-2 mb-2'>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className='row' style={{ padding: '5px' }}>
                                        <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Name</div>
                                        <div className='col-6' style={{ padding: '10px' }}>{candidateUpdatedData?.result1?.first_name} {candidateUpdatedData?.result1?.middle_name} {candidateUpdatedData?.result1?.last_name}</div>
                                        <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Email</div>
                                        <div className='col-6' style={{ padding: '10px' }}>{candidateUpdatedData?.result1?.email1}</div>
                                        <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>2nd Email</div>
                                        <div className='col-6' style={{ padding: '10px' }}>{candidateUpdatedData?.result1?.email2}</div>
                                        <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Home Phone</div>
                                        <div className='col-6' style={{ padding: '10px' }}>{candidateUpdatedData?.result1?.phone_home}</div>
                                        <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Cell Phone</div>
                                        <div className='col-6' style={{ padding: '10px' }}>{candidateUpdatedData?.result1?.phone_cell}</div>
                                        <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Work Phone</div>
                                        <div className='col-6' style={{ padding: '10px' }}>{candidateUpdatedData?.result1?.phone_work}</div>
                                        <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Best Time To call</div>
                                        <div className='col-6' style={{ padding: '10px' }}>{candidateUpdatedData?.result1?.best_time_to_call}</div>
                                        <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Address</div>
                                        <div className='col-6' style={{ padding: '10px' }}>{candidateUpdatedData?.result1.city} {candidateUpdatedData?.result1.state} {candidateUpdatedData?.result1.country}</div>
                                        <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Website</div>
                                        <div className='col-6' style={{ padding: '10px' }}>{candidateUpdatedData?.result1?.web_site}</div>
                                        <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Source</div>
                                        <div className='col-6' style={{ padding: '10px' }}> {candidateUpdatedData?.result1?.source}</div>
                                        <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Primary Skills</div>
                                        <div className='col-6' style={{ padding: '10px' }}>{candidateUpdatedData?.result1?.primary_skills}</div>
                                        <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Immigration Status</div>
                                        <div className='col-6' style={{ padding: '10px' }}>{candidateUpdatedData?.result1?.immigration_status}</div>
                                        <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Submission Date</div>
                                        <div className='col-6' style={{ padding: '10px' }}>{candidateUpdatedData?.result1?.submission_date}</div>
                                        <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Secondary Skills</div>
                                        <div className='col-6' style={{ padding: '10px' }}>{candidateUpdatedData?.result1?.secondary_skills}</div>
                                        <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Current Customer</div>
                                        <div className='col-6' style={{ padding: '10px' }}>{candidateUpdatedData?.result1?.current_customer}</div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='row' style={{ padding: '10px' }}>
                                        <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Date Available</div>
                                        <div className='col-6' style={{ padding: '10px' }}>{candidateUpdatedData?.result1?.date_available}</div>
                                        <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Current Employer</div>
                                        <div className='col-6' style={{ padding: '10px' }}>{candidateUpdatedData?.result1?.current_employer}</div>
                                        <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Key Skills</div>
                                        <div className='col-6' style={{
                                            padding: '10px', whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }} title={candidateUpdatedData?.result1?.key_skills}>{candidateUpdatedData?.result1?.key_skills}</div>
                                        <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Can Relocate</div>
                                        <div className='col-6' style={{ padding: '10px' }}>{candidateUpdatedData?.result1?.can_relocate === 0 ? "NO" : "YES"}</div>
                                        <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Current Pay</div>
                                        <div className='col-6' style={{ padding: '10px' }}>{candidateUpdatedData?.result1?.current_pay}</div>
                                        <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Desired Pay</div>
                                        <div className='col-6' style={{ padding: '10px' }}>{candidateUpdatedData?.result1?.desired_pay}</div>
                                        {/* <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Pipeline</div>
                            <div className='col-6' style={{ padding: '10px' }}>{candidateUpdatedData?.result1?.value}</div>
                            <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Submitted</div>
                            <div className='col-6' style={{ padding: '10px' }}>{candidateUpdatedData?.result1?.value}</div> */}
                                        <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Created</div>
                                        <div className='col-6' style={{ padding: '10px' }}>{moment(candidateUpdatedData?.result1.date_created).format('MM-DD-YYYY')}{" "}({moment(candidateUpdatedData?.result1.date_created).format('h:mm')}){" "}({candidateUpdatedData?.result1.owner})</div>
                                        <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Owner</div>
                                        <div className='col-6' style={{ padding: '10px' }}>{candidateUpdatedData?.result1?.owner}</div>
                                        <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Employer</div>
                                        <div className='col-6' style={{ padding: '10px' }}>{candidateUpdatedData?.result1.employer}</div>
                                        <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Employer Contact Name</div>
                                        <div className='col-6' style={{ padding: '10px' }}>{candidateUpdatedData?.result1.recruiterFirstName} {candidateUpdatedData?.result1.employer_contact_name}</div>
                                        <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Employer Contact Email</div>
                                        <div className='col-6' style={{ padding: '10px' }}>{candidateUpdatedData?.result1.ownerFirstName} {candidateUpdatedData?.result1.employer_email}</div>
                                        <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Employer Contact Phone</div>
                                        <div className='col-6' style={{ padding: '10px' }}>{candidateUpdatedData?.result1.employer_phone}</div>
                                        <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Remote Only</div>
                                        <div className='col-6' style={{ padding: '10px' }}>{candidateUpdatedData?.result1.remote_only === 0 ? "No" : "Yes"}</div>

                                    </div>
                                </div>
                            </div>
                            <div className='row col-12'>
                                <div className='col-2' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>
                                    Attachments
                                </div>
                                <div className='col-8' style={{ padding: '10px' }}>
                                    {candidateUpdatedData?.result4?.map(x => {
                                        return <>
                                            <label title="View Resume" style={{ paddingLeft: '10px', cursor: 'pointer', textDecoration: 'underline' }}
                                                onClick={() => {
                                                    setResumeView(!resumeView)
                                                    setResumeData({
                                                        url: x?.url,
                                                        candidate_id: candidateUpdatedData.result1.candidate_id,
                                                        original_filename: x?.original_filename
                                                    })
                                                }}
                                            >
                                                <i title="View Resume" class="bi bi-eye"></i> {x.original_filename}
                                            </label>
                                            <br />
                                        </>
                                    })}

                                    {attachNewFile ?
                                        <label title="Cancel Attachment" style={{ paddingLeft: '10px', cursor: 'pointer', textDecoration: 'underline', color: 'red' }}
                                            onClick={() => {
                                                setAttachNewFile(!attachNewFile)

                                            }}
                                        >
                                            <i class="bi bi-x-circle"></i> Cancel Attachment
                                        </label>
                                        :
                                        <label title="Add Attachment" style={{ paddingLeft: '10px', cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}
                                            onClick={() => {
                                                setAttachNewFile(!attachNewFile)
                                                setResumeData({
                                                    candidate_id: candidateUpdatedData.result1.candidate_id,
                                                })
                                            }}
                                        >
                                            <i class="bi bi-plus"></i> Add Attachment
                                        </label>
                                    }
                                    {
                                        attachNewFile &&

                                        <div>
                                            <UpdateFileUpload candidate_id={candidateUpdatedData.result1.candidate_id} handleUpdatedResume={() => {
                                                setAttachNewFile(!attachNewFile)
                                                getSingleCandidate(candidateUpdatedData.result1.candidate_id)
                                            }} />
                                        </div>

                                    }
                                </div>
                            </div>
                            <div className='row col-12'>

                                <div className='col-2' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>
                                    Misc Notes
                                </div>
                                <div className='col-8' style={{ padding: '10px' }}>
                                    {candidateUpdatedData?.result1?.notes}
                                </div>
                            </div>
                            <ResumeView resumeView={resumeView} resumeData={resumeData} closeResume={(data) => setResumeView(false)} access={props.access} />

                        </div>
                    :
                    <div className='mt-2 mb-2'>
                        <div className='mt-2 mb-2'>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='form-group'>
                                                <label>First Name</label>
                                                <input name="first_name" type="text" value={candidateUpdatedData.result1.first_name} className="form-control input-sm" onChange={(e) => handleUpdateChange(e)} />
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='form-group'>
                                                <label>Last Name</label>
                                                <input name="last_name" type="text" value={candidateUpdatedData.result1.last_name} className="form-control input-sm" onChange={(e) => handleUpdateChange(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className=''>
                                        <div className='form-group'>
                                            <label>Email</label>
                                            <input name="email1" type="text" value={candidateUpdatedData.result1.email1} className="form-control input-sm" onChange={(e) => handleUpdateChange(e)} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='form-group'>
                                                <label>Cell Phone</label>
                                                <input name="phone_cell" type="text" value={candidateUpdatedData.result1.phone_cell} className="form-control input-sm" onChange={(e) => handleUpdateChange(e)} />
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='form-group'>
                                                <label>Work Phone</label>
                                                <input name="phone_work" type="text" value={candidateUpdatedData.result1.phone_work} className="form-control input-sm" onChange={(e) => handleUpdateChange(e)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className='form-group'>
                                                <label>Country</label>
                                                <input name='country' type="text" value={candidateUpdatedData.result1.country} className="form-control input-sm" onChange={(e) => handleUpdateChange(e)} />
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                            <div className='form-group'>
                                                <label>State</label>
                                                <input name="state" type="text" value={candidateUpdatedData.result1.state} className="form-control input-sm" onChange={(e) => handleUpdateChange(e)} />
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                            <div className='form-group'>
                                                <label>City</label>
                                                <input name="city" type="text" value={candidateUpdatedData.result1.city} className="form-control input-sm" onChange={(e) => handleUpdateChange(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className='form-group'>
                                                <label>Priority</label>
                                                <select className='form-control' name="priority" onChange={(e) => handleUpdateChange(e)}>
                                                    <option value={candidateUpdatedData.result1.priority}>{candidateUpdatedData.result1.priority}</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                            <div className='form-group'>
                                                <label>Immigration Status</label>
                                                <select style={{ width: 150 }} name="immigration_status" className="form-control" onChange={(e) => handleUpdateChange(e)} >
                                                    <option value={candidateUpdatedData.result1.immigration_status}>{candidateUpdatedData.result1.immigration_status}</option>
                                                    <option value="H1B">H1B</option>
                                                    <option value="Green Card EAD">Green Card EAD</option>
                                                    <option value="Green Card">Green Card</option>
                                                    <option value="OPT EAD">OPT EAD</option>
                                                    <option value="TN">TN</option>
                                                    <option value="Canadian Citizen">Canadian Citizen</option>
                                                    <option value="Canada PR">Canada PR</option>
                                                    <option value="H4 EAD">H4 EAD</option>
                                                    <option value="Indian Citizen">Indian Citizen</option>
                                                    <option value="Other">Other</option>
                                                    <option value="USC">USC</option>

                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                            <div className='form-group'>
                                                <label>Desired Pay</label>
                                                <input name="desired_pay" type="text" value={candidateUpdatedData.result1.desired_pay} className="form-control input-sm" onChange={(e) => handleUpdateChange(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Notes</label>
                                        <textarea name="notes" className="form-control input-sm" value={candidateUpdatedData.result1.notes} onChange={(e) => handleUpdateChange(e)} />
                                    </div>
                                    <div className='form-group'>
                                        <label>Primary Skills</label>
                                        <textarea name="primary_skills" className="form-control input-sm" value={candidateUpdatedData?.result1.primary_skills} onChange={(e) => handleUpdateChange(e)} />
                                    </div>
                                    <div className='form-group'>
                                        <label>Key Skills</label>
                                        <textarea name="key_skills" className="form-control input-sm" value={candidateUpdatedData.result1.key_skills} onChange={(e) => handleUpdateChange(e)} />
                                    </div>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className='form-group'>
                                                <label>Employer</label>
                                                <input name='employer' type="text" value={candidateUpdatedData.result1.employer} className="form-control input-sm" onChange={(e) => handleUpdateChange(e)} />
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                            <div className='form-group'>
                                                <label>Employer Contact Name</label>
                                                <input name="employer_contact_name" type="text" value={candidateUpdatedData.result1.employer_contact_name} className="form-control input-sm" onChange={(e) => handleUpdateChange(e)} />
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                            <div className='form-group'>
                                                <label>Employer contact Email</label>
                                                <input name="employer_email" type="text" value={candidateUpdatedData.result1.employer_email} className="form-control input-sm" onChange={(e) => handleUpdateChange(e)} />
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                            <div className='form-group'>
                                                <label>Employer contact Phone</label>
                                                <input name="employer_phone" type="text" value={candidateUpdatedData.result1.employer_phone} className="form-control input-sm" onChange={(e) => handleUpdateChange(e)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
            }
            <div className='row col-12 mt-2 mb-2'>
                {
                    candidateEdit ?
                        <>
                            <button disabled={loader} type="button" onClick={() => handleUpdate()} className='btn btn-sm btn-outline-info mr-2'>{loader ? "Loading.." : "Save"}</button>

                            <button type="button" onClick={() => setCandidateEdit(false)} className='btn btn-sm btn-outline-dark mr-2'>Cancel</button>
                        </>
                        :
                        <button type="button" onClick={() => setCandidateEdit(true)} className='btn btn-sm btn-outline-dark mr-2'>Edit</button>

                }
            </div>
        </div >
    )
}