import React, { useState, useEffect } from 'react'
//eslint-disable-next-line
import Select from 'react-select';
import { OPEN_CATS_URL } from '../../config';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import moment from 'moment'
import Modal from 'react-modal';
//eslint-disable-next-line
import { Chart as ChartJS } from 'chart.js/auto'
import { Line } from 'react-chartjs-2';
import $ from 'jquery'
import LoadingOverlay from 'react-loading-overlay';
import { ExportToCsv } from 'export-to-csv';
import Workbook from 'react-excel-workbook'
import SingleJobOrder from '../JobOrder/singleJobOrder';
import EditCandidate from '../Candidates/editCandidate';
import { getToken, setToken } from '../../storage';

const Status = (props) => {
    const [count, setCount] = useState()

    useEffect(() => {
        getLatestStatus()
        //eslint-disable-next-line
    }, [])

    const getLatestStatus = async (id) => {
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getLatestStatus`, {
            method: 'POST',
            body: JSON.stringify({ candidateId: props.candidateId, joborderId: props.joborderId }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {
                setCount(result.data[0]?.short_description)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    return (
        <div>{count || "1.No Contact"}  </div>
    )
}

const Count = (props) => {
    const [count, setCount] = useState(0)

    useEffect(() => {
        getCandidateReportCounts()
        //eslint-disable-next-line
    }, [])

    const getCandidateReportCounts = async (id) => {
        await fetch(
            `${OPEN_CATS_URL}/reportsRouter/getCandidateReportCounts`, {
            method: 'POST',
            body: JSON.stringify({ candidate_id: props.candidate_id, type: props.type, dateRange: props.dateRange }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {
                setCount(result)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    return (
        <label style={{ cursor: 'pointer' }} onClick={() => props.setRowData(count)}>{count.length}  </label>
    )
}

const GetCount = (props) => {
    const [count, setCount] = useState(0)
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        getDifferentReportsCount()
        //eslint-disable-next-line
    }, [])

    const getDifferentReportsCount = async () => {
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/reportsRouter/getDifferentReportsCount`, {
            method: 'POST',
            body: JSON.stringify({ recruiter_id: props.recruiter, type: props.reportType, dateRange: props.dateRange }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {
                setCount(result.data)
                setLoader(false)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    return (
        <label style={{ cursor: 'pointer' }}>{loader ? 'Loading...' : count.length}  </label>
    )
}


export default function ShowReports(props) {

    const [jobOrderTableData, setJobOrderTableData] = useState([])
    const [pipelineTableData, setPipelineTableData] = useState([])
    const [reportTableView, setReportTableView] = useState(false)
    const [submissionTableData, setSubmissionTableData] = useState([])
    const [rncData, setRNCTableData] = useState([])
    const [iwvData, setIWVTableData] = useState([])
    const [rbvData, setRBVTableData] = useState([])
    const [iwcData, setIWCTableData] = useState([])
    const [rbcData, setRBCTableData] = useState([])
    const [nitData, setNITableData] = useState([])
    const [nrcData, setNRCTableData] = useState([])
    const [npdData, setNPDTableData] = useState([])
    const [candidateTableData, setCandidateTableData] = useState([])
    const [companiesTableData, setCompaniesTableData] = useState([])
    const [contactTableData, setContactTableData] = useState([])
    const [reportType, setReportType] = useState("")
    const [reportName, setReportName] = useState("")
    const [activityInfo, setActivityInfo] = useState([])


    useEffect(() => {
        if (reportName !== "") {
            $(document).ready(function () {
                var indexLastColumn = $("#reportsTable").find('tr')[0].cells.length - 1
                window.$('#reportsTable').DataTable({
                    "order": [[indexLastColumn, 'desc']],
                    mark: true,
                    destroy: true
                })
            })
        }
    }, [reportName])




    const handleExportReports = (data, type) => {

        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            title: `${type} - ${moment(dateRange[0]?.startDate).format('MM-DD-YYYY')} to ${moment(dateRange[0]?.endDate).format('MM-DD-YYYY')}`
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };

        const csvExporter = new ExportToCsv(options);

        if (type === 'jobOrders') {
            // setReportName('jobOrders')
            csvExporter.generateCsv(data.map(x => {
                return {
                    "Job Order Id": `NOVIJP00${x.result1.joborder_id}`,
                    "Title": x.result1.title,
                    "Company": x.result1.name,
                    "Submissions": x.result2,
                    "Pipeline": x.result3,
                    "Recruiter": `${x.result1.recruiter_user_first_name} ${x.result1.recruiter_user_last_name}`,
                    "Owner": `${x.result1.owner_user_first_name !== null ? x.result1.owner_user_first_name : ""} ${x.result1.owner_user_last_name ? x.result1.owner_user_last_name : ""}`,
                    "Date Created": moment(x.result1.date_created).format('MM-DD-YY LT')
                }
            }));
        }
        if (type === 'pipeline') {
            // setReportName('pipeline')
            csvExporter.generateCsv(data.map(x => {
                return {
                    "First Name": x.first_name,
                    "Last Name": x.last_name,
                    "Job Order Id": `NOVIJP00${x.joborder_id}`,
                    "Job Order": x.title,
                    "Entered By": `${x.user_first_name} ${x.user_last_name}`,
                    "Date Created": moment(x.date_created).format('MM-DD-YY LT')
                }
            }));
        }
        if (type === 'submissions') {
            // setReportName('submissions')
            csvExporter.generateCsv(data.map(x => {
                return {
                    "First Name": x.first_name,
                    "Last Name": x.last_name,
                    "Candidate Owner": `${x.candidate_user_first_name} ${x.candidate_user_last_name}`,
                    "Job Order Id": `NOVIJP00${x.joborder_id}`,
                    "Job Order": x.title,
                    "Company Name": x.company_name,
                    "Job Order Owner": `${x.job_first_name !== null ? x.job_first_name : ""} ${x.job_last_name !== null ? x.job_last_name : ""}`,
                    "Date Created": moment(x.date).format('MM-DD-YY LT')
                }
            }));
        }
        if (type === 'resumeNotConsideration') {
            // setReportName('resumeNotConsideration')
            csvExporter.generateCsv(data.map(x => {
                return {
                    "First Name": x.first_name,
                    "Last Name": x.last_name,
                    "Job Order": x.title,
                    "Activity": x.activity,
                    // "Notes": <NotesData data={x?.notes} />,
                    "Employer": x.employer,
                    "Entered By": `${x.job_first_name} ${x.job_last_name}`,
                    "Date Created": moment(x.date_created).format('MM-DD-YY LT')
                }
            }));
        }
        if (type === 'interviewWithVendor') {
            // setReportName('interviewWithVendor')
            csvExporter.generateCsv(data.map(x => {
                return {
                    "First Name": x.first_name,
                    "Last Name": x.last_name,
                    "Job Order": x.title,
                    "Activity": x.activity,
                    // "Notes": <NotesData data={x?.notes} />,
                    "Employer": x.Employer,
                    "Entered By": `${x.job_first_name} ${x.job_last_name}`,
                    "Date Created": moment(x.date_created).format('MM-DD-YY LT')
                }
            }));
        }
        if (type === 'rejectedByVendor') {
            // setReportName('rejectedByVendor')
            csvExporter.generateCsv(data.map(x => {
                return {
                    "First Name": x.first_name,
                    "Last Name": x.last_name,
                    "Job Order": x.title,
                    "Activity": x.activity,
                    // "Notes": <NotesData data={x?.notes} />,
                    "Employer": x.Employer,
                    "Entered By": `${x.job_first_name} ${x.job_last_name}`,
                    "Date Created": moment(x.date_created).format('MM-DD-YY LT')
                }
            }));
        }
        if (type === 'interviewWithClient') {
            // setReportName('interviewWithClient')
            csvExporter.generateCsv(data.map(x => {
                return {
                    "First Name": x.first_name,
                    "Last Name": x.last_name,
                    "Job Order": x.title,
                    "Activity": x.activity,
                    // "Notes": <NotesData data={x?.notes} />,
                    "Employer": x.Employer,
                    "Entered By": `${x.job_first_name} ${x.job_last_name}`,
                    "Date Created": moment(x.date_created).format('MM-DD-YY LT')
                }
            }));
        }
        if (type === 'rejectedByClient') {
            // setReportName('rejectedByClient')
            csvExporter.generateCsv(data.map(x => {
                return {
                    "First Name": x.first_name,
                    "Last Name": x.last_name,
                    "Job Order": x.title,
                    "Activity": x.activity,
                    // "Notes": <NotesData data={x?.notes} />,
                    "Employer": x.Employer,
                    "Entered By": `${x.job_first_name} ${x.job_last_name}`,
                    "Date Created": moment(x.date_created).format('MM-DD-YY LT')
                }
            }));
        }
        if (type === 'newInterviews') {
            // setReportName('newInterviews')
            csvExporter.generateCsv(data.map(x => {
                return {
                    "First Name": x.first_name,
                    "Last Name": x.last_name,
                    "Job Order": x.title,
                    "Activity": x.activity,
                    // "Notes": <NotesData data={x?.notes} />,
                    "Employer": x.Employer,
                    "Entered By": `${x.job_first_name} ${x.job_last_name}`,
                    "Date Created": moment(x.date_created).format('MM-DD-YY LT')
                }
            }));
        }
        if (type === 'newRejections') {
            // setReportName('newRejections')
            csvExporter.generateCsv(data.map(x => {
                return {
                    "First Name": x.first_name,
                    "Last Name": x.last_name,
                    "Job Order": x.title,
                    "Activity": x.activity,
                    // "Notes": <NotesData data={x?.notes} />,
                    "Employer": x.Employer,
                    "Entered By": `${x.job_first_name} ${x.job_last_name}`,
                    "Date Created": moment(x.date_created).format('MM-DD-YY LT')
                }
            }));
        }
        if (type === 'newPlacement') {
            // setReportName('newPlacement')
            csvExporter.generateCsv(data.map(x => {
                return {
                    "First Name": x.first_name,
                    "Last Name": x.last_name,
                    "Job Order": x.title,
                    "Candidate Owner": x.candidate_owner,
                    "Job Order Owner": x.joborder_owner,
                    "Date Created": moment(x.date).format('MM-DD-YY LT')
                }
            }));
        }
        if (type === 'newCandidates') {
            // setReportName('newCandidates')
            csvExporter.generateCsv(data.map(x => {
                return {
                    "First Name": x.first_name,
                    "Last Name": x.last_name,
                    "Primary Skill": x.primary_skill,
                    "Candidate Owner": `${x.owner_first_name} ${x.owner_last_name}`,
                    "Date Created": moment(x.date_created).format('MM-DD-YY LT')
                }
            }));
        }
        if (type === 'newCompanies') {
            // setReportName('newCompanies')
            csvExporter.generateCsv(data.map(x => {
                return {
                    "Name": x.name,
                    "Job Count": x.jobcount,
                    "Owner": `${x.owner_first_name} ${x.owner_last_name}`,
                    "Date Created": moment(x.date_created).format('MM-DD-YY LT')
                }
            }));
        }
        if (type === 'newContact') {
            // setReportName('newContact')
            csvExporter.generateCsv(data.map(x => {
                return {
                    "First Name": x.first_name,
                    "Last Name": x.last_name,
                    "Company Name": x.name,
                    "Owner": `${x.owner_first_name} ${x.owner_last_name}`,
                    "Date Created": moment(x.date_created).format('MM-DD-YY LT')
                }
            }));
        }
    }


    const handleOpenReportTable = (data, type) => {

        if (loader === false) {
            if (type === 'Job Orders') {
                setReportName('jobOrders')
                setJobOrderTableData(data.map(x => {
                    return {
                        joborder_id: x.result1.joborder_id,
                        jobId: `NOVIJP00${x.result1.joborder_id}`,
                        title: x.result1.title,
                        company: x.result1.name,
                        submission: x.result2,
                        pipeline: x.result3,
                        recruiter: `${x.result1.recruiter_user_first_name} ${x.result1.recruiter_user_last_name}`,
                        owner: `${x.result1.owner_user_first_name !== null ? x.result1.owner_user_first_name : ""} ${x.result1.owner_user_last_name ? x.result1.owner_user_last_name : ""}`,
                        dateCreated: moment(x.result1.date_created).format('MM-DD-YY LT')
                    }
                }))
            }
            if (type === 'Pipeline') {
                setReportName('pipeline')
                setPipelineTableData(data.map(x => {
                    return {
                        "candidate_id": x.candidate_id,
                        "first_name": x.first_name,
                        "last_name": x.last_name,
                        "joborder_id": `NOVIJP00${x.joborder_id}`,
                        "jobId": x.joborder_id,
                        "title": x.title,
                        "company_name": x.company_name,
                        "enteredBy": `${x.user_first_name} ${x.user_last_name}`,
                        "dateCreated": moment(x.date_created).format('MM-DD-YY LT')
                    }
                }))
            }
            if (type === 'Submissions') {
                setReportName('submissions')
                setSubmissionTableData(data.map(x => {
                    return {
                        "candidate_id": x.candidate_id,
                        "first_name": x.first_name,
                        "last_name": x.last_name,
                        "candidate_owner": `${x.candidate_user_first_name} ${x.candidate_user_last_name}`,
                        "joborder_id": `NOVIJP00${x.joborder_id}`,
                        "jobId": x.joborder_id,
                        "company_name": x.company_name,
                        "title": x.title,
                        "joborder_owner": `${x.job_first_name !== null ? x.job_first_name : ""} ${x.job_last_name !== null ? x.job_last_name : ""}`,
                        "dateCreated": moment(x.date).format('MM-DD-YY LT')
                    }
                }))
            }
            if (type === 'Resume Not Consideration') {
                setReportName('resumeNotConsideration')
                setRNCTableData(data.map(x => {
                    return {
                        "candidate_id": x.candidate_id,
                        "first_name": x.first_name,
                        "last_name": x.last_name,
                        "joborder": x.title,
                        "jobId": x.joborder_id,
                        "activity": x.activity,
                        "notes": <div dangerouslySetInnerHTML={{ __html: x.notes }}></div>,
                        "employer": x.employer,
                        "enteredBy": `${x.job_first_name} ${x.job_last_name}`,
                        "dateCreated": moment(x.date_created).format('MM-DD-YY LT')
                    }
                }))
            }
            if (type === 'Interview With Vendor') {
                setReportName('interviewWithVendor')
                setIWVTableData(data.map(x => {
                    return {
                        "candidate_id": x.candidate_id,
                        "first_name": x.first_name,
                        "last_name": x.last_name,
                        "jobId": x.joborder_id,
                        "joborder": x.title,
                        "activity": x.activity,
                        "notes": <div dangerouslySetInnerHTML={{ __html: x.notes }}></div>,
                        "employer": x.Employer,
                        "enteredBy": `${x.job_first_name} ${x.job_last_name}`,
                        "dateCreated": moment(x.date_created).format('MM-DD-YY LT')
                    }
                }))
            }
            if (type === 'Rejected By Vendor') {
                setReportName('rejectedByVendor')
                setRBVTableData(data.map(x => {
                    return {
                        "candidate_id": x.candidate_id,
                        "first_name": x.first_name,
                        "last_name": x.last_name,
                        "joborder": x.title,
                        "jobId": x.joborder_id,
                        "activity": x.activity,
                        "notes": <div dangerouslySetInnerHTML={{ __html: x.notes }}></div>,
                        "employer": x.Employer,
                        "enteredBy": `${x.job_first_name} ${x.job_last_name}`,
                        "dateCreated": moment(x.date_created).format('MM-DD-YY LT')
                    }
                }))
            }
            if (type === 'Interview With Client') {
                setReportName('interviewWithClient')
                setIWCTableData(data.map(x => {
                    return {
                        "candidate_id": x.candidate_id,
                        "first_name": x.first_name,
                        "last_name": x.last_name,
                        "joborder": x.title,
                        "jobId": x.joborder_id,
                        "activity": x.activity,
                        "notes": <div dangerouslySetInnerHTML={{ __html: x.notes }}></div>,
                        "employer": x.Employer,
                        "enteredBy": `${x.job_first_name} ${x.job_last_name}`,
                        "dateCreated": moment(x.date_created).format('MM-DD-YY LT')
                    }
                }))
            }
            if (type === 'Rejected By Client') {
                setReportName('rejectedByClient')
                setRBCTableData(data.map(x => {
                    return {
                        "candidate_id": x.candidate_id,
                        "first_name": x.first_name,
                        "last_name": x.last_name,
                        "joborder": x.title,
                        "jobId": x.joborder_id,
                        "activity": x.activity,
                        "notes": <div dangerouslySetInnerHTML={{ __html: x.notes }}></div>,
                        "employer": x.Employer,
                        "enteredBy": `${x.job_first_name} ${x.job_last_name}`,
                        "dateCreated": moment(x.date_created).format('MM-DD-YY LT')
                    }
                }))
            }
            if (type === 'New Interviews') {
                setReportName('newInterviews')
                setNITableData(data.map(x => {
                    return {
                        "candidate_id": x.candidate_id,
                        "first_name": x.first_name,
                        "last_name": x.last_name,
                        "joborder": x.title,
                        "jobId": x.joborder_id,
                        "activity": x.activity,
                        "notes": <div dangerouslySetInnerHTML={{ __html: x.notes }}></div>,
                        "employer": x.Employer,
                        "enteredBy": `${x.job_first_name} ${x.job_last_name}`,
                        "dateCreated": moment(x.date_created).format('MM-DD-YY LT')
                    }
                }))
            }
            if (type === 'New Rejections') {
                setReportName('newRejections')
                setNRCTableData(data.map(x => {
                    return {
                        "candidate_id": x.candidate_id,
                        "first_name": x.first_name,
                        "last_name": x.last_name,
                        "joborder": x.title,
                        "jobId": x.joborder_id,
                        "activity": x.activity,
                        "notes": <div dangerouslySetInnerHTML={{ __html: x.notes }}></div>,
                        "employer": x.Employer,
                        "enteredBy": `${x.job_first_name} ${x.job_last_name}`,
                        "dateCreated": moment(x.date_created).format('MM-DD-YY LT')
                    }
                }))
            }
            if (type === 'New Placements') {
                setReportName('newPlacement')
                setNPDTableData(data.map(x => {
                    return {
                        "candidate_id": x.candidate_id,
                        "first_name": x.first_name,
                        "last_name": x.last_name,
                        "title": x.title,
                        "jobId": x.joborder_id,
                        "joborder": x.title,
                        "candidateOwner": x.candidate_owner,
                        "joborderOwner": x.joborder_owner,
                        "date_created": moment(x.date).format('MM-DD-YY LT')
                    }
                }))
            }
            if (type === 'New Candidates') {
                setReportName('newCandidates')
                setCandidateTableData(data.map(x => {
                    return {
                        "candidate_id": x.candidate_id,
                        "first_name": x.first_name,
                        "last_name": x.last_name,
                        "primary_skill": x.primary_skill,
                        "candidate_owner": `${x.owner_first_name !== null ? x.owner_first_name : ""} ${x.owner_last_name !== null ? x.owner_last_name : ""}`,
                        "date_created": moment(x.date_created).format('MM-DD-YY LT')
                    }
                }))
            }
            if (type === 'New Companies') {
                setReportName('newCompanies')
                setCompaniesTableData(data.map(x => {
                    return {
                        "name": x.name,
                        "jobcount": x.jobcount,
                        "owner": `${x.owner_first_name} ${x.owner_last_name}`,
                        "date_created": moment(x.date_created).format('MM-DD-YY LT')
                    }
                }))
            }
            if (type === 'New Contact') {
                setReportName('newContact')
                setContactTableData(data.map(x => {
                    return {
                        "first_name": x.first_name,
                        "last_name": x.last_name,
                        "name": x.name,
                        "owner": `${x.owner_first_name} ${x.owner_last_name}`,
                        "date_created": moment(x.date_created).format('MM-DD-YY LT')
                    }
                }))
            }
            setReportTableView(true)
            setReportType(type)
        }
    }

    const [candidateView, setCandidateView] = useState(false)
    const [jobView, setJobView] = useState(false)
    //eslint-disable-next-line
    const [users, setUsers] = useState([])
    const [filterUsers, setFilterUsers] = useState([])
    const [dateRange, setDateRange] = useState([{
        startDate: moment().startOf('week').toDate(),
        endDate: moment().endOf('week').toDate(),
        key: 'selection',
    }])
    const [datePicker, setDatePicker] = useState(false)
    const [jobData, setJobData] = useState([])
    const [candidateData, setCandidateData] = useState([])
    const [candidateFilter, setCandidateFilter] = useState([])
    const [jobFilter, setJobFilter] = useState([])
    const [candidateCountData, setCandidateCountData] = useState([])
    const [jobsCountData, setJobsCountData] = useState([])
    const [candidateCountFilterData, setCandidateCountFilterData] = useState([])
    const [jobsCountFilterData, setJobsCountFilterData] = useState([])
    const [jobModal, setJobModal] = useState(false)
    const [candidateModal, setCandidateModal] = useState(false)
    const [filterCandidateData, setFilterCandidateData] = useState()
    //eslint-disable-next-line
    const [filterJobData, setFilterJobData] = useState()
    //eslint-disable-next-line
    const [daysList, setDayList] = useState()
    const [candidateChartData, setCandidateChartData] = useState([])
    //eslint-disable-next-line
    const [candidateChartFilterData, setCandidateChartFilterData] = useState([])
    const [jobChartData, setJobChartData] = useState([])
    //eslint-disable-next-line
    const [jobChartFilterData, setJobChartFilterData] = useState([])
    const [candidateTableView, setCandidateTableView] = useState(false)
    const [candidateChartView, setCandidateChartView] = useState(false)
    const [jobTableView, setJobTableView] = useState(false)
    const [jobChartView, setJobChartView] = useState(false)
    const [loader, setLoader] = useState(false)
    const [natReportView, setNatReportView] = useState(false)
    const [accountReportView, setAccountReportView] = useState(false)

    const [newJobOrders, setNewJobOrders] = useState()
    const [newPipelines, setNewPipelines] = useState()
    const [newSubmissions, setNewSubmissions] = useState()
    const [resumeNotConsideration, setResumeNotConsideration] = useState()
    const [interviewingWithVendor, setInterviewingWithVendor] = useState()
    const [rejectedByVendor, setRejectedByVendor] = useState()
    const [interviewingWithClient, setInterviewingWithClient] = useState()
    const [rejectedByClient, setRejectedByClient] = useState()
    const [newInterviews, setNewInterviews] = useState()
    const [newRejections, setNewRejections] = useState()
    const [newPlacements, setNewPlacements] = useState()
    const [newCandidates, setNewCandidates] = useState()
    const [newCompanies, setNewCompanies] = useState()
    const [newContact, setNewContact] = useState()
    const [viewReportData, setViewReportData] = useState(false)
    const [activityReports, setActivityReports] = useState(false)
    const [activityTableView, setActivityTableView] = useState()
    const [activityChartView, setActivityChartView] = useState()
    const [activityCountData, setActivityCountData] = useState([])
    const [activityCountFilterData, setActivityCountFilterData] = useState([])
    const [activityChartData, setActivityChartData] = useState([])
    const [activityChartFilterData, setActivityChartFilterData] = useState([])
    const [pipelineReports, setPipelineReports] = useState(false)
    const [pipelineTableView, setPipelineTableView] = useState(false)
    const [pipelineChartView, setPipelineChartView] = useState(false)
    const [pipelineCountData, setPipelineCountData] = useState([])
    const [pipelineCountFilterData, setPipelineCountFilterData] = useState([])
    const [pipelineChartData, setPipelineChartData] = useState([])
    const [pipelineChartFilterData, setPipelineChartFilterData] = useState([])
    const [candidateStatus, setCandidateStatus] = useState(5)

    const [filterPipelineData, setFilterPipelineData] = useState()
    const [pipelineData, setPipelineData] = useState()
    const [pipelineModal, setPipelineModal] = useState(false)
    const [activityModal, setActivityModal] = useState(false)
    const [filteractivityData, setFilterActivityData] = useState()
    const [activityData, setActivityData] = useState()
    const [name, setName] = useState(null)
    const [pipeLineInfo, setPipeLineInfo] = useState([])
    const [accountReportData, setAccountReportData] = useState([])
    const [accountReportFilterData, setAccountReportFilterData] = useState([])
    const [selectedJobOrderId, setSelectedJobOrderId] = useState()
    const [jobOrderModal, setJobOrderModal] = useState(false)
    const [candidateEditModal, setCandidateEditModal] = useState(false)
    const [candidateUpdatedData, setCandidateUpdatedData] = useState()
    const [newReportView, setNewReportView] = useState(false)
    const [exportReportName, setExportReportName] = useState("Report")
    const [selectedReportUser, setSelectedReportUser] = useState([])
    const [pipelineTableType, setPipelineTableType] = useState(null)

    const bulkExportRefer = React.useRef(null);
    const tableRef = React.useRef(null);
    const exportResumeLink = React.useRef(null)



    const getDynamicReports = async (value, type) => {

        setLoader(true)
        if (type === 'submissions' || type === 'bulkExport') {
            await fetch(
                `${OPEN_CATS_URL}/reportsRouter/getReportsSubmissionCount`,
                {
                    method: 'POST',
                    body: JSON.stringify({ dateRange: dateRange, recruiter_id: value }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then((response) => response.json())
                .then((result) => {
                    setNewSubmissions(result)
                    setSubmissionTableData(result?.data?.map(x => {
                        return {
                            "candidate_id": x.candidate_id,
                            "first_name": x.first_name,
                            "last_name": x.last_name,
                            "candidate_owner": `${x.candidate_user_first_name} ${x.candidate_user_last_name}`,
                            "joborder_id": `NOVIJP00${x.joborder_id}`,
                            "company_name": x.company_name,
                            "title": x.title,
                            "joborder_owner": `${x.job_first_name !== null ? x.job_first_name : ""} ${x.job_last_name !== null ? x.job_last_name : ""}`,
                            "dateCreated": moment(x.date).format('MM-DD-YY LT')
                        }
                    }))
                    if (type !== 'bulkExport') {
                        handleOpenReportTable(result.data, 'Submissions')
                    }
                })
                .catch((error) => {
                    console.log('Error:', error);
                })
        }

        if (type === 'placements' || type === 'bulkExport') {
            await fetch(
                `${OPEN_CATS_URL}/reportsRouter/getReportsPlacementCount`,
                {
                    method: 'POST',
                    body: JSON.stringify({ dateRange: dateRange, recruiter_id: value }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then((response) => response.json())
                .then((result) => {
                    setNewPlacements(result)
                    setNPDTableData(result?.data?.map(x => {
                        return {
                            "candidate_id": x.candidate_id,
                            "first_name": x.first_name,
                            "last_name": x.last_name,
                            "jobId": x.joborder_id,
                            "joborder": x.title,
                            "candidateOwner": x.candidate_owner,
                            "joborderOwner": x.joborder_owner,
                            "date_created": moment(x.date).format('MM-DD-YY LT')
                        }
                    }))
                    if (type !== 'bulkExport') {
                        handleOpenReportTable(result.data, 'New Placements')
                    }
                })
                .catch((error) => {
                    console.log('Error:', error);
                })
        }

        if (type === 'companies' || type === 'bulkExport') {
            await fetch(
                `${OPEN_CATS_URL}/reportsRouter/getReportsCompanyCount`,
                {
                    method: 'POST',
                    body: JSON.stringify({ dateRange: dateRange, recruiter_id: value }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then((response) => response.json())
                .then((result) => {
                    setNewCompanies(result)
                    setCompaniesTableData(result?.data?.map(x => {
                        return {
                            "name": x.name,
                            "jobcount": x.jobcount,
                            "owner": `${x.owner_first_name} ${x.owner_last_name}`,
                            "date_created": moment(x.date_created).format('MM-DD-YY LT')
                        }
                    }))
                    if (type !== 'bulkExport') {
                        handleOpenReportTable(result.data, 'New Companies')
                    }
                })
                .catch((error) => {
                    console.log('Error:', error);
                })
        }

        if (type === 'contact' || type === 'bulkExport') {
            await fetch(
                `${OPEN_CATS_URL}/reportsRouter/getReportsContactCount`,
                {
                    method: 'POST',
                    body: JSON.stringify({ dateRange: dateRange, recruiter_id: value }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then((response) => response.json())
                .then((result) => {
                    setNewContact(result)
                    setContactTableData(result?.data?.map(x => {
                        return {
                            "first_name": x.first_name,
                            "last_name": x.last_name,
                            "name": x.name,
                            "owner": `${x.owner_first_name} ${x.owner_last_name}`,
                            "date_created": moment(x.date_created).format('MM-DD-YY LT')
                        }
                    }))
                    if (type !== 'bulkExport') {
                        handleOpenReportTable(result.data, 'New Contact')
                    }
                })
                .catch((error) => {
                    console.log('Error:', error);
                })
        }
        if (type === 'joborders' || type === 'bulkExport') {
            await fetch(
                `${OPEN_CATS_URL}/reportsRouter/getReportsJobOrderCount`,
                {
                    method: 'POST',
                    body: JSON.stringify({ dateRange: dateRange, recruiter_id: value }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then((response) => response.json())
                .then((result) => {
                    setNewJobOrders(result)
                    setJobOrderTableData(result?.data?.map(x => {
                        return {
                            joborder_id: x.result1.joborder_id,
                            jobId: `NOVIJP00${x.result1.joborder_id}`,
                            title: x.result1.title,
                            company: x.result1.name,
                            submission: x.result2,
                            pipeline: x.result3,
                            recruiter: `${x.result1.recruiter_user_first_name} ${x.result1.recruiter_user_last_name}`,
                            owner: `${x.result1.owner_user_first_name !== null ? x.result1.owner_user_first_name : ""} ${x.result1.owner_user_last_name ? x.result1.owner_user_last_name : ""}`,
                            dateCreated: moment(x.result1.date_created).format('MM-DD-YY LT')
                        }
                    }))
                    if (type !== 'bulkExport') {
                        handleOpenReportTable(result.data, 'Job Orders')
                    }
                })
                .catch((error) => {
                    console.log('Error:', error);
                })
        }

        if (type === 'interviews' || type === 'bulkExport') {
            await fetch(
                `${OPEN_CATS_URL}/reportsRouter/getReportsInterviewCount`,
                {
                    method: 'POST',
                    body: JSON.stringify({ dateRange: dateRange, recruiter_id: value }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then((response) => response.json())
                .then((result) => {
                    setNewInterviews(result)
                    setNITableData(result?.data?.map(x => {
                        return {
                            "candidate_id": x.candidate_id,
                            "first_name": x.first_name,
                            "last_name": x.last_name,
                            "joborder": x.title,
                            "jobId": x.joborder_id,
                            "activity": x.activity,
                            "notes": <div dangerouslySetInnerHTML={{ __html: x.notes }}></div>,
                            "employer": x.Employer,
                            "enteredBy": `${x.job_first_name} ${x.job_last_name}`,
                            "dateCreated": moment(x.date_created).format('MM-DD-YY LT')
                        }
                    }))
                    if (type !== 'bulkExport') {
                        handleOpenReportTable(result.data, 'New Interviews')
                    }
                })
                .catch((error) => {
                    console.log('Error:', error);
                })
        }
        if (type === 'rejections' || type === 'bulkExport') {
            await fetch(
                `${OPEN_CATS_URL}/reportsRouter/getReportsRejectionCount`,
                {
                    method: 'POST',
                    body: JSON.stringify({ dateRange: dateRange, recruiter_id: value }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then((response) => response.json())
                .then((result) => {
                    setNewRejections(result)
                    setNRCTableData(result?.data?.map(x => {
                        return {
                            "candidate_id": x.candidate_id,
                            "first_name": x.first_name,
                            "last_name": x.last_name,
                            "joborder": x.title,
                            "jobId": x.joborder_id,
                            "activity": x.activity,
                            "notes": <div dangerouslySetInnerHTML={{ __html: x.notes }}></div>,
                            "employer": x.Employer,
                            "enteredBy": `${x.job_first_name} ${x.job_last_name}`,
                            "dateCreated": moment(x.date_created).format('MM-DD-YY LT')
                        }
                    }))
                    if (type !== 'bulkExport') {
                        handleOpenReportTable(result.data, 'New Rejections')
                    }

                })
                .catch((error) => {
                    console.log('Error:', error);
                })
        }
        if (type === 'RNC' || type === 'bulkExport') {
            await fetch(
                `${OPEN_CATS_URL}/reportsRouter/getReportsResumeNotConsiderationCount`,
                {
                    method: 'POST',
                    body: JSON.stringify({ dateRange: dateRange, recruiter_id: value }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then((response) => response.json())
                .then((result) => {
                    setResumeNotConsideration(result)
                    setRNCTableData(result?.data?.map(x => {
                        return {
                            "candidate_id": x.candidate_id,
                            "first_name": x.first_name,
                            "last_name": x.last_name,
                            "joborder": x.title,
                            "jobId": x.joborder_id,
                            "activity": x.activity,
                            "notes": <div dangerouslySetInnerHTML={{ __html: x.notes }}></div>,
                            "employer": x.employer,
                            "enteredBy": `${x.job_first_name} ${x.job_last_name}`,
                            "dateCreated": moment(x.date_created).format('MM-DD-YY LT')
                        }
                    }))
                    if (type !== 'bulkExport') {
                        handleOpenReportTable(result.data, 'Resume Not Consideration')
                    }
                })
                .catch((error) => {
                    console.log('Error:', error);
                })
        }
        if (type === 'IVW' || type === 'bulkExport') {
            await fetch(
                `${OPEN_CATS_URL}/reportsRouter/getReportsInterviewWithVendorCount`,
                {
                    method: 'POST',
                    body: JSON.stringify({ dateRange: dateRange, recruiter_id: value }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then((response) => response.json())
                .then((result) => {
                    setInterviewingWithVendor(result)
                    setIWVTableData(result?.data?.map(x => {
                        return {
                            "candidate_id": x.candidate_id,
                            "first_name": x.first_name,
                            "last_name": x.last_name,
                            "joborder": x.title,
                            "jobId": x.joborder_id,
                            "activity": x.activity,
                            "notes": <div dangerouslySetInnerHTML={{ __html: x.notes }}></div>,
                            "employer": x.Employer,
                            "enteredBy": `${x.job_first_name} ${x.job_last_name}`,
                            "dateCreated": moment(x.date_created).format('MM-DD-YY LT')
                        }
                    }))
                    if (type !== 'bulkExport') {
                        handleOpenReportTable(result.data, 'Interview With Vendor')
                    }
                })
                .catch((error) => {
                    console.log('Error:', error);
                })
        }
        if (type === 'RBV' || type === 'bulkExport') {
            await fetch(
                `${OPEN_CATS_URL}/reportsRouter/getReportsRejectedByVendorCount`,
                {
                    method: 'POST',
                    body: JSON.stringify({ dateRange: dateRange, recruiter_id: value }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then((response) => response.json())
                .then((result) => {
                    setRejectedByVendor(result)
                    setRBVTableData(result?.data?.map(x => {
                        return {
                            "candidate_id": x.candidate_id,
                            "first_name": x.first_name,
                            "last_name": x.last_name,
                            "joborder": x.title,
                            "jobId": x.joborder_id,
                            "activity": x.activity,
                            "notes": <div dangerouslySetInnerHTML={{ __html: x.notes }}></div>,
                            "employer": x.Employer,
                            "enteredBy": `${x.job_first_name} ${x.job_last_name}`,
                            "dateCreated": moment(x.date_created).format('MM-DD-YY LT')
                        }
                    }))
                    if (type !== 'bulkExport') {
                        handleOpenReportTable(result.data, 'Rejected By Vendor')
                    }
                })
                .catch((error) => {
                    console.log('Error:', error);
                })
        }
        if (type === 'IWC' || type === 'bulkExport') {
            await fetch(
                `${OPEN_CATS_URL}/reportsRouter/getReportsInterviewWithClientCount`,
                {
                    method: 'POST',
                    body: JSON.stringify({ dateRange: dateRange, recruiter_id: value }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then((response) => response.json())
                .then((result) => {
                    setInterviewingWithClient(result)
                    setIWCTableData(result?.data?.map(x => {
                        return {
                            "candidate_id": x.candidate_id,
                            "first_name": x.first_name,
                            "last_name": x.last_name,
                            "joborder": x.title,
                            "jobId": x.joborder_id,
                            "activity": x.activity,
                            "notes": <div dangerouslySetInnerHTML={{ __html: x.notes }}></div>,
                            "employer": x.Employer,
                            "enteredBy": `${x.job_first_name} ${x.job_last_name}`,
                            "dateCreated": moment(x.date_created).format('MM-DD-YY LT')
                        }
                    }))
                    if (type !== 'bulkExport') {
                        handleOpenReportTable(result.data, 'Interview With Client')
                    }
                })
                .catch((error) => {
                    console.log('Error:', error);
                })
        }
        if (type === 'RBC' || type === 'bulkExport') {
            await fetch(
                `${OPEN_CATS_URL}/reportsRouter/getReportsRejectedByClientCount`,
                {
                    method: 'POST',
                    body: JSON.stringify({ dateRange: dateRange, recruiter_id: value }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then((response) => response.json())
                .then((result) => {
                    setRejectedByClient(result)
                    setRBCTableData(result?.data?.map(x => {
                        return {
                            "candidate_id": x.candidate_id,
                            "first_name": x.first_name,
                            "last_name": x.last_name,
                            "jobId": x.joborder_id,
                            "joborder": x.title,
                            "activity": x.activity,
                            "notes": <div dangerouslySetInnerHTML={{ __html: x.notes }}></div>,
                            "employer": x.Employer,
                            "enteredBy": `${x.job_first_name} ${x.job_last_name}`,
                            "dateCreated": moment(x.date_created).format('MM-DD-YY LT')
                        }
                    }))
                    if (type !== 'bulkExport') {
                        handleOpenReportTable(result.data, 'Rejected By Client')
                    }
                })
                .catch((error) => {
                    console.log('Error:', error);
                })
        }
        if (type === 'pipelines' || type === 'bulkExport') {
            await fetch(
                `${OPEN_CATS_URL}/reportsRouter/getReportsPipelineCount`,
                {
                    method: 'POST',
                    body: JSON.stringify({ dateRange: dateRange, recruiter_id: value }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then((response) => response.json())
                .then((result) => {
                    setNewPipelines(result)
                    setPipelineTableData(result?.data?.map(x => {
                        return {
                            "candidate_id": x.candidate_id,
                            "first_name": x.first_name,
                            "last_name": x.last_name,
                            "joborder_id": `NOVIJP00${x.joborder_id}`,
                            "jobId": x.joborder_id,
                            "title": x.title,
                            "company_name": x.company_name,
                            "enteredBy": `${x.user_first_name} ${x.user_last_name}`,
                            "dateCreated": moment(x.date_created).format('MM-DD-YY LT')
                        }
                    }))
                    if (type !== 'bulkExport') {
                        handleOpenReportTable(result.data, 'Pipeline')
                    }
                })
                .catch((error) => {
                    console.log('Error:', error);
                })
        }
        if (type === 'candidates' || type === 'bulkExport') {
            await fetch(
                `${OPEN_CATS_URL}/reportsRouter/getReportsCandidateCount`,
                {
                    method: 'POST',
                    body: JSON.stringify({ dateRange: dateRange, recruiter_id: value }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then((response) => response.json())
                .then((result) => {
                    setNewCandidates(result)
                    setCandidateTableData(result?.data?.map(x => {
                        return {
                            "candidate_id": x.candidate_id,
                            "first_name": x.first_name,
                            "last_name": x.last_name,
                            "primary_skill": x.primary_skill,
                            "candidate_owner": `${x.owner_first_name !== null ? x.owner_first_name : ""} ${x.owner_last_name !== null ? x.owner_last_name : ""}`,
                            "date_created": moment(x.date_created).format('MM-DD-YY LT')
                        }
                    }))
                    if (type !== 'bulkExport') {
                        handleOpenReportTable(result.data, 'New Candidates')
                    }
                    if (type === 'bulkExport') {
                        bulkExportRefer.current.click();
                    }
                })
                .catch((error) => {
                    console.log('Error:', error);
                })
        }
        setLoader(false)
    }
    const handleView = (type) => {
        if (type === 'pipeline') {
            setCandidateView(false)
            setJobView(false)
            setNatReportView(false)
            setActivityReports(false)
            setPipelineReports(true)
            setAccountReportView(false)
        }
        if (type === 'activity') {
            setCandidateView(false)
            setJobView(false)
            setNatReportView(false)
            setActivityReports(true)
            setPipelineReports(false)
            setAccountReportView(false)
        }
        if (type === 'candidate') {
            setCandidateView(true)
            setJobView(false)
            setNatReportView(false)
            setActivityReports(false)
            setPipelineReports(false)
            setAccountReportView(false)

        }
        if (type === 'job') {
            setCandidateView(false)
            setJobView(true)
            setNatReportView(false)
            setActivityReports(false)
            setPipelineReports(false)
            setAccountReportView(false)
        }
        if (type === 'natReport') {
            setCandidateView(false)
            setJobView(false)
            setNatReportView(true)
            setPipelineReports(false)
            setAccountReportView(false)
            setActivityReports(false)

        }
        if (type === 'accountReports') {
            setCandidateView(false)
            setJobView(false)
            setNatReportView(false)
            setPipelineReports(false)
            setAccountReportView(true)
            handleSearch()
        }
        if (type === 'candidateTable') {
            setCandidateTableView(true)
            setCandidateChartView(false)
        }
        if (type === 'candidateChart') {
            setCandidateTableView(false)
            setCandidateChartView(true)
        }
        if (type === 'jobTable') {
            setJobTableView(true)
            setJobChartView(false)
        }
        if (type === 'jobChart') {
            setJobTableView(false)
            setJobChartView(true)
        }

        if (type === 'activityTable') {
            setActivityTableView(true)
            setActivityChartView(false)
        }
        if (type === 'activityChart') {
            setActivityTableView(false)
            setActivityChartView(true)
        }
        if (type === 'pipelineTable') {
            setPipelineTableView(true)
            setPipelineChartView(false)
        }
        if (type === 'pipelineChart') {
            setPipelineTableView(false)
            setPipelineChartView(true)
        }
    }

    useEffect(() => {
        if (props.showReports) {
            setCandidateView(false)
            setJobView(false)
            fetch(
                `${OPEN_CATS_URL}/reportsRouter/getUsers`)
                .then((response) => response.json())
                .then((result) => {
                    let arr = result.data.filter(y => {
                        return y.first_name !== "1" && y.user_name !== "cats@rootadmin" && y.email !== "admin@testdomain.com"
                    }).map(x => {
                        return {
                            value: x.user_id, label: `${x.first_name}-${x.last_name}-${x.email}`, email: x.email, first_name: x.first_name, last_name: x.last_name
                        }
                    })
                    // arr = [{ label: 'All', value: 'All' }, ...arr]
                    setUsers(arr)
                    // setFilterUsers(arr)

                })
                .catch((error) => {
                    console.log('Error:', error);
                })
        }
    }, [props?.showReports])
    //eslint-disable-next-line
    const handleSelect = (selectedOption, type) => {
        if (type === 'reportCandidate') {
            setCandidateData(selectedOption)
        }
        if (type === "candidate") {
            if (selectedOption.value !== 'All') {
                setCandidateData(candidateFilter.filter(x => {
                    return x.entered_by === selectedOption.value
                }))
                setCandidateCountData(candidateCountFilterData.filter(x => {
                    return x.user_id === selectedOption.value

                }))
            } else {
                setCandidateData(candidateFilter)
                setCandidateCountData(candidateCountFilterData)
            }
        }
        if (type === "job") {
            if (selectedOption.value !== 'All') {
                setJobData(jobFilter.filter(x => {
                    return x.entered_by === selectedOption.value
                }))
                setJobsCountData(jobsCountFilterData.filter(x => {
                    return x.user_id === selectedOption.value

                }))
            } else {
                setJobData(jobFilter)
                setJobsCountData(jobsCountFilterData)
            }
        }
        if (type === "accountReportView") {
            if (selectedOption.label !== 'All') {
                setAccountReportData(accountReportFilterData.filter(x => {
                    return x.owner_email === selectedOption.email
                }))

            } else {
                setAccountReportData(accountReportFilterData)
            }
        }
    }

    useEffect(() => {
        setDateRange([{
            startDate: moment().startOf('week').toDate(),
            endDate: moment().endOf('week').toDate(),
            key: 'selection',
        }])
    }, [new URLSearchParams(window.location.search).get('type')])

    const handleDateRange = (dateRange) => {
        setDateRange(dateRange)
    }

    useEffect(() => {
        handleSearch()
        //eslint-disable-next-line
    }, [candidateView, jobView])

    useEffect(() => {
        if (candidateData.length > 0) {
            $(document).ready(function () {
                window.$('#exampleCandidate').DataTable({
                    bDestroy: true
                })
            })
        }
    }, [candidateData])

    useEffect(() => {
        if (filterUsers.length > 0) {
            $(document).ready(function () {

                window.$('#newReportData').DataTable()
            })
        }
    }, [filterUsers, newReportView, selectedReportUser, getToken('selectedReportUser'), loader])

    useEffect(() => {
        if (jobData.length > 0) {
            $(document).ready(function () {
                window.$('#job-data').DataTable({
                    bDestroy: true
                })
            })
        }
    }, [jobData])

    useEffect(() => {
        if (pipelineData?.length > 0) {
            $(document).ready(function () {
                window.$('#pipeline-data').DataTable({
                    bDestroy: true
                })
            })
        }
    }, [pipelineData])

    useEffect(() => {
        if (pipelineCountData?.length > 0) {
            $(document).ready(function () {
                window.$('#pipelineCountTable').DataTable({
                    "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
                    dom: 'Bfrtip',
                    "order": [[0, "desc"]],
                    mark: true,
                    "bDestroy": true,
                    buttons: [
                        'pageLength',
                        'selectAll',
                        'selectNone',
                        {
                            extend: 'excelHtml5',
                            autoFilter: true,
                            sheetName: 'Exported data',
                            text: 'Export'

                        }],
                    select: true
                })
            })
        }
    }, [pipelineCountData])


    useEffect(() => {
        if (activityData?.length > 0) {
            $(document).ready(function () {
                window.$('#activity-data').DataTable({
                    bDestroy: true,
                    "order": [[0, "desc"]],
                })
            })
        }
    }, [activityData])


    const handleCopyTable = () => {
        function tableToExcel(table, name, filename) {
            let uri = 'data:application/vnd.ms-excel;base64,',
                template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><title></title><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>',
                base64 = function (s) { return window.btoa(decodeURIComponent(encodeURIComponent(s))) }, format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }

            if (!table.nodeType) table = document.getElementById(table)
            var ctx = { worksheet: name || 'Worksheet', table: table.innerHTML }

            var link = document.createElement('a');
            link.download = filename;
            link.href = uri + base64(format(template, ctx));
            link.click();
        }
        tableToExcel('newReportData', 'Recruiter', `Recruiter ${moment(dateRange[0]?.startDate).format('MM-DD-YYYY')} to ${moment(dateRange[0]?.endDate).format('MM-DD-YYYY')}`)

    }

    const handleSearch = async () => {
        setCandidateData([])
        setCandidateFilter([])
        setCandidateCountData([])
        setCandidateCountFilterData([])
        setCandidateChartData([])
        setCandidateChartFilterData([])
        setJobData([])
        setJobFilter([])
        setJobsCountData([])
        setJobsCountFilterData([])
        setJobChartData([])
        setJobChartFilterData([])
        setActivityCountData([])
        setActivityCountFilterData([])
        setActivityChartData([])
        setActivityChartFilterData([])
        setPipelineCountData([])
        setPipelineCountFilterData([])
        setLoader(true)
        setDatePicker(false)
        if (candidateView) {
            await fetch(
                `${OPEN_CATS_URL}/reportsRouter/getCandidateReports`,
                {
                    method: 'POST',
                    body: JSON.stringify(dateRange),
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then((response) => response.json())
                .then((result) => {
                    setCandidateData(result.data)
                    setCandidateFilter(result.data)
                    // setLoader(false)
                })
                .catch((error) => {
                    console.log('Error:', error);
                })
            await fetch(
                `${OPEN_CATS_URL}/reportsRouter/getCandidateCount`,
                {
                    method: 'POST',
                    body: JSON.stringify(dateRange),
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then((response) => response.json())
                .then((result) => {
                    setCandidateCountData(result.data)
                    setCandidateCountFilterData(result.data)
                    setCandidateTableView(true)
                    // setLoader(false)

                })
                .catch((error) => {
                    console.log('Error:', error);
                })
            await fetch(
                `${OPEN_CATS_URL}/reportsRouter/getCandidateChart`,
                {
                    method: 'POST',
                    body: JSON.stringify(dateRange),
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then((response) => response.json())
                .then((result) => {
                    setCandidateChartData(result)
                    setCandidateChartFilterData(result)
                    // setLoader(false)

                })
                .catch((error) => {
                    console.log('Error:', error);
                })
        }
        if (jobView) {
            await fetch(
                `${OPEN_CATS_URL}/reportsRouter/getJobReports`,
                {
                    method: 'POST',
                    body: JSON.stringify(dateRange),
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then((response) => response.json())
                .then((result) => {
                    setJobData(result.data)
                    setJobFilter(result.data)
                    // setLoader(false)

                })
                .catch((error) => {
                    console.log('Error:', error);
                })
            await fetch(
                `${OPEN_CATS_URL}/reportsRouter/getJobsCount`,
                {
                    method: 'POST',
                    body: JSON.stringify(dateRange),
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then((response) => response.json())
                .then((result) => {
                    setJobsCountData(result.data)
                    setJobsCountFilterData(result.data)
                    setJobTableView(true)
                    // setLoader(false)

                })
                .catch((error) => {
                    console.log('Error:', error);
                })
            await fetch(
                `${OPEN_CATS_URL}/reportsRouter/getJobChart`,
                {
                    method: 'POST',
                    body: JSON.stringify(dateRange),
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then((response) => response.json())
                .then((result) => {
                    setJobChartData(result)
                    setJobChartFilterData(result)
                    // setLoader(false)

                })
                .catch((error) => {
                    console.log('Error:', error);
                })
        }
        if (activityReports) {
            await fetch(
                `${OPEN_CATS_URL}/reportsRouter/getActivityCount`,
                {
                    method: 'POST',
                    body: JSON.stringify(dateRange),
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then((response) => response.json())
                .then((result) => {
                    setActivityCountData(result.data)
                    setActivityCountFilterData(result.data)
                    setActivityTableView(true)
                    // setLoader(false)

                })
                .catch((error) => {
                    console.log('Error:', error);
                })
            await fetch(
                `${OPEN_CATS_URL}/reportsRouter/getActivityChart`,
                {
                    method: 'POST',
                    body: JSON.stringify(dateRange),
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then((response) => response.json())
                .then((result) => {
                    setActivityChartData(result)
                    setActivityChartFilterData(result)

                    // setLoader(false)

                })
                .catch((error) => {
                    console.log('Error:', error);
                })
        }
        if (pipelineReports) {
            await fetch(
                `${OPEN_CATS_URL}/reportsRouter/getPipelineCount?candidateStatus=${candidateStatus}`,
                {
                    method: 'POST',
                    body: JSON.stringify(dateRange),
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then((response) => response.json())
                .then((result) => {


                    const array1 = [...new Map(result.data.map(item =>
                        [item['candidate_id'], item])).values()];


                    const array2 = result.data.map(x => {
                        return {
                            candidate_id: x.candidate_id,
                            recruiter_first_name: x.r_first_name,
                            recruiter_last_name: x.r_last_name,
                            recruiter_id: x.r_recruiter_id
                        }
                    })

                    const newArray = array1.map(candidate => {
                        const matchingRecruiters = array2.filter(recruiter => recruiter.candidate_id === candidate.candidate_id);
                        const recruiterNames = matchingRecruiters.map(recruiter => ({
                            recruiter_first_name: recruiter.recruiter_first_name,
                            recruiter_last_name: recruiter.recruiter_last_name,
                            recruiter_id: recruiter.recruiter_id
                        }));
                        return { ...candidate, ...[recruiterNames] };
                    });


                    setPipelineCountData(newArray)
                    setPipelineCountFilterData(newArray)
                    setPipelineTableView(true)
                    // setLoader(false)

                })
                .catch((error) => {
                    console.log('Error:', error);
                })
        }
        if (accountReportView) {
            await fetch(
                `${OPEN_CATS_URL}/reportsRouter/accountReports`,
                {
                    method: 'POST',
                    body: JSON.stringify({ dateRange: dateRange, candidateData: candidateData }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then((response) => response.json())
                .then((result) => {
                    setAccountReportData(result.data)
                    setAccountReportFilterData(result.data)
                })
                .catch((error) => {
                    console.log('Error:', error);
                })
        }
        setLoader(false)
    }

    const handleJob = (data) => {
        setFilterJobData(jobFilter.filter(x => {
            return x?.result1?.entered_by === data.user_id
        }))

        setJobData(jobFilter.filter(x => {
            return x?.result1?.entered_by === data.user_id
        }))

        setJobModal(true)
    }

    const handleCandidate = (data) => {

        setFilterCandidateData(candidateFilter.filter(x => {
            return x.entered_by === data.user_id
        }))
        setCandidateData(candidateFilter.filter(x => {
            return x.entered_by === data.user_id
        }))
        setCandidateModal(true)

    }
    const handlePipeline = (data, name) => {
        setFilterPipelineData(data)
        setPipelineData(data)
        setName(name)
        setPipelineModal(true)
    }

    const handleActivity = (data, name) => {
        setFilterActivityData(data)
        setActivityData(data)
        setName(name)
        setActivityModal(true)
    }


    const handleDataSearch = (e, type) => {

        if (type === 'candidateData') {
            if (e.target.value.length !== undefined) {
                setCandidateData(filterCandidateData.filter((y) => {
                    return y.first_name?.toLowerCase().includes(e.target.value?.toLowerCase()) || y.last_name?.toLowerCase().includes(e.target.value?.toLowerCase()) || y.email1?.toLowerCase().includes(e.target.value?.toLowerCase()) || y.phone_home?.toLowerCase().includes(e.target.value?.toLowerCase()) || y.created_by?.toLowerCase().includes(e.target.value?.toLowerCase()) || moment(y.date_created).format("MM-DD-YY hh:mm")?.toLowerCase().includes(e.target.value?.toLowerCase())
                }))
            } else {
                setCandidateData(filterCandidateData)
            }
        }
        if (type === 'candidateCountData') {
            if (e.target.value.length !== undefined) {

                setCandidateCountData(candidateCountFilterData.filter((y) => {
                    return y.first_name?.toLowerCase().includes(e.target.value?.toLowerCase()) || y.last_name?.toLowerCase().includes(e.target.value?.toLowerCase())
                }))
            } else {
                setCandidateCountData(candidateCountFilterData)
            }
        }
        if (type === 'jobCountData') {
            if (e.target.value.length !== undefined) {

                setJobsCountData(jobsCountFilterData.filter((y) => {
                    return y.first_name?.toLowerCase().includes(e.target.value?.toLowerCase()) || y.last_name?.toLowerCase().includes(e.target.value?.toLowerCase())
                }))
            } else {
                setJobsCountData(jobsCountFilterData)
            }
        }
        if (type === 'jobData') {
            if (e.target.value.length !== undefined) {

                setJobData(jobFilter.filter((y) => {
                    return y.title?.toLowerCase().includes(e.target.value?.toLowerCase()) || y.city?.toLowerCase().includes(e.target.value?.toLowerCase()) || y.state?.toLowerCase().includes(e.target.value?.toLowerCase()) || y.created_by?.toLowerCase().includes(e.target.value?.toLowerCase()) || y.created_by?.toLowerCase().includes(e.target.value?.toLowerCase()) || moment(y.date_created).format("MM-DD-YY hh:mm")?.toLowerCase().includes(e.target.value?.toLowerCase())
                }))
            } else {
                setJobData(jobFilter)
            }
        }
    }



    useEffect(() => {
        if (props?.access?.access_level === 300 || props?.access?.access_level === 400 || props?.access?.access_level === 500) {
            if (new URLSearchParams(window.location.search).get('type') === 'reports') {
                handleView('natReport')
                setNewReportView(true)
                if (JSON.parse(getToken('selectedReportUser')) !== null) {
                    setSelectedReportUser(JSON.parse(getToken('selectedReportUser')))
                    setFilterUsers(JSON.parse(getToken('selectedReportUser')))
                }
            }
        }
        if (new URLSearchParams(window.location.search).get('type') === 'candidate') {
            handleView('candidate')
        }
        if (new URLSearchParams(window.location.search).get('type') === 'joborder') {
            handleView('job')
        }
        if (new URLSearchParams(window.location.search).get('type') === 'activity') {
            handleView('activity')
            handleSearch()
        }
        if (new URLSearchParams(window.location.search).get('type') === 'accountReports') {
            handleView('accountReports')
        }
        if (new URLSearchParams(window.location.search).get('type') === 'pipeline') {
            handleView('pipeline')
            setCandidateStatus(1)
        }
    }, [new URLSearchParams(window.location.search).get('type'), props?.access?.access_level])

    useEffect(() => {
        handleSearch()
    }, [candidateStatus])


    const getCandidatePipeLines = async (data) => {
        setPipeLineInfo([])
        await fetch(
            `${OPEN_CATS_URL}/reportsRouter/getCandidatePipeLines`,
            {
                method: 'POST',
                body: JSON.stringify({ candidateId: data.candidate_id, dateRange: dateRange }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                setPipeLineInfo(result.data)
                setName(`${data.first_name} ${data.last_name}`)
                setPipelineModal(true)

            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    useEffect(() => {
        if (pipeLineInfo?.length > 0) {
            $(document).ready(function () {
                window.$('#pipeLineInfoTable').DataTable({
                    "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
                    dom: 'Bfrtip',
                    "order": [[0, "desc"]],
                    mark: true,
                    "bDestroy": true,
                    buttons: [
                        'pageLength',
                        'selectAll',
                        'selectNone',
                        {
                            extend: 'excelHtml5',
                            autoFilter: true,
                            sheetName: 'Exported data',
                            text: 'Export'

                        }],
                    select: true
                })
            })
        }
    }, [pipeLineInfo, pipelineTableView])

    // const handleRowData = async (x, data) => {
    //     setPipeLineInfo(data)
    //     setName(`${x.first_name} ${x.last_name}`)
    //     setPipelineModal(true)
    // }

    const getCandidateReportCounts = async (x, dateRange, type) => {
        await fetch(
            `${OPEN_CATS_URL}/reportsRouter/getCandidateReportCounts`, {
            method: 'POST',
            body: JSON.stringify({ candidate_id: x.candidate_id, type: type, dateRange: dateRange }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {
                setPipeLineInfo(result.data)
                var length = result.dates.dateArray.map(x => {
                    var result1 = result.data.filter(a1 => moment(x).format('YYYY-MM-DD') === moment(a1.date_created).format('YYYY-MM-DD'));
                    if (result1.length > 0) {
                        return {
                            dates: x,
                            data: result1.length,
                            chartType: type
                        }
                    } else {
                        return {
                            dates: x,
                            data: result1.length,
                            chartType: type
                        }
                    }
                })
                handleView('pipelineTable')
                setPipelineChartFilterData(length)
                setPipelineChartData(length)
                setName(`${x.first_name} ${x.last_name}`)
                setPipelineModal(true)
                setPipelineTableType(type)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const getSingleCandidate = async (id) => {
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getSingleCandidate`, {
            method: 'POST',
            body: JSON.stringify({ candidate_id: id }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {

                setCandidateUpdatedData(result.data)
                getCandidateActivity(id)
                setCandidateEditModal(true)
                setLoader(false)
            })
            .catch((error) => {
                setLoader(false)
                console.error('Error:', error);
            });
    }

    const getCandidateActivity = async (data) => {
        setActivityInfo([])
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getCandidateActivity`,
            {
                method: 'POST',
                body: JSON.stringify({ candidateId: data }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                setActivityInfo(result.data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }

    const getData = (data) => {
        if (data) {
            return (<p dangerouslySetInnerHTML={{ __html: data.concat(" ") }} />)
        }
    }

    const hideUnHideRows = (data) => {
        var newArray = data.map(x => {
            return x.value
        })
        if (newArray.includes('All')) {
            setFilterUsers(users)
            setSelectedReportUser([{ value: 'All', label: 'All' }])
        } else {

            setSelectedReportUser(data.filter(y => { return y.value !== 'All' }))
            setFilterUsers(users.filter(y => {
                return newArray.includes(y.value)
            }))

            setToken('selectedReportUser', JSON.stringify(users.filter(y => {
                return newArray.includes(y.value)
            })))
        }
    }

    return (
        props?.showReports && (
            <div className="ml-4">
                {/* <div className="mb-5">
                    <div className="mt-2 mb-2">
                        {
                            (props?.access?.access_level === 300 || props?.access?.access_level === 400 || props?.access?.access_level === 500) &&
                            <>
                                <button type="button" className={natReportView ? "btn btn-outline-dark btn-sm active mr-2" : "btn btn-outline-dark btn-sm mr-2"} onClick={() => handleView('natReport')}>Reports</button>
                            </>
                        }

                        <button type="button" className={candidateView ? "btn btn-outline-dark active mr-2 btn-sm" : "btn btn-outline-dark mr-2 btn-sm"} onClick={() => handleView('candidate')}>Candidate Reports</button>
                        <button type="button" className={jobView ? "btn btn-outline-dark btn-sm active mr-2" : "btn btn-outline-dark btn-sm mr-2"} onClick={() => handleView('job')}>Job order Reports</button>
                        <button type="button" className={activityReports ? "btn btn-outline-dark btn-sm active mr-2" : "btn btn-outline-dark btn-sm mr-2"} onClick={() => handleView('activity')}>Activity Reports</button>
                    </div>
                </div> */}
                <div>
                    {
                        (candidateView || jobView || activityReports || pipelineReports) &&
                        <div className='mt-1 mb-3'>
                            <h5 className="ml-3" style={{ textTransform: 'capitalize' }}>{new URLSearchParams(window.location.search).get('type')} Reports</h5>
                            {
                                <div className='row mt-2 mb-2 col-12'>
                                    <div className="col-8">
                                        <label className='mb-3'> Date Range Between {moment(dateRange[0]?.startDate).format('MM-DD-YYYY')} and {moment(dateRange[0]?.endDate).format('MM-DD-YYYY')}</label>
                                    </div>
                                    {
                                        !natReportView || !pipelineReports &&
                                        <div className='col-4'>
                                            <input type="search" className="form-control" placeholder='Search...' onChange={(e) => handleDataSearch(e, candidateView ? "candidateCountData" :
                                                "jobCountData")} />
                                        </div>
                                    }

                                </div>
                            }
                        </div>
                    }

                    {candidateView && (

                        <>
                            <div className='row col-10'>
                                <div className="col-6">
                                    <button type="button" className='btn btn-outline-dark btn-sm' onClick={() => setDatePicker(!datePicker)}>
                                        Select Date Range
                                    </button>
                                    <Modal
                                        isOpen={datePicker}
                                        onRequestClose={() => setDatePicker(false)}
                                        style={customStyles}
                                        shouldCloseOnOverlayClick={true}
                                    >
                                        <div>
                                            <DateRangePicker
                                                ranges={dateRange}
                                                onChange={(item) => handleDateRange([item.selection])}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                months={2}
                                                direction="horizontal"
                                            />
                                        </div>
                                        <center>
                                            <button type="button" className='btn btn-outline-dark btn-sm' onClick={() => handleSearch()}>
                                                Search
                                            </button>
                                            <button type="button" className='btn btn-outline-dark btn-sm ml-2' onClick={() => setDatePicker(false)}>
                                                Cancel
                                            </button>
                                        </center>
                                    </Modal>

                                </div>
                                <div className='col-6'>
                                    {/* <Select
                                        onChange={(selectedOption) => handleSelect(selectedOption, 'candidate')}
                                        options={users}
                                    /> */}
                                </div>
                            </div>

                            {
                                candidateCountData.length > 0 ?
                                    <>
                                        <div className='mt-5 mb-3'>

                                            <div className="mb-5">
                                                <div className="mt-2 mb-2">
                                                    <button type="button" className={candidateTableView ? "btn btn-outline-dark btn-sm active mr-2" : "btn btn-outline-dark btn-sm mr-2"} onClick={() => handleView('candidateTable')}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-table" viewBox="0 0 16 16">
                                                        <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z" />
                                                    </svg></button>
                                                    <button type="button" className={candidateChartView ? "btn btn-outline-dark btn-sm active" : "btn btn-outline-dark btn-sm"} onClick={() => handleView('candidateChart')}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bar-chart-fill" viewBox="0 0 16 16">
                                                        <path d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2z" />
                                                    </svg></button>
                                                </div>
                                            </div>
                                            {

                                                candidateChartView &&

                                                <div className='mt-2 mb-4' style={{
                                                    height: '270px',
                                                    width: '50%'
                                                }}>

                                                    <Line
                                                        options={{
                                                            responsive: true,
                                                            maintainAspectRatio: false
                                                        }}
                                                        data={{
                                                            labels: candidateChartData?.date?.map(x => { return moment(x).format('YYYY-MM-DD') }),
                                                            datasets: [
                                                                {
                                                                    id: 1,
                                                                    label: 'Candidate',
                                                                    data: candidateChartData?.data?.map(x => { return x.candidate_count }),
                                                                    borderColor: 'black',
                                                                    backgroundColor: 'black'
                                                                }
                                                            ]
                                                        }}
                                                    />
                                                </div>

                                            }
                                            {
                                                candidateTableView &&
                                                (

                                                    candidateCountData.length > 0 ?
                                                        <div className="mb-4">
                                                            <table id="candidate" className="table table-hover table-sm" style={{ width: '100%' }}>
                                                                <thead>
                                                                    <th>Recruiter</th>
                                                                    <th>Count</th>
                                                                    {
                                                                        // (props?.access?.access_level === 400 || props?.access?.access_level === 500) &&
                                                                        <th>Action</th>
                                                                    }
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        candidateCountData.map(x => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>{x.first_name} {x.last_name}</td>
                                                                                    <td>{x.count}</td>
                                                                                    {
                                                                                        // (props?.access?.access_level === 400 || props?.access?.access_level === 500) &&
                                                                                        <td><button className="btn btn-outline-dark btn-sm" type="button" onClick={() => {
                                                                                            handleCandidate(x)
                                                                                        }}>View Details</button></td>
                                                                                    }

                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        :
                                                        <center>No Candidate Data Found</center>
                                                )
                                            }
                                        </div>
                                        <Modal
                                            isOpen={candidateModal}
                                            onRequestClose={() => setCandidateModal(false)}
                                            style={viewModal}
                                            shouldCloseOnOverlayClick={true}
                                        >
                                            <div>
                                                <div className='mt-5 mb-3'>
                                                    {
                                                        <div className='row mt-2 mb-2'>
                                                            <div className="col-6">
                                                                <label className='mb-3'> Date Range Between {moment(dateRange[0]?.startDate).format('MM-DD-YYYY')} and {moment(dateRange[0]?.endDate).format('MM-DD-YYYY')}</label>
                                                            </div>
                                                            {/* <div className='col-3'>
                                                                <input type="search" className="form-control" placeholder='Search...' onChange={(e) => handleDataSearch(e, 'jobData')} />
                                                            </div> */}
                                                            <div className='col-6' style={{ textAlign: 'right' }}>
                                                                <button type="button" className="btn btn-outline-dark btn-sm" onClick={() => setCandidateModal(false)} >Close</button>
                                                            </div>
                                                        </div>
                                                    }
                                                    <label className='mb-3'> Total Candidate Count: {candidateData?.length}</label>

                                                    {
                                                        candidateData.length > 0 ?
                                                            <div className="mb-4">
                                                                <table id="exampleCandidate" className="table table-hover table-sm" style={{ width: '100%' }}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>First Name</th>
                                                                            <th>Last Name</th>
                                                                            <th>Email</th>
                                                                            <th>Contact</th>
                                                                            {/* <th>Primary Skills</th> */}
                                                                            <th>Created By</th>
                                                                            <th>Created Date</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            candidateData.map(x => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td><label
                                                                                            style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                                            onClick={() => {
                                                                                                getSingleCandidate(x?.candidate_id)
                                                                                            }}>{x.first_name}</label></td>
                                                                                        <td><label
                                                                                            style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                                            onClick={() => {
                                                                                                getSingleCandidate(x?.candidate_id)
                                                                                            }}>{x.last_name}</label></td>
                                                                                        <td>{x.email1}</td>
                                                                                        <td>{x.phone_home}</td>
                                                                                        {/* <td>{x.primary_skills}</td> */}
                                                                                        <td>{x.created_by}</td>
                                                                                        <td>{moment(x.date_created).format("MM-DD-YY hh:mm")}</td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            :
                                                            <div className="container">
                                                                {
                                                                    loader ?
                                                                        <center>Loading....</center>
                                                                        :
                                                                        <center>No Data Found</center>


                                                                }
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        </Modal>
                                    </>
                                    :

                                    loader ?
                                        <center>Loading....</center>
                                        :
                                        <center>No Data Found</center>

                            }
                        </>
                    )}
                    {jobView && (
                        <>
                            <div className='row col-10'>
                                <div className="col-6">
                                    <button type="button" className='btn btn-outline-dark btn-sm' onClick={() => setDatePicker(!datePicker)}>
                                        Select Date Range
                                    </button>
                                    <Modal
                                        isOpen={datePicker}
                                        onRequestClose={() => setDatePicker(false)}
                                        style={customStyles}
                                        shouldCloseOnOverlayClick={true}
                                    >
                                        <div>
                                            <DateRangePicker
                                                ranges={dateRange}
                                                onChange={(item) => handleDateRange([item.selection])}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                months={2}
                                                direction="horizontal"
                                            />
                                        </div>
                                        <center>
                                            <button type="button" className='btn btn-outline-dark btn-sm' onClick={() => handleSearch()}>
                                                Search
                                            </button>
                                            <button type="button" className='btn btn-outline-dark btn-sm ml-2' onClick={() => setDatePicker(false)}>
                                                Cancel
                                            </button>
                                        </center>
                                    </Modal>

                                </div>
                                <div className='col-6'>
                                    {/* <Select
                                        onChange={(selectedOption) => handleSelect(selectedOption, 'job')}
                                        options={users}
                                    /> */}
                                </div>
                            </div>

                            {
                                jobsCountData.length > 0 ?
                                    <>
                                        <div className='mt-5 mb-3'>
                                            <div className="mb-5">
                                                <div className="mt-2 mb-2">
                                                    <button type="button" className="btn btn-outline-dark btn-sm mr-2" onClick={() => handleView('jobTable')}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-table" viewBox="0 0 16 16">
                                                        <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z" />
                                                    </svg></button>
                                                    <button type="button" className="btn btn-outline-dark btn-sm" onClick={() => handleView('jobChart')}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bar-chart-fill" viewBox="0 0 16 16">
                                                        <path d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2z" />
                                                    </svg></button>
                                                </div>
                                            </div>
                                            {

                                                jobChartView &&

                                                <div className='mt-2 mb-4' style={{
                                                    height: '270px',
                                                    width: '50%'
                                                }}>
                                                    <Line
                                                        options={{
                                                            responsive: true,
                                                            maintainAspectRatio: false
                                                        }}
                                                        datasetIdKey='id'
                                                        data={{
                                                            labels: jobChartData?.date?.map(x => { return moment(x).format('YYYY-MM-DD') }),
                                                            datasets: [
                                                                {
                                                                    id: 1,
                                                                    label: 'Jobs',
                                                                    data: jobChartData?.data?.map(x => { return x.job_count }),
                                                                    borderColor: 'black',
                                                                    backgroundColor: 'black'
                                                                }
                                                            ]
                                                        }}
                                                    />
                                                </div>

                                            }
                                            {
                                                jobTableView &&
                                                (
                                                    jobsCountData.length > 0 ?
                                                        <div className="mb-4">
                                                            <table id="candidate" className="table table-hover table-sm" style={{ width: '100%' }}>
                                                                <thead>
                                                                    <th>Recruiter</th>
                                                                    <th>Count</th>
                                                                    {
                                                                        // (props?.access?.access_level === 400 || props?.access?.access_level === 500) &&
                                                                        <th>Action</th>
                                                                    }
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        jobsCountData.map(x => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>{x.first_name} {x.last_name}</td>
                                                                                    <td>{x.count}</td>
                                                                                    {
                                                                                        // (props?.access?.access_level === 400 || props?.access?.access_level === 500) &&
                                                                                        <td><button className="btn btn-outline-dark btn-sm" type="button" onClick={() => {
                                                                                            handleJob(x)
                                                                                        }}>View Details</button></td>
                                                                                    }
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        :
                                                        loader ?
                                                            <center>Loading....</center>
                                                            :
                                                            <center>No Data Found</center>
                                                )
                                            }
                                        </div>
                                        <Modal
                                            isOpen={jobModal}
                                            onRequestClose={() => setJobModal(false)}
                                            style={viewModal}
                                            shouldCloseOnOverlayClick={true}
                                        >
                                            <div>
                                                <div className='mt-5 mb-3'>
                                                    {
                                                        <div className='row mt-2 mb-2'>
                                                            <div className="col-6">
                                                                <label className='mb-3'> Date Range Between {moment(dateRange[0]?.startDate).format('MM-DD-YYYY')} and {moment(dateRange[0]?.endDate).format('MM-DD-YYYY')}</label>
                                                            </div>
                                                            {/* <div className='col-3'>
                                                                <input type="search" className="form-control" placeholder='Search...' onChange={(e) => handleDataSearch(e, 'jobData')} />
                                                            </div> */}
                                                            <div className='col-6' style={{ textAlign: 'right' }}>
                                                                <button type="button" className="btn btn-outline-dark btn-sm" onClick={() => setJobModal(false)} >Close</button>
                                                            </div>
                                                        </div>
                                                    }
                                                    <label className='mb-3'> Total Jobs Count: {jobData.length}</label>

                                                    {
                                                        jobData.length > 0 ?
                                                            <div className="container">
                                                                <table id="job-data" className="table table-hover table-sm" style={{ width: '100%' }}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Job Title</th>
                                                                            <th>Company Name</th>
                                                                            <th>City</th>
                                                                            <th>State</th>
                                                                            <th>S</th>
                                                                            <th>P</th>
                                                                            <th>Created By</th>
                                                                            <th>Created Date</th>
                                                                        </tr>
                                                                    </thead>
                                                                    
                                                                    <tbody>
                                                                        {
                                                                            jobData.map(x => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>  <label
                                                                                            style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                                            onClick={() => {
                                                                                                setSelectedJobOrderId(x?.result1?.joborder_id)
                                                                                                setJobOrderModal(true)
                                                                                            }}>{x?.result1?.title}</label></td>
                                                                                        <td>{x?.result1?.company_name}</td>
                                                                                        <td>{x?.result1?.city}</td>
                                                                                        <td>{x?.result1?.state}</td>
                                                                                        <td>{x?.result2}</td>
                                                                                        <td>{x?.result3}</td>
                                                                                        <td>{x?.result1?.created_by}</td>
                                                                                        <td>{moment(x?.result1?.date_created).format("MM-DD-YY hh:mm")}</td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            :
                                                            <div className="container">
                                                                {
                                                                    loader ?
                                                                        <center>Loading....</center>
                                                                        :
                                                                        <center>No Data Found</center>


                                                                }
                                                            </div>


                                                    }
                                                </div>
                                            </div>
                                        </Modal>
                                    </>
                                    :
                                    loader ?
                                        <center>Loading....</center>
                                        :
                                        <center>No Data Found</center>
                            }
                        </>
                    )}
                    {activityReports && (
                        <>
                            <div className='row col-10'>
                                <div className="col-6">
                                    <button type="button" className='btn btn-outline-dark btn-sm' onClick={() => setDatePicker(!datePicker)}>
                                        Select Date Range
                                    </button>
                                    <Modal
                                        isOpen={datePicker}
                                        onRequestClose={() => setDatePicker(false)}
                                        style={customStyles}
                                        shouldCloseOnOverlayClick={true}
                                    >
                                        <div>
                                            <DateRangePicker
                                                ranges={dateRange}
                                                onChange={(item) => handleDateRange([item.selection])}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                months={2}
                                                direction="horizontal"
                                            />
                                        </div>
                                        <center>
                                            <button type="button" className='btn btn-outline-dark btn-sm' onClick={() => handleSearch()}>
                                                Search
                                            </button>
                                            <button type="button" className='btn btn-outline-dark btn-sm ml-2' onClick={() => setDatePicker(false)}>
                                                Cancel
                                            </button>
                                        </center>
                                    </Modal>

                                </div>
                            </div>

                            {
                                activityCountData.length > 0 ?
                                    <>
                                        <div className='mt-5 mb-3'>
                                            <div className="mb-5">
                                                <div className="mt-2 mb-2">
                                                    <button type="button" className="btn btn-outline-dark btn-sm mr-2" onClick={() => handleView('activityTable')}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-table" viewBox="0 0 16 16">
                                                        <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z" />
                                                    </svg></button>
                                                    <button type="button" className="btn btn-outline-dark btn-sm" onClick={() => handleView('activityChart')}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bar-chart-fill" viewBox="0 0 16 16">
                                                        <path d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2z" />
                                                    </svg></button>
                                                </div>
                                            </div>
                                            {

                                                activityChartView &&

                                                <div className='mt-2 mb-4' style={{
                                                    height: '270px',
                                                    width: '50%'
                                                }}>
                                                    <Line
                                                        options={{
                                                            responsive: true,
                                                            maintainAspectRatio: false
                                                        }}
                                                        datasetIdKey='id'
                                                        data={{
                                                            labels: activityChartData?.date?.map(x => { return moment(x).format('YYYY-MM-DD') }),
                                                            datasets: [
                                                                {
                                                                    id: 1,
                                                                    label: 'Activity',
                                                                    data: activityChartData?.data?.map(x => { return x.job_count }),
                                                                    borderColor: 'black',
                                                                    backgroundColor: 'black'
                                                                }
                                                            ]
                                                        }}
                                                    />
                                                </div>

                                            }
                                            {
                                                activityTableView &&
                                                (
                                                    activityCountData.length > 0 ?
                                                        <div className="mb-4">
                                                            <table id="candidate" className="table table-hover table-sm" style={{ width: '100%' }}>
                                                                <thead>
                                                                    <th>Recruiter</th>
                                                                    <th>Count</th>
                                                                    <th>Action</th>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        activityCountData.map(x => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>{x.first_name} {x.last_name}</td>
                                                                                    <td>{x.count}</td>
                                                                                    {
                                                                                        // (props?.access?.access_level === 400 || props?.access?.access_level === 500) &&
                                                                                        <td><button className="btn btn-outline-dark btn-sm" type="button" onClick={() => {
                                                                                            handleActivity(x.data, `${x.first_name} ${x.last_name}`)
                                                                                        }}>View Details</button></td>
                                                                                    }
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        :
                                                        loader ?
                                                            <center>Loading....</center>
                                                            :
                                                            <center>No Data Found</center>
                                                )
                                            }
                                        </div>
                                        <Modal
                                            isOpen={activityModal}
                                            onRequestClose={() => setActivityModal(false)}
                                            style={viewModal}
                                            shouldCloseOnOverlayClick={true}
                                        >
                                            <div>
                                                <div className='mt-5 mb-3'>
                                                    {
                                                        <div className='row mt-2 mb-2'>
                                                            <div className="col-6">
                                                                <label className='mb-3'> Date Range Between {moment(dateRange[0]?.startDate).format('MM-DD-YYYY')} and {moment(dateRange[0]?.endDate).format('MM-DD-YYYY')}</label>
                                                            </div>
                                                            {/* <div className='col-3'>
                                                                <input type="search" className="form-control" placeholder='Search...' onChange={(e) => handleDataSearch(e, 'jobData')} />
                                                            </div> */}
                                                            <div className='col-6' style={{ textAlign: 'right' }}>
                                                                <button type="button" className="btn btn-outline-dark btn-sm" onClick={() => setActivityModal(false)} >Close</button>
                                                            </div>
                                                        </div>
                                                    }
                                                    <label className='mb-3'> Total activity Count: {activityData?.length} by {name}</label>

                                                    {
                                                        activityData?.length > 0 ?
                                                            <div className="container">
                                                                <table id="activity-data" className="table table-hover table-sm" style={{ width: '100%' }}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th style={{ display: 'none' }}>id</th>
                                                                            <th>Name</th>
                                                                            <th>Regarding</th>
                                                                            <th>Event</th>
                                                                            <th>Event Notes</th>
                                                                            <th>Created Time</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            activityData.map(x => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td style={{ display: 'none' }}>{x.activity_id}</td>
                                                                                        <td><label
                                                                                            style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                                            onClick={() => {
                                                                                                getSingleCandidate(x?.candidate_id)
                                                                                            }}> {x.first_name} {x.last_name}</label></td>
                                                                                        <td>{x?.regarding}</td>
                                                                                        <td>{x?.short_description}</td>
                                                                                        <td><p dangerouslySetInnerHTML={{ __html: x?.notes }} /></td>
                                                                                        <td>{moment(x?.date_created).format("lll")}</td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            :
                                                            <div className="container">
                                                                {
                                                                    loader ?
                                                                        <center>Loading....</center>
                                                                        :
                                                                        <center>No Data Found</center>


                                                                }
                                                            </div>


                                                    }
                                                </div>
                                            </div>
                                        </Modal>
                                    </>
                                    :
                                    loader ?
                                        <center>Loading....</center>
                                        :
                                        <center>No Data Found</center>
                            }
                        </>

                    )}
                    {pipelineReports && (
                        <>
                            <div className='row col-10'>
                                <div className="col-6">
                                    <button type="button" className='btn btn-outline-dark btn-sm' onClick={() => setDatePicker(!datePicker)}>
                                        Select Date Range
                                    </button>
                                    <Modal
                                        isOpen={datePicker}
                                        onRequestClose={() => setDatePicker(false)}
                                        style={customStyles}
                                        shouldCloseOnOverlayClick={true}
                                    >
                                        <div>
                                            <DateRangePicker
                                                ranges={dateRange}
                                                onChange={(item) => handleDateRange([item.selection])}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                months={2}
                                                direction="horizontal"
                                            />
                                        </div>
                                        <center>
                                            <button type="button" className='btn btn-outline-dark btn-sm' onClick={() => handleSearch()}>
                                                Search
                                            </button>
                                            <button type="button" className='btn btn-outline-dark btn-sm ml-2' onClick={() => setDatePicker(false)}>
                                                Cancel
                                            </button>
                                        </center>
                                    </Modal>

                                </div>

                            </div>
                            <div className='col-4 mt-2'>
                                <label>Candidate Status</label>
                                <select className='form-control' value={candidateStatus} onChange={(e) => setCandidateStatus(e.target.value)}>
                                    <option defaultChecked value="1">1-Priority</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="All">All</option>
                                </select>
                            </div>
                            {
                                pipelineCountData.length > 0 ?
                                    <>
                                        <div className='mt-5 mb-3'>
                                            {

                                                (
                                                    pipelineCountData.length > 0 ?
                                                        <div className="container mb-4">
                                                            <table id="pipelineCountTable" className="table table-hover table-sm" style={{ width: '100%' }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ display: 'none' }}>Candidate Id</th>
                                                                        <th>Candidate Name</th>
                                                                        <th>Recruiter</th>
                                                                        <th>Closing Probability</th>
                                                                        <th>Pipelines</th>
                                                                        <th>Submissions</th>
                                                                        <th>Interview</th>
                                                                        <th>Rejections</th>
                                                                        {/* <th>View</th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        pipelineCountData.map(x => {
                                                                            return (
                                                                                <tr key={x.candidate_id}>

                                                                                    <td style={{ display: 'none' }}>{x.candidate_id}</td>
                                                                                    <td><label
                                                                                        style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                                        onClick={() => {
                                                                                            getSingleCandidate(x?.candidate_id)
                                                                                        }}> {x.first_name} {x.last_name}</label></td>
                                                                                    <td>
                                                                                        {[...new Map(x[0].map(item =>
                                                                                            [item['recruiter_id'], item])).values()].map(x => {
                                                                                                return `${x.recruiter_first_name != null ? x.recruiter_first_name : ""}  ${x.recruiter_last_name != null ? x.recruiter_last_name : ""}`
                                                                                            }).join(',')}</td>
                                                                                    <td>{x.probability !== null ? x.probability : '0'}%</td>
                                                                                    <td style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => getCandidateReportCounts(x, dateRange, "Pipelines")}>{x.pipelines_count !== null ? x.pipelines_count : 0}</td>
                                                                                    <td style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => getCandidateReportCounts(x, dateRange, "Submissions")}>{x.submission_count !== null ? x.submission_count : 0}</td>
                                                                                    <td style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => getCandidateReportCounts(x, dateRange, "Interviews")}>{x.interview_count !== null ? x.interview_count : 0}</td>
                                                                                    <td style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => getCandidateReportCounts(x, dateRange, "Rejections")}>{x.rejection_count !== null ? x.rejection_count : 0}</td>
                                                                                    {/* <td><button className="btn btn-outline-dark btn-sm" type="button" onClick={() => {
                                                                                        getCandidatePipeLines(x, 'viewDetails')
                                                                                    }}>View Details</button></td> */}

                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        :
                                                        loader ?
                                                            <center>Loading....</center>
                                                            :
                                                            <center>No Data Found</center>
                                                )
                                            }
                                        </div>
                                        <Modal
                                            isOpen={pipelineModal}
                                            onRequestClose={() => setPipelineModal(false)}
                                            style={viewModal}
                                            shouldCloseOnOverlayClick={true}
                                        >
                                            <div>
                                                <div className='mt-5 mb-3'>
                                                    {
                                                        <>
                                                            <div className='row mt-2 mb-2'>
                                                                <div className="col-6">
                                                                    <label className='mb-3'> Date Range Between {moment(dateRange[0]?.startDate).format('MM-DD-YYYY')} and {moment(dateRange[0]?.endDate).format('MM-DD-YYYY')}</label>
                                                                </div>
                                                                <div className='col-6' style={{ textAlign: 'right' }}>
                                                                    <button type="button" className="btn btn-outline-dark btn-sm" onClick={() => setPipelineModal(false)} >Close</button>
                                                                </div>
                                                            </div>
                                                            <div className="mb-5">
                                                                <div className="mt-2 mb-2">
                                                                    <button type="button" className="btn btn-outline-dark btn-sm mr-2" onClick={() => handleView('pipelineTable')}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-table" viewBox="0 0 16 16">
                                                                        <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z" />
                                                                    </svg></button>
                                                                    <button type="button" className="btn btn-outline-dark btn-sm" onClick={() => handleView('pipelineChart')}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bar-chart-fill" viewBox="0 0 16 16">
                                                                        <path d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2z" />
                                                                    </svg></button>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    {
                                                        pipelineTableView ?
                                                            pipeLineInfo.length > 0 ?
                                                                <div className="">
                                                                    <table id="pipeLineInfoTable" className="table table-hover table-sm" style={{ width: '100%' }}>
                                                                        <thead>
                                                                            <tr>
                                                                                <th style={{ display: 'none' }}></th>
                                                                                <th>Candidate Name</th>
                                                                                <th>Joborder ID</th>
                                                                                <th>Job title</th>
                                                                                <th>Job Source</th>
                                                                                <th>Company</th>
                                                                                {pipelineTableType === 'Submissions' &&
                                                                                    <th>Closing Propbability</th>
                                                                                }
                                                                                <th>Added</th>
                                                                                <th>Entered By</th>
                                                                                <th>Status</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                pipeLineInfo.map((x) => {
                                                                                    return (
                                                                                        <tr key={x}>
                                                                                            <td style={{ display: 'none' }}>{x?.candidate_job_order_id}</td>
                                                                                            <td><label
                                                                                                style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                                                onClick={() => {
                                                                                                    getSingleCandidate(x?.candidate_id)
                                                                                                }}> {name}</label></td>
                                                                                            <td>NOVIJP00{x.joborder_id}</td>
                                                                                            <td>
                                                                                                <label
                                                                                                    style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                                                    onClick={() => {
                                                                                                        setSelectedJobOrderId(x?.joborder_id)
                                                                                                        setJobOrderModal(true)
                                                                                                    }
                                                                                                    }

                                                                                                >
                                                                                                    {x?.title}
                                                                                                </label>
                                                                                            </td>
                                                                                            <td>{x.job_source}</td>
                                                                                            <td>{x.companyName}</td>
                                                                                            {pipelineTableType === 'Submissions' &&
                                                                                                <td>{x.probability !== null ? `${x.probability}%` : '0%'}</td>
                                                                                            }
                                                                                            <td>{moment(x.date_modified).format("MM-DD-YY hh:mm a")}</td>
                                                                                            <td>{x.first_name} {x.last_name}</td>
                                                                                            <td id={`previousStatus${x.candidate_id}`}>{<Status candidateId={x.candidate_id} joborderId={x.joborder_id} />}</td>
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                :
                                                                loader ?
                                                                    <div>Loading...</div>
                                                                    :
                                                                    <div>No Pipeline Data Found</div>
                                                            :
                                                            ""

                                                    }
                                                    {
                                                        pipelineChartView ?
                                                            <div className='mt-2 mb-4' style={{
                                                                height: '390px',

                                                            }}>
                                                                <Line
                                                                    options={{
                                                                        responsive: true,
                                                                        maintainAspectRatio: false
                                                                    }}
                                                                    datasetIdKey='id'
                                                                    data={{
                                                                        labels: pipelineChartData?.map(x => { return moment(x?.dates).format('YYYY-MM-DD') }),
                                                                        datasets: [
                                                                            {
                                                                                id: 1,
                                                                                label: pipelineChartData[0].chartType,
                                                                                data: pipelineChartData?.map(x => { return x?.data }),
                                                                                borderColor: 'black',
                                                                                backgroundColor: 'black'
                                                                            }
                                                                        ]
                                                                    }}
                                                                />
                                                            </div>
                                                            :
                                                            ""
                                                    }
                                                </div>
                                            </div>
                                        </Modal>

                                    </>
                                    :
                                    loader ?
                                        <center>Loading....</center>
                                        :
                                        <center>No Data Found</center>
                            }
                        </>

                    )}
                    {natReportView && (
                        <>
                            <div className='row col-12'>
                                <div className="col-12">
                                    <h5>Recruiter Reports</h5>
                                    <div className='mt-1 mb-1'>
                                        {
                                            <div className='row mb-2'>
                                                <div className="col-8">
                                                    <label className='mb-3'> Date Range Between {moment(dateRange[0]?.startDate).format('MM-DD-YYYY')} and {moment(dateRange[0]?.endDate).format('MM-DD-YYYY')}</label>
                                                </div>


                                            </div>
                                        }
                                    </div>
                                    <button type="button" className='btn btn-outline-dark btn-sm' onClick={() => {
                                        setNewReportView(false)
                                        setDatePicker(!datePicker)
                                    }}>
                                        Select Date Range
                                    </button>
                                    <Modal
                                        isOpen={datePicker}
                                        onRequestClose={() => setDatePicker(false)}
                                        style={customStyles}
                                        shouldCloseOnOverlayClick={true}
                                    >
                                        <div>
                                            <DateRangePicker
                                                ranges={dateRange}
                                                onChange={(item) => handleDateRange([item.selection])}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                months={2}
                                                direction="horizontal"
                                            />
                                        </div>
                                        <center>
                                            <button type="button" className='btn btn-outline-dark btn-sm' onClick={() => {
                                                setNewReportView(true)
                                                setDatePicker(false)
                                            }}>
                                                Search
                                            </button>
                                            <button type="button" className='btn btn-outline-dark btn-sm ml-2' onClick={() => setDatePicker(false)}>
                                                Cancel
                                            </button>
                                        </center>
                                    </Modal>
                                </div>
                                <div className='col-4 mt-2'>
                                    <label>Select Recruiters</label>
                                    <Select
                                        value={selectedReportUser}
                                        isMulti={true}
                                        placeholder="Select Recruiter"
                                        onChange={(selectedOption) => hideUnHideRows(selectedOption)}
                                        options={[{ label: 'All', value: 'All' }, ...users]}
                                    />
                                </div>
                                <div className='col-4'>

                                    {
                                        newReportView &&
                                        <div className="row text-center" style={{ marginTop: '8px', marginBottom: '10px', marginRight: '15px' }}>
                                            <Workbook filename={`${exportReportName}.xlsx`} element={<button ref={bulkExportRefer} title="Bulk Export" style={{ display: 'none' }} className="btn btn-outline-dark btn-sm">{
                                                loader ?
                                                    <img style={{ width: "10px", height: '10px' }} src="./images/loader.gif" alt="loading..." />
                                                    :
                                                    "Export"
                                            }</button>}>
                                                <Workbook.Sheet data={jobOrderTableData} name="Job Orders">
                                                    <Workbook.Column label="Job Order Id" value="jobId" />
                                                    <Workbook.Column label="Title" value="title" />
                                                    <Workbook.Column label="Company" value="company" />
                                                    <Workbook.Column label="Submissions" value="submission" />
                                                    <Workbook.Column label="Pipeline" value="pipeline" />
                                                    <Workbook.Column label="Recruiter" value="recruiter" />
                                                    <Workbook.Column label="Owner" value="owner" />
                                                    <Workbook.Column label="Date Created" value="dateCreated" />
                                                </Workbook.Sheet>
                                                <Workbook.Sheet data={pipelineTableData} name="Pipeline">
                                                    <Workbook.Column label="First Name" value="first_name" />
                                                    <Workbook.Column label="Last Name" value="last_name" />
                                                    <Workbook.Column label="Job Order Id" value="joborder_id" />
                                                    <Workbook.Column label="Company Name" value="company_name" />
                                                    <Workbook.Column label="Job Order" value="title" />
                                                    <Workbook.Column label="Entered By" value="enteredBy" />
                                                    <Workbook.Column label="Date Created" value="dateCreated" />
                                                </Workbook.Sheet>
                                                <Workbook.Sheet data={submissionTableData} name="Submissions">
                                                    <Workbook.Column label="First Name" value="first_name" />
                                                    <Workbook.Column label="Last Name" value="last_name" />
                                                    <Workbook.Column label="Candidate Owner" value="candidate_owner" />
                                                    <Workbook.Column label="Job Order Id" value="joborder_id" />
                                                    <Workbook.Column label="Job Order" value="title" />
                                                    <Workbook.Column label="Company" value="company_name" />
                                                    <Workbook.Column label="Job Order Owner" value="joborder_owner" />
                                                    <Workbook.Column label="Date Created" value="dateCreated" />
                                                </Workbook.Sheet>
                                                <Workbook.Sheet data={rncData} name="Resume Not Consideration">
                                                    <Workbook.Column label="First Name" value="first_name" />
                                                    <Workbook.Column label="Last Name" value="last_name" />
                                                    <Workbook.Column label="Job Order" value="joborder" />
                                                    <Workbook.Column label="Activity" value="activity" />
                                                    <Workbook.Column label="Employer" value="employer" />
                                                    <Workbook.Column label="Entered By" value="enteredBy" />
                                                    <Workbook.Column label="Date Created" value="dateCreated" />
                                                </Workbook.Sheet>
                                                <Workbook.Sheet data={iwvData} name="Interviewing With Vendor">
                                                    <Workbook.Column label="First Name" value="first_name" />
                                                    <Workbook.Column label="Last Name" value="last_name" />
                                                    <Workbook.Column label="Job Order" value="joborder" />
                                                    <Workbook.Column label="Activity" value="activity" />
                                                    <Workbook.Column label="Employer" value="employer" />
                                                    <Workbook.Column label="Entered By" value="enteredBy" />
                                                    <Workbook.Column label="Date Created" value="dateCreated" />
                                                </Workbook.Sheet>
                                                <Workbook.Sheet data={rbvData} name="Rejected By Vendor">
                                                    <Workbook.Column label="First Name" value="first_name" />
                                                    <Workbook.Column label="Last Name" value="last_name" />
                                                    <Workbook.Column label="Job Order" value="joborder" />
                                                    <Workbook.Column label="Activity" value="activity" />
                                                    <Workbook.Column label="Employer" value="employer" />
                                                    <Workbook.Column label="Entered By" value="enteredBy" />
                                                    <Workbook.Column label="Date Created" value="dateCreated" />
                                                </Workbook.Sheet>
                                                <Workbook.Sheet data={iwcData} name="Interviewing With Client">
                                                    <Workbook.Column label="First Name" value="first_name" />
                                                    <Workbook.Column label="Last Name" value="last_name" />
                                                    <Workbook.Column label="Job Order" value="joborder" />
                                                    <Workbook.Column label="Activity" value="activity" />
                                                    <Workbook.Column label="Employer" value="employer" />
                                                    <Workbook.Column label="Entered By" value="enteredBy" />
                                                    <Workbook.Column label="Date Created" value="dateCreated" />
                                                </Workbook.Sheet>
                                                <Workbook.Sheet data={rbcData} name="Rejected By Client">
                                                    <Workbook.Column label="First Name" value="first_name" />
                                                    <Workbook.Column label="Last Name" value="last_name" />
                                                    <Workbook.Column label="Job Order" value="joborder" />
                                                    <Workbook.Column label="Activity" value="activity" />
                                                    <Workbook.Column label="Employer" value="employer" />
                                                    <Workbook.Column label="Entered By" value="enteredBy" />
                                                    <Workbook.Column label="Date Created" value="dateCreated" />
                                                </Workbook.Sheet>
                                                <Workbook.Sheet data={nitData} name="Total Interviews">
                                                    <Workbook.Column label="First Name" value="first_name" />
                                                    <Workbook.Column label="Last Name" value="last_name" />
                                                    <Workbook.Column label="Job Order" value="joborder" />
                                                    <Workbook.Column label="Activity" value="activity" />
                                                    <Workbook.Column label="Employer" value="employer" />
                                                    <Workbook.Column label="Entered By" value="enteredBy" />
                                                    <Workbook.Column label="Date Created" value="dateCreated" />
                                                </Workbook.Sheet>
                                                <Workbook.Sheet data={nrcData} name="Total Rejections">
                                                    <Workbook.Column label="First Name" value="first_name" />
                                                    <Workbook.Column label="Last Name" value="last_name" />
                                                    <Workbook.Column label="Job Order" value="joborder" />
                                                    <Workbook.Column label="Activity" value="activity" />
                                                    <Workbook.Column label="Employer" value="employer" />
                                                    <Workbook.Column label="Entered By" value="enteredBy" />
                                                    <Workbook.Column label="Date Created" value="dateCreated" />
                                                </Workbook.Sheet>
                                                <Workbook.Sheet data={npdData} name="Total Placements">
                                                    <Workbook.Column label="First Name" value="first_name" />
                                                    <Workbook.Column label="Last Name" value="last_name" />
                                                    <Workbook.Column label="Job Order" value="joborder" />
                                                    <Workbook.Column label="Candidate Owner" value="candidateOwner" />
                                                    <Workbook.Column label="Job Order Owner" value="joborderOwner" />
                                                    <Workbook.Column label="Date Created" value="dateCreated" />
                                                </Workbook.Sheet>
                                                <Workbook.Sheet data={candidateTableData} name="Total Candidates">
                                                    <Workbook.Column label="First Name" value="first_name" />
                                                    <Workbook.Column label="Last Name" value="last_name" />
                                                    <Workbook.Column label="Primary Skills" value="primary_skill" />
                                                    <Workbook.Column label="Candidate Owner" value="candidate_owner" />
                                                    <Workbook.Column label="Date Created" value="date_created" />
                                                </Workbook.Sheet>
                                                <Workbook.Sheet data={companiesTableData} name="Total Companies">
                                                    <Workbook.Column label="Company Name" value="name" />
                                                    <Workbook.Column label="Jobs" value="jobcount" />
                                                    <Workbook.Column label="Owner" value="owner" />
                                                    <Workbook.Column label="Date Created" value="date_created" />
                                                </Workbook.Sheet>
                                                <Workbook.Sheet data={contactTableData} name="Total Contact">
                                                    <Workbook.Column label="First Name" value="first_name" />
                                                    <Workbook.Column label="Last Name" value="last_name" />
                                                    <Workbook.Column label="Company Name" value="name" />
                                                    <Workbook.Column label="Owner" value="owner" />
                                                    <Workbook.Column label="Date Created" value="date_created" />
                                                </Workbook.Sheet>
                                            </Workbook>
                                        </div>
                                    }

                                </div>
                            </div>
                            {
                                newReportView &&
                                <div className='mt-3 mb-3'>
                                    <div style={{ width: '98%' }}>
                                        <LoadingOverlay
                                            active={loader}
                                            spinner
                                            text='Loading, Please Wait...'
                                        >
                                            {
                                                filterUsers.length > 0 ?
                                                    <div className="row justify-content-center" style={{ width: '100%', margin: 'auto', overflow: 'auto', fontSize: '13px' }}>
                                                        <div className="col-auto">
                                                            <button className='btn btn-sm btn-outline-dark mb-3' onClick={() => {
                                                                handleCopyTable()
                                                            }
                                                            }>{loader ? "Loading..." : "Export"}</button>
                                                            <div>{filterUsers && filterUsers?.length > 0 ?
                                                                <table id="newReportData" className="table table-hover table-sm" style={{ width: '100%', fontSize: '13px' }}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th style={{ display: 'none' }}>RecruiterId</th>
                                                                            <th>Recruiter</th>
                                                                            <th>Candidates</th>
                                                                            <th>Joborders</th>
                                                                            <th>Pipelines</th>
                                                                            <th>Submissions</th>
                                                                            <th>Resume Not Consideration</th>
                                                                            <th>Interview With Vendor</th>
                                                                            <th>Rejected By Vendor</th>
                                                                            <th>Interview With Client</th>
                                                                            <th>Rejected By Client</th>
                                                                            <th>Interviews</th>
                                                                            <th>Rejections</th>
                                                                            <th>Placements</th>
                                                                            <th>Companies</th>
                                                                            <th>Contact</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {filterUsers?.map(x => {
                                                                            return <tr key={x.value} >
                                                                                <td style={{ display: 'none' }}>{x.value}</td>
                                                                                <td title={`click here to export bulk report of ${x.first_name} ${x.last_name}`} style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
                                                                                    setExportReportName(`${x.first_name}${x.last_name}${moment(dateRange[0]?.startDate).format('MM-DD-YYYY')} to ${moment(dateRange[0]?.endDate).format('MM-DD-YYYY')}`)
                                                                                    getDynamicReports(x.value, "bulkExport")
                                                                                }}>{x.first_name}{" "}{x.last_name}</td>
                                                                                <td title={`click here to export Candidate report of ${x.first_name} ${x.last_name}`} style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => getDynamicReports(x.value, "candidates")}><GetCount reportType="candidates" recruiter={x.value} dateRange={dateRange} /></td>
                                                                                <td title={`click here to export Joborder report of ${x.first_name} ${x.last_name}`} style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => getDynamicReports(x.value, 'joborders')}><GetCount reportType="joborders" recruiter={x.value} dateRange={dateRange} /></td>
                                                                                <td title={`click here to export Pipelines report of ${x.first_name} ${x.last_name}`} style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => getDynamicReports(x.value, 'pipelines')}><GetCount reportType="pipelines" recruiter={x.value} dateRange={dateRange} /></td>
                                                                                <td title={`click here to export Submissions report of ${x.first_name} ${x.last_name}`} style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => getDynamicReports(x.value, 'submissions')}><GetCount reportType="submissions" recruiter={x.value} dateRange={dateRange} /></td>
                                                                                <td title={`click here to export Resume not consideration report of ${x.first_name} ${x.last_name}`} style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => getDynamicReports(x.value, 'RNC')}><GetCount reportType="RNC" recruiter={x.value} dateRange={dateRange} /></td>
                                                                                <td title={`click here to export Interview with vendor report of ${x.first_name} ${x.last_name}`} style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => getDynamicReports(x.value, 'IVW')}><GetCount reportType="IVW" recruiter={x.value} dateRange={dateRange} /></td>
                                                                                <td title={`click here to export Rejected by vendor report of ${x.first_name} ${x.last_name}`} style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => getDynamicReports(x.value, 'RBV')}><GetCount reportType="RBV" recruiter={x.value} dateRange={dateRange} /></td>
                                                                                <td title={`click here to export Interview with client report of ${x.first_name} ${x.last_name}`} style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => getDynamicReports(x.value, 'IWC')}><GetCount reportType="IWC" recruiter={x.value} dateRange={dateRange} /></td>
                                                                                <td title={`click here to export Rejected by client report of ${x.first_name} ${x.last_name}`} style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => getDynamicReports(x.value, 'RBC')}><GetCount reportType="RBC" recruiter={x.value} dateRange={dateRange} /></td>
                                                                                <td title={`click here to export Interviews report of ${x.first_name} ${x.last_name}`} style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => getDynamicReports(x.value, 'interviews')}><GetCount reportType="interviews" recruiter={x.value} dateRange={dateRange} /></td>
                                                                                <td title={`click here to export Rejections report of ${x.first_name} ${x.last_name}`} style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => getDynamicReports(x.value, 'rejections')}><GetCount reportType="rejections" recruiter={x.value} dateRange={dateRange} /></td>
                                                                                <td title={`click here to export Placements report of ${x.first_name} ${x.last_name}`} style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => getDynamicReports(x.value, 'placements')}><GetCount reportType="placements" recruiter={x.value} dateRange={dateRange} /></td>
                                                                                <td title={`click here to export Companies report of ${x.first_name} ${x.last_name}`} style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => getDynamicReports(x.value, 'companies')}><GetCount reportType="companies" recruiter={x.value} dateRange={dateRange} /></td>
                                                                                <td title={`click here to export Contact report of ${x.first_name} ${x.last_name}`} style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => getDynamicReports(x.value, 'contact')}><GetCount reportType="contact" recruiter={x.value} dateRange={dateRange} /></td>
                                                                            </tr>
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                                :
                                                                "Loading.."
                                                            }

                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    "No Data Found"
                                            }

                                        </LoadingOverlay>
                                    </div>
                                </div>
                            }

                            {
                                // viewReportData &&
                                // <div className='mt-3 mb-3'>
                                //     <div className="container">
                                //         <table id="candidate" className="table table-hover table-sm" style={{ width: '100%', fontSize: '13px' }}>
                                //             <thead>
                                //                 <th>Type</th>
                                //                 <th>Count</th>
                                //                 <th>Action</th>
                                //             </thead>
                                //             <tbody>
                                //                 <tr>
                                //                     <td style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }} onClick={() => handleOpenReportTable(newJobOrders?.data, 'Job Orders')}>New Job Orders</td><td>{newJobOrders?.count?.joborder_count}</td><td style={{ cursor: 'pointer' }} onClick={() => handleExportReports(newJobOrders?.data, 'jobOrders')}><button disabled={newJobOrders?.count?.joborder_count === 0 || loader} className="btn btn-outline-dark btn-sm">{
                                //                         loader ?
                                //                             <img style={{ width: "10px", height: '10px' }} src="./images/loader.gif" alt="loading..." />
                                //                             :
                                //                             "Export"
                                //                     }</button></td>
                                //                 </tr>
                                //                 <tr>
                                //                     <td style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }} onClick={() => handleOpenReportTable(newPipelines?.data, 'Pipeline')}>New Pipelines</td><td>{newPipelines?.count?.pipelines_count}</td><td style={{ cursor: 'pointer' }} onClick={() => handleExportReports(newPipelines?.data, 'pipeline')}><button disabled={newPipelines?.count?.pipelines_count === 0 || loader} className="btn btn-outline-dark btn-sm">{
                                //                         loader ?
                                //                             <img style={{ width: "10px", height: '10px' }} src="./images/loader.gif" alt="loading..." />
                                //                             :
                                //                             "Export"
                                //                     }</button></td>
                                //                 </tr>
                                //                 <tr>
                                //                     <td style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }} onClick={() => handleOpenReportTable(newSubmissions?.data, 'Submissions')}>New Submissions</td><td>{newSubmissions?.count?.submissionCount}</td><td style={{ cursor: 'pointer' }} onClick={() => handleExportReports(newSubmissions?.data, 'submissions')}><button disabled={newSubmissions?.count?.submissionCount === 0 || loader} className="btn btn-outline-dark btn-sm">{
                                //                         loader ?
                                //                             <img style={{ width: "10px", height: '10px' }} src="./images/loader.gif" alt="loading..." />
                                //                             :
                                //                             "Export"
                                //                     }</button></td>
                                //                 </tr>
                                //                 <tr>
                                //                     <td style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }} onClick={() => handleOpenReportTable(resumeNotConsideration?.data, 'Resume Not Consideration')} >Resume not Consideration</td><td>{resumeNotConsideration?.count?.Resumenot_count}</td><td style={{ cursor: 'pointer' }} onClick={() => handleExportReports(resumeNotConsideration?.data, 'resumeNotConsideration')}><button disabled={resumeNotConsideration?.count?.Resumenot_count === 0 || loader} className="btn btn-outline-dark btn-sm">{
                                //                         loader ?
                                //                             <img style={{ width: "10px", height: '10px' }} src="./images/loader.gif" alt="loading..." />
                                //                             :
                                //                             "Export"
                                //                     }</button></td>
                                //                 </tr>
                                //                 <tr>
                                //                     <td style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }} onClick={() => handleOpenReportTable(interviewingWithVendor?.data, 'Interview With Vendor')}>Interviewing with Vendor</td><td>{interviewingWithVendor?.count?.vendor_count}</td><td style={{ cursor: 'pointer' }} onClick={() => handleExportReports(interviewingWithVendor?.data, 'interviewWithVendor')}><button disabled={interviewingWithVendor?.count?.vendor_count === 0 || loader} className="btn btn-outline-dark btn-sm">{
                                //                         loader ?
                                //                             <img style={{ width: "10px", height: '10px' }} src="./images/loader.gif" alt="loading..." />
                                //                             :
                                //                             "Export"
                                //                     }</button></td>
                                //                 </tr>
                                //                 <tr>
                                //                     <td style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }} onClick={() => handleOpenReportTable(rejectedByVendor?.data, 'Rejected By Vendor')}>Rejected by Vendor</td><td>{rejectedByVendor?.count?.vendorRej_count}</td><td style={{ cursor: 'pointer' }} onClick={() => handleExportReports(rejectedByVendor?.data, 'rejectedByVendor')}><button disabled={rejectedByVendor?.count?.vendorRej_count === 0 || loader} className="btn btn-outline-dark btn-sm">{
                                //                         loader ?
                                //                             <img style={{ width: "10px", height: '10px' }} src="./images/loader.gif" alt="loading..." />
                                //                             :
                                //                             "Export"
                                //                     }</button></td>
                                //                 </tr>
                                //                 <tr>
                                //                     <td style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }} onClick={() => handleOpenReportTable(interviewingWithClient?.data, 'Interview With Client')} >Interviewing with Client</td><td>{interviewingWithClient?.count?.client_count}</td><td style={{ cursor: 'pointer' }} onClick={() => handleExportReports(interviewingWithClient?.data, 'interviewWithClient')}><button disabled={interviewingWithClient?.count?.client_count === 0 || loader} className="btn btn-outline-dark btn-sm">{
                                //                         loader ?
                                //                             <img style={{ width: "10px", height: '10px' }} src="./images/loader.gif" alt="loading..." />
                                //                             :
                                //                             "Export"
                                //                     }</button></td>
                                //                 </tr>
                                //                 <tr>
                                //                     <td style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }} onClick={() => handleOpenReportTable(rejectedByClient?.data, 'Rejected By Client')}>Rejected by Client</td><td>{rejectedByClient?.count?.clientRej_count}</td><td style={{ cursor: 'pointer' }} onClick={() => handleExportReports(rejectedByClient?.data, 'rejectedByClient')}><button disabled={rejectedByClient?.count?.clientRej_count === 0 || loader} className="btn btn-outline-dark btn-sm">{
                                //                         loader ?
                                //                             <img style={{ width: "10px", height: '10px' }} src="./images/loader.gif" alt="loading..." />
                                //                             :
                                //                             "Export"
                                //                     }</button></td>
                                //                 </tr>
                                //                 <tr>
                                //                     <td style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }} onClick={() => handleOpenReportTable(newInterviews?.data, 'New Interviews')}>New Interviews</td><td>{newInterviews?.count?.Interview_count}</td><td style={{ cursor: 'pointer' }} onClick={() => handleExportReports(newInterviews?.data, 'newInterviews')}><button disabled={newInterviews?.count?.Interview_count === 0 || loader} className="btn btn-outline-dark btn-sm">{
                                //                         loader ?
                                //                             <img style={{ width: "10px", height: '10px' }} src="./images/loader.gif" alt="loading..." />
                                //                             :
                                //                             "Export"
                                //                     }</button></td>
                                //                 </tr>
                                //                 <tr>
                                //                     <td style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }} onClick={() => handleOpenReportTable(newRejections?.data, 'New Rejections')}>New Rejections</td><td>{newRejections?.count?.Rejection_count}</td><td style={{ cursor: 'pointer' }} onClick={() => handleExportReports(newRejections?.data, 'newRejections')}><button disabled={newRejections?.count?.Rejection_count === 0 || loader} className="btn btn-outline-dark btn-sm">{
                                //                         loader ?
                                //                             <img style={{ width: "10px", height: '10px' }} src="./images/loader.gif" alt="loading..." />
                                //                             :
                                //                             "Export"
                                //                     }</button></td>
                                //                 </tr>
                                //                 <tr>
                                //                     <td style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }} onClick={() => handleOpenReportTable(newPlacements?.data, 'New Placements')}>New Placements</td><td>{newPlacements?.count?.placementCount}</td><td style={{ cursor: 'pointer' }} onClick={() => handleExportReports(newPlacements?.data, 'newPlacements')}><button disabled={newPlacements?.count?.placementCount === 0 || loader} className="btn btn-outline-dark btn-sm">{
                                //                         loader ?
                                //                             <img style={{ width: "10px", height: '10px' }} src="./images/loader.gif" alt="loading..." />
                                //                             :
                                //                             "Export"
                                //                     }</button></td>
                                //                 </tr>
                                //                 <tr>
                                //                     <td style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }} onClick={() => handleOpenReportTable(newCandidates?.data, 'New Candidates')}>New Candidates</td><td>{newCandidates?.count.candidate_count}</td><td style={{ cursor: 'pointer' }} onClick={() => handleExportReports(newCandidates?.data, 'newCandidates')}><button disabled={newCandidates?.count.candidate_count === 0 || loader} className="btn btn-outline-dark btn-sm">{
                                //                         loader ?
                                //                             <img style={{ width: "10px", height: '10px' }} src="./images/loader.gif" alt="loading..." />
                                //                             :
                                //                             "Export"
                                //                     }</button></td>
                                //                 </tr>
                                //                 <tr>
                                //                     <td style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }} onClick={() => handleOpenReportTable(newCompanies?.data, 'New Companies')}>New Companies</td><td>{newCompanies?.count?.company_count}</td><td style={{ cursor: 'pointer' }} onClick={() => handleExportReports(newCompanies?.data, 'newCompanies')}><button disabled={newCompanies?.count?.company_count === 0 || loader} className="btn btn-outline-dark btn-sm">{
                                //                         loader ?
                                //                             <img style={{ width: "10px", height: '10px' }} src="./images/loader.gif" alt="loading..." />
                                //                             :
                                //                             "Export"
                                //                     }</button></td>
                                //                 </tr>
                                //                 <tr>
                                //                     <td style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }} onClick={() => handleOpenReportTable(newContact?.data, 'New Contact')}>New Contact</td><td>{newContact?.count?.contact_count}</td><td style={{ cursor: 'pointer' }} onClick={() => handleExportReports(newContact?.data, 'newContact')}><button disabled={newContact?.count?.contact_count === 0 || loader} className="btn btn-outline-dark btn-sm">{
                                //                         loader ?
                                //                             <img style={{ width: "10px", height: '10px' }} src="./images/loader.gif" alt="loading..." />
                                //                             :
                                //                             "Export"
                                //                     }</button></td>
                                //                 </tr>
                                //             </tbody>
                                //         </table>
                                //     </div>
                                // </div>
                            }

                            <Modal
                                isOpen={reportTableView}
                                onRequestClose={() => {
                                    setReportName("")
                                    setReportTableView(false)
                                }}
                                style={viewModal1}
                                contentLabel={reportType}
                                shouldCloseOnOverlayClick={true}
                            >
                                <div className='col-12' style={{ fontSize: '12px' }}>
                                    <div style={{ float: 'right' }}>
                                        {
                                            reportType === 'Pipeline' &&
                                            <button onClick={() => handleExportReports(newPipelines?.data, 'pipeline')} className="btn btn-outline-dark btn-sm mr-2" type="button">Export</button>

                                        }
                                        {
                                            reportType === 'Job Orders' &&
                                            <button onClick={() => handleExportReports(newJobOrders?.data, 'jobOrders')} className="btn btn-outline-dark btn-sm mr-2" type="button">Export</button>

                                        }
                                        {
                                            reportType === 'Submissions' &&
                                            <button onClick={() => handleExportReports(newSubmissions?.data, 'submissions')} className="btn btn-outline-dark btn-sm mr-2" type="button">Export</button>

                                        }
                                        {
                                            reportType === 'Resume Not Consideration' &&
                                            <button onClick={() => handleExportReports(resumeNotConsideration?.data, 'resumeNotConsideration')} className="btn btn-outline-dark btn-sm mr-2" type="button">Export</button>

                                        }
                                        {
                                            reportType === 'Interview With Vendor' &&
                                            <button onClick={() => handleExportReports(interviewingWithVendor?.data, 'interviewWithVendor')} className="btn btn-outline-dark btn-sm mr-2" type="button">Export</button>

                                        }
                                        {
                                            reportType === 'Rejected By Vendor' &&
                                            <button onClick={() => handleExportReports(rejectedByVendor?.data, 'rejectedByVendor')} className="btn btn-outline-dark btn-sm mr-2" type="button">Export</button>

                                        }
                                        {
                                            reportType === 'Interview With Client' &&
                                            <button onClick={() => handleExportReports(interviewingWithClient?.data, 'interviewWithClient')} className="btn btn-outline-dark btn-sm mr-2" type="button">Export</button>

                                        }
                                        {
                                            reportType === 'Rejected By Client' &&
                                            <button onClick={() => handleExportReports(rejectedByClient?.data, 'rejectedByClient')} className="btn btn-outline-dark btn-sm mr-2" type="button">Export</button>

                                        }
                                        {
                                            reportType === 'New Interviews' &&
                                            <button onClick={() => handleExportReports(newInterviews?.data, 'newInterviews')} className="btn btn-outline-dark btn-sm mr-2" type="button">Export</button>

                                        }
                                        {
                                            reportType === 'New Rejections' &&
                                            <button onClick={() => handleExportReports(newRejections?.data, 'newRejections')} className="btn btn-outline-dark btn-sm mr-2" type="button">Export</button>

                                        }
                                        {
                                            reportType === 'New Placements' &&
                                            <button onClick={() => handleExportReports(newPlacements?.data, 'newPlacements')} className="btn btn-outline-dark btn-sm mr-2" type="button">Export</button>

                                        }
                                        {
                                            reportType === 'New Candidates' &&
                                            <button onClick={() => handleExportReports(newCandidates?.data, 'newCandidates')} className="btn btn-outline-dark btn-sm mr-2" type="button">Export</button>

                                        }
                                        {
                                            reportType === 'New Companies' &&
                                            <button onClick={() => handleExportReports(newCompanies?.data, 'newCompanies')} className="btn btn-outline-dark btn-sm mr-2" type="button">Export</button>

                                        }
                                        {
                                            reportType === 'New Contact' &&
                                            <button onClick={() => handleExportReports(newContact?.data, 'newContact')} className="btn btn-outline-dark btn-sm mr-2" type="button">Export</button>

                                        }
                                        <button onClick={() => {
                                            setReportName("")
                                            setReportTableView(false)

                                        }} className="btn btn-outline-dark btn-sm" type="button">Close</button>
                                    </div>
                                    <label>{loader ? "Loading...." : reportType}</label>
                                    <div className="mt-2">
                                        {
                                            reportType === 'Pipeline' &&
                                            <>
                                                <table id="reportsTable" className='table table-hover table-sm'>
                                                    <thead>
                                                        <tr>
                                                            <th>First Name</th>
                                                            <th>Last Name</th>
                                                            <th>Job Order Id</th>
                                                            <th>Company Name</th>
                                                            <th>Job Order</th>
                                                            <th>Entered By</th>
                                                            <th>Date Created</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            pipelineTableData.map(x => {
                                                                return <tr>
                                                                    <td><label
                                                                        style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            getSingleCandidate(x?.candidate_id)
                                                                        }}>{x.first_name}</label></td>
                                                                    <td><label
                                                                        style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            getSingleCandidate(x?.candidate_id)
                                                                        }}>{x.last_name}</label></td>
                                                                    <td>{x.joborder_id}</td>
                                                                    <td>{x.company_name}</td>
                                                                    <td>
                                                                        <label
                                                                            style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                            onClick={() => {
                                                                                setSelectedJobOrderId(x?.jobId)
                                                                                setJobOrderModal(true)
                                                                            }}>{x.title}</label></td>
                                                                    <td>{x.enteredBy}</td>
                                                                    <td>{x.dateCreated}</td>
                                                                </tr>
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </>
                                        }
                                        {
                                            reportType === 'Job Orders' &&
                                            <>
                                                <table id="reportsTable" className='table table-hover table-sm'>
                                                    <thead>
                                                        <tr>
                                                            <th>Job Order Id</th>
                                                            <th>Title</th>
                                                            <th>Company</th>
                                                            <th>S</th>
                                                            <th>P</th>
                                                            <th>Recruiter</th>
                                                            <th>Owner</th>
                                                            <th>Date Created</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            jobOrderTableData.map(x => {
                                                                return <tr>
                                                                    <td>{x.jobId}</td>
                                                                    <td>
                                                                        <label
                                                                            style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                            onClick={() => {
                                                                                setSelectedJobOrderId(x?.joborder_id)
                                                                                setJobOrderModal(true)
                                                                            }}>{x.title}</label></td>
                                                                    <td>{x.company}</td>
                                                                    <td>{x.submission}</td>
                                                                    <td>{x.pipeline}</td>
                                                                    <td>{x.recruiter}</td>
                                                                    <td>{x.Owner}</td>
                                                                    <td>{x.dateCreated}</td>
                                                                </tr>
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </>
                                        }
                                        {
                                            reportType === 'Submissions' &&
                                            <>
                                                <table id="reportsTable" className='table table-hover table-sm'>
                                                    <thead>
                                                        <tr>
                                                            <th>First Name</th>
                                                            <th>Last Name</th>
                                                            <th>Candidate Owner</th>
                                                            <th>Job Order Id</th>
                                                            <th>Job Order</th>
                                                            <th>Company Name</th>
                                                            <th>Job Order Owner</th>
                                                            <th>Date Created</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            submissionTableData.map(x => {
                                                                return <tr>
                                                                    <td><label
                                                                        style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            getSingleCandidate(x?.candidate_id)
                                                                        }}>{x.first_name}</label></td>
                                                                    <td><label
                                                                        style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            getSingleCandidate(x?.candidate_id)
                                                                        }}>{x.last_name}</label></td>
                                                                    <td>{x.candidate_owner}</td>
                                                                    <td>{x.joborder_id}</td>
                                                                    <td>
                                                                        <label
                                                                            style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                            onClick={() => {
                                                                                setSelectedJobOrderId(x?.jobId)
                                                                                setJobOrderModal(true)
                                                                            }}>{x.title}</label></td>
                                                                    <td>{x.company_name}</td>
                                                                    <td>{x.joborder_owner}</td>
                                                                    <td>{x.dateCreated}</td>
                                                                </tr>
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </>
                                        }
                                        {
                                            reportType === 'Resume Not Consideration' &&
                                            <>
                                                <table id="reportsTable" className='table table-hover table-sm'>
                                                    <thead>
                                                        <tr>
                                                            <th>First Name</th>
                                                            <th>Last Name</th>
                                                            <th>Job Order</th>
                                                            <th>Activity</th>
                                                            <th>Notes</th>
                                                            <th>Employer</th>
                                                            <th>Entered By</th>
                                                            <th>Date Created</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            rncData.map(x => {
                                                                return <tr>
                                                                    <td><label
                                                                        style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            getSingleCandidate(x?.candidate_id)
                                                                        }}>{x.first_name}</label></td>
                                                                    <td><label
                                                                        style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            getSingleCandidate(x?.candidate_id)
                                                                        }}>{x.last_name}</label></td>
                                                                    <td>
                                                                        <label
                                                                            style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                            onClick={() => {
                                                                                setSelectedJobOrderId(x?.jobId)
                                                                                setJobOrderModal(true)
                                                                            }}>{x.joborder}</label></td>
                                                                    <td>{x.activity}</td>
                                                                    <td>{x.notes}</td>
                                                                    <td>{x.employer}</td>
                                                                    <td>{x.enteredBy}</td>
                                                                    <td>{x.dateCreated}</td>
                                                                </tr>
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </>
                                        }
                                        {
                                            reportType === 'Interview With Vendor' &&
                                            <>
                                                <table id="reportsTable" className='table table-hover table-sm'>
                                                    <thead>
                                                        <tr>
                                                            <th>First Name</th>
                                                            <th>Last Name</th>
                                                            <th>Job Order</th>
                                                            <th>Activity</th>
                                                            <th>Notes</th>
                                                            <th>Employer</th>
                                                            <th>Entered By</th>
                                                            <th>Date Created</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            iwvData.map(x => {
                                                                return <tr>
                                                                    <td><label
                                                                        style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            getSingleCandidate(x?.candidate_id)
                                                                        }}>{x.first_name}</label></td>
                                                                    <td><label
                                                                        style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            getSingleCandidate(x?.candidate_id)
                                                                        }}>{x.last_name}</label></td>
                                                                    <td>
                                                                        <label
                                                                            style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                            onClick={() => {
                                                                                setSelectedJobOrderId(x?.jobId)
                                                                                setJobOrderModal(true)
                                                                            }}>{x.joborder}</label></td>
                                                                    <td>{x.activity}</td>
                                                                    <td>{x.notes}</td>
                                                                    <td>{x.employer}</td>
                                                                    <td>{x.enteredBy}</td>
                                                                    <td>{x.dateCreated}</td>
                                                                </tr>
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </>
                                        }
                                        {
                                            reportType === 'Rejected By Vendor' &&
                                            <>
                                                <table id="reportsTable" className='table table-hover table-sm'>
                                                    <thead>
                                                        <tr>
                                                            <th>First Name</th>
                                                            <th>Last Name</th>
                                                            <th>Job Order</th>
                                                            <th>Activity</th>
                                                            <th>Notes</th>
                                                            <th>Employer</th>
                                                            <th>Entered By</th>
                                                            <th>Date Created</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            rbvData.map(x => {
                                                                return <tr>
                                                                    <td><label
                                                                        style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            getSingleCandidate(x?.candidate_id)
                                                                        }}>{x.first_name}</label></td>
                                                                    <td><label
                                                                        style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            getSingleCandidate(x?.candidate_id)
                                                                        }}>{x.last_name}</label></td>
                                                                    <td>
                                                                        <label
                                                                            style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                            onClick={() => {
                                                                                setSelectedJobOrderId(x?.jobId)
                                                                                setJobOrderModal(true)
                                                                            }}>{x.joborder}</label></td>      <td>{x.activity}</td>
                                                                    <td>{x.notes}</td>
                                                                    <td>{x.employer}</td>
                                                                    <td>{x.enteredBy}</td>
                                                                    <td>{x.dateCreated}</td>
                                                                </tr>
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </>
                                        }
                                        {
                                            reportType === 'Interview With Client' &&
                                            <>
                                                <table id="reportsTable" className='table table-hover table-sm'>
                                                    <thead>
                                                        <tr>
                                                            <th>First Name</th>
                                                            <th>Last Name</th>
                                                            <th>Job Order</th>
                                                            <th>Activity</th>
                                                            <th>Notes</th>
                                                            <th>Employer</th>
                                                            <th>Entered By</th>
                                                            <th>Date Created</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            iwcData.map(x => {
                                                                return <tr>
                                                                    <td><label
                                                                        style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            getSingleCandidate(x?.candidate_id)
                                                                        }}>{x.first_name}</label></td>
                                                                    <td><label
                                                                        style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            getSingleCandidate(x?.candidate_id)
                                                                        }}>{x.last_name}</label></td>
                                                                    <td>
                                                                        <label
                                                                            style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                            onClick={() => {
                                                                                setSelectedJobOrderId(x?.jobId)
                                                                                setJobOrderModal(true)
                                                                            }}>{x.joborder}</label></td>     <td>{x.activity}</td>
                                                                    <td>{x.notes}</td>
                                                                    <td>{x.employer}</td>
                                                                    <td>{x.enteredBy}</td>
                                                                    <td>{x.dateCreated}</td>
                                                                </tr>
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </>
                                        }
                                        {
                                            reportType === 'Rejected By Client' &&
                                            <>
                                                <table id="reportsTable" className='table table-hover table-sm'>
                                                    <thead>
                                                        <tr>
                                                            <th>First Name</th>
                                                            <th>Last Name</th>
                                                            <th>Job Order</th>
                                                            <th>Activity</th>
                                                            <th>Notes</th>
                                                            <th>Employer</th>
                                                            <th>Entered By</th>
                                                            <th>Date Created</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            rbcData.map(x => {
                                                                return <tr>
                                                                    <td><label
                                                                        style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            getSingleCandidate(x?.candidate_id)
                                                                        }}>{x.first_name}</label></td>
                                                                    <td><label
                                                                        style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            getSingleCandidate(x?.candidate_id)
                                                                        }}>{x.last_name}</label></td>
                                                                    <td>
                                                                        <label
                                                                            style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                            onClick={() => {
                                                                                setSelectedJobOrderId(x?.jobId)
                                                                                setJobOrderModal(true)
                                                                            }}>{x.joborder}</label></td>    <td>{x.activity}</td>
                                                                    <td>{x.notes}</td>
                                                                    <td>{x.employer}</td>
                                                                    <td>{x.enteredBy}</td>
                                                                    <td>{x.dateCreated}</td>
                                                                </tr>
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </>
                                        }
                                        {
                                            reportType === 'New Interviews' &&
                                            <>
                                                <table id="reportsTable" className='table table-hover table-sm'>
                                                    <thead>
                                                        <tr>
                                                            <th>First Name</th>
                                                            <th>Last Name</th>
                                                            <th>Job Order</th>
                                                            <th>Activity</th>
                                                            <th>Notes</th>
                                                            <th>Employer</th>
                                                            <th>Entered By</th>
                                                            <th>Date Created</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            nitData.map(x => {
                                                                return <tr>
                                                                    <td><label
                                                                        style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            getSingleCandidate(x?.candidate_id)
                                                                        }}>{x.first_name}</label></td>
                                                                    <td><label
                                                                        style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            getSingleCandidate(x?.candidate_id)
                                                                        }}>{x.last_name}</label></td>
                                                                    <td>
                                                                        <label
                                                                            style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                            onClick={() => {
                                                                                setSelectedJobOrderId(x?.jobId)
                                                                                setJobOrderModal(true)
                                                                            }}>{x.joborder}</label></td>    <td>{x.activity}</td>
                                                                    <td>{x.notes}</td>
                                                                    <td>{x.employer}</td>
                                                                    <td>{x.enteredBy}</td>
                                                                    <td>{x.dateCreated}</td>
                                                                </tr>
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </>
                                        }
                                        {
                                            reportType === 'New Rejections' &&
                                            <>
                                                <table id="reportsTable" className='table table-hover table-sm'>
                                                    <thead>
                                                        <tr>
                                                            <th>First Name</th>
                                                            <th>Last Name</th>
                                                            <th>Job Order</th>
                                                            <th>Activity</th>
                                                            <th>Notes</th>
                                                            <th>Employer</th>
                                                            <th>Entered By</th>
                                                            <th>Date Created</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            nrcData.map(x => {
                                                                return <tr>
                                                                    <td><label
                                                                        style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            getSingleCandidate(x?.candidate_id)
                                                                        }}>{x.first_name}</label></td>
                                                                    <td><label
                                                                        style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            getSingleCandidate(x?.candidate_id)
                                                                        }}>{x.last_name}</label></td>
                                                                    <td>
                                                                        <label
                                                                            style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                            onClick={() => {
                                                                                setSelectedJobOrderId(x?.jobId)
                                                                                setJobOrderModal(true)
                                                                            }}>{x.joborder}</label></td>       <td>{x.activity}</td>
                                                                    <td>{x.notes}</td>
                                                                    <td>{x.employer}</td>
                                                                    <td>{x.enteredBy}</td>
                                                                    <td>{x.dateCreated}</td>
                                                                </tr>
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </>
                                        }
                                        {
                                            reportType === 'New Placements' &&
                                            <>
                                                <table id="reportsTable" className='table table-hover table-sm'>
                                                    <thead>
                                                        <tr>
                                                            <th>First Name</th>
                                                            <th>Last Name</th>
                                                            <th>Job Order</th>
                                                            <th>Candidate Owner</th>
                                                            <th>Job Order Owner</th>
                                                            <th>Date Created</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            npdData.map(x => {
                                                                return <tr>
                                                                    <td><label
                                                                        style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            getSingleCandidate(x?.candidate_id)
                                                                        }}>{x.first_name}</label></td>
                                                                    <td><label
                                                                        style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            getSingleCandidate(x?.candidate_id)
                                                                        }}>{x.last_name}</label></td>
                                                                    <td>
                                                                        <label
                                                                            style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                            onClick={() => {
                                                                                setSelectedJobOrderId(x?.jobId)
                                                                                setJobOrderModal(true)
                                                                            }}>{x.joborder}</label></td>      <td>{x.candidateOwner}</td>
                                                                    <td>{x.joborderOwner}</td>
                                                                    <td>{x.date_created}</td>
                                                                </tr>
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </>
                                        }
                                        {
                                            reportType === 'New Candidates' &&
                                            <>
                                                <table id="reportsTable" className='table table-hover table-sm'>
                                                    <thead>
                                                        <tr>
                                                            <th>First Name</th>
                                                            <th>Last Name</th>
                                                            <th>Primary Skills</th>
                                                            <th>Candidate Owner</th>
                                                            <th>Date Created</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            candidateTableData.map(x => {
                                                                return <tr>
                                                                    <td><label
                                                                        style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            getSingleCandidate(x?.candidate_id)
                                                                        }}>{x.first_name}</label></td>
                                                                    <td><label
                                                                        style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            getSingleCandidate(x?.candidate_id)
                                                                        }}>{x.last_name}</label></td>
                                                                    <td>{x.primary_skill}</td>
                                                                    <td>{x.candidate_owner}</td>
                                                                    <td>{x.date_created}</td>
                                                                </tr>
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </>
                                        }
                                        {
                                            reportType === 'New Companies' &&
                                            <>
                                                <table id="reportsTable" className='table table-hover table-sm'>
                                                    <thead>
                                                        <tr>
                                                            <th>Company Name</th>
                                                            <th>Jobs</th>
                                                            <th>Owner</th>
                                                            <th>Date Created</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            companiesTableData.map(x => {
                                                                return <tr>
                                                                    <td>{x.name}</td>
                                                                    <td>{x.jobcount}</td>
                                                                    <td>{x.owner}</td>
                                                                    <td>{x.date_created}</td>
                                                                </tr>
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </>
                                        }
                                        {
                                            reportType === 'New Contact' &&
                                            <>
                                                <table id="reportsTable" className='table table-hover table-sm'>
                                                    <thead>
                                                        <tr>
                                                            <th>First Name</th>
                                                            <th>Last Name</th>
                                                            <th>Company Name</th>
                                                            <th>Owner</th>
                                                            <th>Date Created</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            contactTableData.map(x => {
                                                                return <tr>
                                                                    <td>{x.first_name}</td>
                                                                    <td>{x.last_name}</td>
                                                                    <td>{x.name}</td>
                                                                    <td>{x.owner}</td>
                                                                    <td>{x.date_created}</td>
                                                                </tr>
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </>
                                        }
                                    </div>

                                </div>
                            </Modal>
                        </>

                    )}
                    {
                        accountReportView && (
                            <>
                                <div className='row col-12'>
                                    <div className="col-12">
                                        <h5>Account Reports</h5>
                                        <div className='mt-1 mb-1'>
                                            {
                                                <div className='row mb-2'>
                                                    <div className="col-8">
                                                        <label className='mb-3'> Date Range Between {moment(dateRange[0]?.startDate).format('MM-DD-YYYY')} and {moment(dateRange[0]?.endDate).format('MM-DD-YYYY')}</label>
                                                    </div>


                                                </div>
                                            }
                                        </div>
                                        <button disabled={loader} type="button" className='btn btn-outline-dark btn-sm' onClick={() => setDatePicker(!datePicker)}>
                                            Select Date Range
                                        </button>
                                        <Modal
                                            isOpen={datePicker}
                                            onRequestClose={() => setDatePicker(false)}
                                            style={customStyles}
                                            shouldCloseOnOverlayClick={true}
                                        >
                                            <div>
                                                <DateRangePicker
                                                    ranges={dateRange}
                                                    onChange={(item) => handleDateRange([item.selection])}
                                                    showSelectionPreview={true}
                                                    moveRangeOnFirstSelection={false}
                                                    months={2}
                                                    direction="horizontal"
                                                />
                                            </div>
                                            <center>
                                                <button type="button" className='btn btn-outline-dark btn-sm' onClick={() => handleSearch()}>
                                                    Search
                                                </button>
                                                <button type="button" className='btn btn-outline-dark btn-sm ml-2' onClick={() => setDatePicker(false)}>
                                                    Cancel
                                                </button>
                                            </center>
                                        </Modal>
                                    </div>
                                    <div className='col-4 mt-2'>
                                        <Select
                                            placeholder="Select Recruiter"
                                            onChange={(selectedOption) => handleSelect(selectedOption, 'accountReportView')}
                                            options={users}
                                        />
                                    </div>
                                    <div className='col-12 mt-2'>
                                        {
                                            accountReportData.length > 0 &&
                                            <table id="candidate" className="table table-hover table-sm" style={{ width: '100%' }}>
                                                <thead>
                                                    <th>Manager</th>
                                                    <th>Accounts</th>
                                                    <th>Hot Jobs</th>
                                                    <th>Open Positions</th>
                                                    <th>New Demands</th>
                                                    <th>Submission</th>
                                                    <th>Interviews</th>
                                                    <th>I/V Aborts</th>
                                                    <th>Rejects</th>
                                                    <th>Closure</th>
                                                    <th>Use Bench</th>
                                                    <th>Connects(Non-Transactional)</th>
                                                    <th>Cum Closers</th>
                                                    <th>Target</th>
                                                    <th>% Achieved</th>
                                                </thead>
                                                <tbody>
                                                    {
                                                        accountReportData?.map(x => {
                                                            return (
                                                                <tr>
                                                                    <td>{x?.owner_first_name} {x?.owner_last_name}</td>
                                                                    <td>{x?.name}</td>
                                                                    <td>{x?.hot_count}</td>
                                                                    <td>{x?.openings_count}</td>
                                                                    <td>{x?.new_demands}</td>
                                                                    <td>{x?.submission_count}</td>
                                                                    <td>{x?.interview_count}</td>
                                                                    <td>{x?.iv_aborts}</td>
                                                                    <td>{x?.rejection_count}</td>
                                                                    <td>{x?.closure_count}</td>
                                                                    <td>{x?.bench_count}</td>
                                                                    <td>{x?.connects}</td>
                                                                    <td>{x?.cum_closers}</td>
                                                                    <td>{x?.target}</td>
                                                                    <td>{x?.achieved}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                        }


                                    </div>
                                </div>
                            </>

                        )
                    }
                </div >
                <Modal
                    isOpen={jobOrderModal}
                    onRequestClose={() => {
                        setJobOrderModal(false)
                    }}
                    style={customStyles2}
                    shouldCloseOnOverlayClick={false}
                >
                    <div>
                        <SingleJobOrder jobSlug={selectedJobOrderId} closeModal={(data) => setJobOrderModal(data)} />
                    </div>
                </Modal>
                <div>
                    <Modal
                        isOpen={candidateEditModal}
                        onRequestClose={() => {
                            setCandidateEditModal(false)
                        }}
                        style={customStyles2}
                        shouldCloseOnOverlayClick={false}
                    >
                        {/* <label><b>{`${user?.firstName}'s Information`}</b></label> */}
                        <button className="btn btn-danger float-right mb-2" onClick={() => {
                            setCandidateEditModal(false)
                        }}>close</button>
                        <div>
                            {/* {selectedCandidateId} */}
                            <EditCandidate candidate={candidateUpdatedData} access={props.access} />
                            <label>Activity Information</label>
                            {
                                activityInfo.length > 0 ?
                                    <div className="container">
                                        <table className="table table-hover table-sm" style={{ width: '100%' }}>
                                            <thead>
                                                <th>Date</th>
                                                <th>Type</th>
                                                <th>Entered</th>
                                                <th>Regarding</th>
                                                <th>Notes</th>
                                            </thead>
                                            <tbody>
                                                {
                                                    activityInfo.map((x, index) => {
                                                        return (
                                                            <tr id={index}>
                                                                <td>{moment(x.date_created).format("MM-DD-YY hh:mm a")}</td>
                                                                <td>{x.short_description}</td>
                                                                <td>{x.user_first_name} {x.user_last_name}</td>
                                                                <td>{x.regarding}</td>
                                                                <td>{getData(x.notes)}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    <div>No Activity Data Found</div>
                            }
                        </div>
                    </Modal>
                </div>
            </div >
        )
    )
}

const customStyles = {
    content: {
        position: 'absolute',
        inset: '40px',
        border: '1px solid rgb(204, 204, 204)',
        background: 'rgb(255, 255, 255)',
        overflow: 'auto',
        borderRadius: '4px',
        outline: 'none',
        padding: '20px',
        width: 'fit-content',
        height: 'fit-content',
        margin: 'auto'
    }
};

const viewModal = {
    content: {
        position: 'absolute',
        inset: '40px',
        border: '1px solid rgb(204, 204, 204)',
        background: 'rgb(255, 255, 255)',
        overflow: 'auto',
        borderRadius: '4px',
        outline: 'none',
        padding: '20px',
        width: '95%',
        height: '90vh',
        margin: 'auto'
    }
};

const viewModal1 = {
    content: {
        position: 'absolute',
        inset: '40px',
        border: '1px solid rgb(204, 204, 204)',
        background: 'rgb(255, 255, 255)',
        overflow: 'auto',
        borderRadius: '4px',
        outline: 'none',
        padding: '20px',
        width: '80%',
        height: '90vh',
        margin: 'auto',
        marginTop: '3%'
    }
};

const customStyles2 = {
    content: {
        top: '52%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        height: '85%'
    },
    overlay: { zIndex: 99 }
};

