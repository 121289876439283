import React from 'react'
import { useEffect, useState } from 'react'
import moment from 'moment'
import DataTable from 'react-data-table-component';
import axios from 'axios';
import FilterComponent from '../Common/FilterComponent';



export default function Candidate(props) {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [filterType, setFilterType] = useState(null)
    const [filteredData, setFilteredData] = useState([])


    const fetchUsers = async page => {
        setLoading(true);
        const response = await axios.get(`${props.url}&page=${page}&per_page=${perPage}&delay=1`);
        setData(response.data.data);
        setFilteredData(response.data.data.filter(
            item =>
                JSON.stringify(item)
                    .toLowerCase()
                    .indexOf(filterText.toLowerCase()) !== -1
        ))
        setTotalRows(response.data.pageCount);
        setLoading(false);
    };

    const handlePageChange = page => {
        fetchUsers(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const response = await axios.get(`${props.url}&page=${page}&per_page=${newPerPage}&delay=1`);
        setData(response.data.data);
        setPerPage(newPerPage);
        setFilteredData(response.data.data.filter(
            item =>
                JSON.stringify(item)
                    .toLowerCase()
                    .indexOf(filterText.toLowerCase()) !== -1
        ))
        setLoading(false);
    };

    useEffect(() => {
        fetchUsers(1); // fetch page 1 of users
    }, [props.url]);

    const getData = (data) => {
        if (data) {
            return (<p dangerouslySetInnerHTML={{ __html: data.concat(" ") }} />)
        }
    }

    const columns = [
        {
            name: "Name",
            selector: row => `${row.first_name + " " + row.last_name}`,
            sortable: true,
            width: "200px"
        },
        {
            name: "Regarding",
            selector: row => <label title={row.regarding}>{row.regarding}</label>,
            sortable: true,
            width: "300px"
        },
        {
            name: "Event",
            selector: row => <label title={row.short_description}>{row.short_description}</label>,
            sortable: true,
            width: "100px"
        },
        {
            name: "Activity Status",
            selector: row => <label title={row.joborder_status_type_description[0]?.short_description}>{row.joborder_status_type_description[0]?.short_description}</label>,
            sortable: true,
            width: "150px"
        },
        {
            name: "Event Notes",
            selector: row => <div title={getData(row.notes)} style={{ textOverflow: 'clip', overflowX: 'auto' }} >{getData(row.notes)}</div>,
            sortable: true,
            width: "200px"
        },
        {
            name: "Entered By",
            selector: row => row.employer_contact_name !== null ? <>{`${row.employer_contact_name}  ${row.employer_name !== null ? row.employer_name : ""}`} </> : <>{`${row.user_first_name + " " + row.user_last_name}`}</>,
            sortable: true,
            width: "200px"
        },
        {
            name: "Created Time",
            selector: row => moment(row.date_created).format("MM-DD-YYYY, h:mm:ss"),
            sortable: true,
            width: "200px"
        },
        // {
        //     name: "Modified Time",
        //     selector: row => moment(row.date_modified).format("MM-DD-YYYY, h:mm:ss"),
        //     sortable: true,
        //     width: "8%"
        // }
    ]

    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    );

    const subHeaderComponent = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: totalRows,
    };

    const handleFilterData = (type) => {
        let filterData
        setFilterType(type)
        if (type === 'clear') {
            setFilteredData(data)
            setFilterType(null)
            return
        }
        if (type === 'pipelines') {
            setFilteredData(data.filter(x => {
                return x.notes === 'Added Candidate to pipeline'
            }))
        }
        if (type === 'submissions') {
            filterData = '3.Submitted'
            setData()
        }
        if (type === 'interviews') {
            filterData = '4.Interviewing'
            setData()
        }
        if (type === 'offered') {
            filterData = '6.Offered'
            setData()
        }
        function setData() {
            return setFilteredData(data.filter(x => {
                return x.joborder_status_type_description[0]?.short_description === filterData
            }))
        }
    }



    return (
        <div>
            <div className='col-6 row mt-1 mb-1'>
                <button onClick={() => handleFilterData('pipelines')} className={filterType === 'pipelines' ? 'btn btn-sm btn-outline-dark mr-2 active' : 'btn btn-sm btn-outline-dark mr-2'}>Pipelines</button>
                <button onClick={() => handleFilterData('submissions')} className={filterType === 'submissions' ? 'btn btn-sm btn-outline-dark mr-2 active' : 'btn btn-sm btn-outline-dark mr-2'}>Submissions</button>
                <button onClick={() => handleFilterData('interviews')} className={filterType === 'interviews' ? 'btn btn-sm btn-outline-dark mr-2 active' : 'btn btn-sm btn-outline-dark mr-2'}>Interviews</button>
                <button onClick={() => handleFilterData('offered')} className={filterType === 'offered' ? 'btn btn-sm btn-outline-dark mr-2 active' : 'btn btn-sm btn-outline-dark mr-2'}>Offered</button>
                {
                    filterType !== null &&
                    <button onClick={() => handleFilterData('clear')} className={'btn btn-sm btn-outline-dark mr-2'}>Clear X</button>
                }
            </div>

            <DataTable
                columns={columns}
                data={filteredData}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                subHeader
                subHeaderComponent={subHeaderComponent}
                paginationComponentOptions={paginationComponentOptions}
            />
        </div>
    )
}

