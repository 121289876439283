import React, { useEffect, useState } from 'react'
import { OPEN_CATS_URL } from '../../config';
import BootstrapTable from 'react-bootstrap-table-next';
import LoadingOverlay from 'react-loading-overlay';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import Select from 'react-select'
import Modal from 'react-modal'
import moment from 'moment/moment';
import $ from 'jquery'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export default function Settings(props) {

    const { SearchBar } = Search;
    const [data, setData] = useState([])
    const [viewResumeAccess, setViewResumeAccess] = useState(false)
    const [mergeUsers, setMergeUsers] = useState(false)
    const [loader, setLoader] = useState(false)
    const [selectedUserData, setSelectedUserData] = useState({})
    const [isMerge, setIsMerge] = useState(false)
    const [message, setMessage] = useState(null)
    const [jobTypeView, setJobTypeView] = useState(false)
    const [jobTypeString, setJobTypeString] = useState(null)
    const [jobTypeData, setJobTypeData] = useState([])
    const [editData, setEditData] = useState(false)
    const [editId, setEditId] = useState(0)

    useEffect(() => {
        if (props.showSettings) {
            getUsers()
        }
        //eslint-disable-next-line
    }, [props.showSettings])


    useEffect(() => {
        getJobTypesData()
    }, [])

    const getUsers = async () => {
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/authRouter/getUsers`)
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setData(result.data)
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoader(false)
            });
        setLoader(false)
    }


    const updateAccess = async (e, data) => {
        if (e.target.value !== "") {
            setLoader(true)
            await fetch(
                `${OPEN_CATS_URL}/authRouter/updateResumeAccess`,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        value: e.target.value,
                        data: data
                    }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            )
                .then((response) => response.json())
                .then((result) => {
                    setLoader(false)
                    getUsers()
                })
                .catch((error) => {
                    setLoader(false)
                    console.error('Error:', error);
                });
            setLoader(false)
        }
    }

    const handleView = (type) => {
        if (type === 'resume_access') {
            setViewResumeAccess(true)
            setMergeUsers(false)
            setJobTypeView(false)
        }
        if (type === 'merge_users') {
            setMergeUsers(true)
            setViewResumeAccess(false)
            setJobTypeView(false)
        }
        if (type === 'job_type_view') {
            setMergeUsers(false)
            setViewResumeAccess(false)
            setJobTypeView(true)
        }
    }

    const columns = [{
        dataField: 'username',
        text: 'Username'
    }, {
        dataField: 'email',
        text: 'Email'
    }, {
        dataField: 'nats_access',
        text: 'Nats Access'
    },
    {
        dataField: 'access',
        text: 'Access'
    }, {
        dataField: 'updateAccess',
        text: 'Update Access'
    }];

    const options = {
        sizePerPageList: [{
            text: '5', value: 5
        }, {
            text: '10', value: 10
        }, {
            text: 'All', value: data?.filter(y => {
                return y.user_name !== "cats@rootadmin" && y.email !== "admin@testdomain.com"
            }).length
        }],
        onSizePerPageChange: (sizePerPage, page) => {
            console.log('Size per page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
        },
        onPageChange: (page, sizePerPage) => {
            console.log('Page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
        }
    };

    const rowStyle = (row, rowIndex) => {
        const style = {};
        style.fontSize = 14;
        return style;
    };

    const usersData = data?.filter(y => {
        return y.user_name !== "cats@rootadmin" && y.email !== "admin@testdomain.com"
    })?.map(x => {
        return {
            label: `${x.user_name}-${x.email}`,
            value: x.user_id,
        }
    })

    const handleSelect = (selectedOption, type) => {
        if (type === 'sourceUser') {
            setSelectedUserData({
                ...selectedUserData, sourceUser: selectedOption
            })
        }
        if (type === 'destinationUser') {
            setSelectedUserData({
                ...selectedUserData, destinationUser: selectedOption
            })
        }
    }

    const handleMerge = async () => {
        setMessage(null)
        setIsMerge(!isMerge)
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/authRouter/mergeUsers`,
            {
                method: 'POST',
                body: JSON.stringify({
                    data: selectedUserData
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setMessage(result.message)
                setSelectedUserData()
            })
            .catch((error) => {
                setLoader(false)
                console.error('Error:', error);
            });
        setLoader(false)


    }

    const handleOpen = () => {
        if (selectedUserData?.sourceUser !== undefined && selectedUserData?.destinationUser !== undefined) {
            setIsMerge(!isMerge)
        }
        else {
            alert("Please select users to merge")
        }
    }

    const closeMerge = () => {
        setIsMerge(!isMerge)
    }

    const handleJobType = async (e) => {
        e.preventDefault()
        setLoader(true)
        setMessage(null)
        await fetch(
            `${OPEN_CATS_URL}/searchRouter/addJobType`,
            {
                method: 'POST',
                body: JSON.stringify({
                    jobTypeString: jobTypeString
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            }
        ).then((response) => response.json())
            .then((response) => {
                setLoader(false)
                getJobTypesData()
                setJobTypeString("")
                toast.success(response.message)

            }).catch((error) => {
                // Handle the error
                setMessage(JSON.parse(error.message).message)
            })
        setLoader(false)
    }

    const getJobTypesData = async () => {
        setJobTypeData([])
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/searchRouter/getJobTypes`)
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setJobTypeData(result.data)
            })
            .catch((error) => {

                console.log('Error:', error.response);
                setLoader(false)
            });
        setLoader(false)
    }

    const handleJobTypeDelete = async (data) => {
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/searchRouter/deleteJobType?id=${data}`)
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                getJobTypesData()
                toast.success(result.message)
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoader(false)
            });
        setLoader(false)
    }

    const handleJobTypeUpdate = async (data) => {
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/searchRouter/updateJobType?id=${data}&job_type=${jobTypeString}`)
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setEditData(false)
                setEditId(0)
                getJobTypesData()
                setJobTypeString("")
                toast.success(result.message)
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoader(false)
            });
        setLoader(false)

    }

    useEffect(() => {
        if (jobTypeData.length > 0) {
            $(document).ready(function () {
                window.$('#jobTypesTable').DataTable({
                    "order": [[0, "desc"]],
                    mark: true,
                    "bDestroy": true,
                })
            })
        }
    }, [jobTypeData])

    const customAlert = (data) => {
        confirmAlert({
            title: '',
            message: 'Are you sure want to remove job type?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        handleJobTypeDelete(data)
                    }
                },
                {
                    label: 'No',
                    onClick: () => console.log('Click No')
                }
            ]
        });

    }

    useEffect(() => {
        if (new URLSearchParams(window.location.search).get('type') === 'resume') {
            handleView('resume_access')
        }
        if (new URLSearchParams(window.location.search).get('type') === 'merge') {
            handleView('merge_users')
        }
        if (new URLSearchParams(window.location.search).get('type') === 'job_type_view') {
            handleView('job_type_view')
        }
    }, [new URLSearchParams(window.location.search).get('type')])

    return (
        props.showSettings && (
            <>
                <ToastContainer />
                <div className="ml-4">
                    {/* <div className="mb-2">
                        <div className="mt-2 mb-3">
                            <button type="button" className={viewResumeAccess ? "btn btn-outline-dark btn-sm mr-2 active" : "btn btn-outline-dark btn-sm mr-2"} onClick={() => handleView('resume_access')}>Resume Access</button>
                            <button type="button" className={mergeUsers ? "btn btn-outline-dark btn-sm mr-2 active" : "btn btn-outline-dark btn-sm mr-2"} onClick={() => handleView('merge_users')}>Merge Users</button>
                            <button type="button" className={jobTypeView ? "btn btn-outline-dark btn-sm mr-2 active" : "btn btn-outline-dark btn-sm mr-2"} onClick={() => handleView('')}>Search Settings</button>

                        </div>
                    </div> */}
                    {
                        viewResumeAccess &&

                        <div className="mt-2 mb-5" style={{ fontSize: '14px' }}>
                            <div className="mt-2 mb-1">
                                <LoadingOverlay
                                    active={loader}
                                    spinner
                                    text=''
                                >
                                    <ToolkitProvider
                                        keyField="id"
                                        data={
                                            data?.filter(y => {
                                                return y.user_name !== "cats@rootadmin" && y.email !== "admin@testdomain.com"
                                            })?.map(x => {
                                                return {
                                                    username: x.user_name,
                                                    email: x.email,
                                                    nats_access: x.short_description,
                                                    access: x.rcats_access_type,
                                                    updateAccess: <select className='form-control' onChange={(e) => updateAccess(e, x)}>
                                                        <option value="">Select One</option>
                                                        <option value="1">1(Own Resumes)</option>
                                                        <option value="2">2(All Resumes)</option>
                                                    </select>
                                                }
                                            })
                                        }
                                        columns={columns}
                                        search
                                    >
                                        {
                                            props => (
                                                <div>
                                                    <div style={{ textAlign: 'right' }}>
                                                        <SearchBar {...props.searchProps} />
                                                    </div>
                                                    <BootstrapTable
                                                        {...props.baseProps}
                                                        // keyField='id' columns={columns}
                                                        pagination={paginationFactory(options)}
                                                        rowStyle={rowStyle}
                                                        bordered={false}
                                                        striped
                                                        hover
                                                    />
                                                </div>
                                            )
                                        }
                                    </ToolkitProvider>
                                </LoadingOverlay>
                            </div>
                        </div>
                    }
                    {
                        mergeUsers &&

                        <div className="mt-2 mb-5" style={{ fontSize: '14px' }}>
                            <div className="mt-2 mb-1">
                                <LoadingOverlay
                                    active={loader}
                                    spinner
                                    text=''
                                >
                                    <div>
                                        <div className='row col-12'>
                                            <div className='col-6'>
                                                <label>Source User</label>
                                                <Select
                                                    key={selectedUserData}
                                                    // value={selectedCandidate}
                                                    onChange={(selectedOption) => handleSelect(selectedOption, 'sourceUser')}
                                                    options={usersData}
                                                />
                                            </div>
                                            <div className='col-6'>
                                                <label>Destination User</label>
                                                <Select
                                                    key={selectedUserData}
                                                    // value={selectedCandidate}
                                                    onChange={(selectedOption) => handleSelect(selectedOption, 'destinationUser')}
                                                    options={usersData}
                                                />
                                            </div>
                                        </div>
                                        <div className='mt-3' style={{ textAlign: 'center' }}>
                                            <button onClick={() => handleOpen()} className='btn btn-outline-dark btn-sm mr-2'>Merge User</button>
                                        </div>
                                        <div className='mt-3' style={{ textAlign: 'center' }}>
                                            <h3>{message}</h3>
                                        </div>

                                    </div>
                                </LoadingOverlay>
                            </div>
                        </div>
                    }
                    {
                        jobTypeView &&

                        <div className="mt-2 mb-5" style={{ fontSize: '14px' }}>
                            <div className="mt-2 mb-1">
                                <form onSubmit={(e) => handleJobType(e)}>
                                    <div className='col-4'>
                                        <label>Enter Job Type</label>
                                        <input className='form-control' value={jobTypeString} placeholder='Enter Job Type' onChange={(e) => setJobTypeString(e.target.value)} required />
                                    </div>
                                    <br />
                                    <div className='col-3'>
                                        <button className='btn btn-outline-dark btn-sm mr-2' type="submit">{loader ? "Loading" : "Save"}</button>
                                        <button className='btn btn-outline-danger btn-sm mr-2' type="button" onClick={() => setJobTypeString("")}>Cancel</button>
                                    </div>
                                    <div className="mt-2 ml-3">
                                        <label>{message}</label>
                                    </div>
                                </form>
                            </div>
                            <div className="mt-4 mb-1">
                                <div style={{ width: '90%', margin: 'auto', marginBottom: '10px', fontSize: '13px' }}>
                                    <div className="justify-content-center">
                                        {
                                            jobTypeData?.length > 0 ?
                                                <div>
                                                    <table className='table table-hover table-sm mt-2' id="jobTypesTable">
                                                        <thead>
                                                            <tr>
                                                                <th style={{ display: 'none' }}>id</th>
                                                                <th>Job Type</th>
                                                                <th>Created Time</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                jobTypeData.length > 0 ? jobTypeData.map((x) => {
                                                                    return <tr key={x.id}>
                                                                        <td style={{ display: 'none' }}>{x.id}</td>
                                                                        <td>
                                                                            {
                                                                                x.id === editId && editData ?
                                                                                    <textarea onChange={(e) => setJobTypeString(e.target.value)}>{x.job_type}</textarea>
                                                                                    :
                                                                                    x.job_type
                                                                            }
                                                                        </td>
                                                                        <td>{moment(x.created_at).format('lll')}</td>
                                                                        <td>
                                                                            {x.id === editId && editData ?
                                                                                <button onClick={() => {
                                                                                    handleJobTypeUpdate(x.id)
                                                                                }} className='btn btn-outline-info btn-sm mr-2' type="button">Save</button>

                                                                                :
                                                                                <button onClick={() => {
                                                                                    setEditData(true)
                                                                                    setEditId(x.id)
                                                                                }} className='btn btn-outline-info btn-sm mr-2' type="button">Update</button>

                                                                            }

                                                                            <button onClick={() => customAlert(x.id)} className='btn btn-outline-danger btn-sm mr-2' type="button">Delete</button>
                                                                        </td>
                                                                    </tr>
                                                                })
                                                                    :
                                                                    <tr>
                                                                        <td colSpan={3} style={{ textAlign: 'center' }}>No Data Found</td>
                                                                    </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                :
                                                <center>
                                                    {
                                                        loader ?
                                                            "Loading..."
                                                            :
                                                            "No Data Found"
                                                    }
                                                </center>
                                        }

                                    </div>

                                </div>

                            </div>
                        </div>
                    }
                    <Modal
                        isOpen={isMerge}
                        onRequestClose={closeMerge}
                        style={customStyles}
                        shouldCloseOnOverlayClick={false}
                    >
                        <center>
                            <p>Are you sure want to merge user?</p>
                        </center>
                        <center>
                            <button onClick={() => handleMerge()} className='btn btn-outline-success btn-sm mr-2'>Yes</button>
                            <button onClick={() => closeMerge()} className='btn btn-outline-danger btn-sm mr-2'>No</button>

                        </center>
                    </Modal>
                </div>
            </>
        )
    )
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '25%',
        height: '20%'
    },
};

