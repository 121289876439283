import React, { useEffect, useState } from 'react'
import { OPEN_CATS_URL } from '../../config';
import $ from 'jquery'
import moment from 'moment';
import Modal from 'react-modal';
import { getToken } from '../../storage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';

export default function Company(props) {
    const history = useHistory()
    const [companyData, setCompanyData] = useState([])
    const [loader, setLoader] = useState(false)
    const [companyAdd, setCompanyAdd] = useState(false)
    const [companyView, setCompanyView] = useState(false)
    const [data, setData] = useState({
        companyName: "",
        primaryPhone: "",
        secondaryPhone: "",
        address: "",
        city: "",
        state: "",
        postalCode: "",
        faxNumber: "",
        website: "",
        isHot: "0",
        country: "",
        notes: "",
        keyTechnologies: ""
    })
    const [editData, setEditData] = useState({
        view: false,
        keyValue: null,
        data: {}
    })

    useEffect(() => {
        if (companyView) {
            getAllCompanies()
        }
    }, [companyView])

    useEffect(() => {
        if (companyData.length > 0) {

            $(document).ready(function () {
                $('#companyTable tfoot th').each(function () {
                    var title = $(this).text();
                    $(this).html('<input type="text" placeholder="Search ' + title + '" />');
                });
                var table = window.$('#companyTable').DataTable({
                    "order": [[0, "desc"]],
                    mark: true,
                    bDestroy: true,
                })
                table.columns().every(function () {
                    var that = this;

                    $('input', this.footer()).addClass('input-class-footer').on('keyup change', function () {
                        if (that.search() !== this.value) {
                            that
                                .search(this.value)
                                .draw();
                        }
                    });

                });
                $('input.toggle-vis').on('change', function (e) {
                    e.preventDefault()
                    var columnIndex = parseInt($(this).attr('data-column'));
                    table.column(columnIndex).visible(!this.checked);
                });
            })
        }
    }, [companyData, new URLSearchParams(window.location.search).get('page')])

    const getAllCompanies = async () => {
        setLoader(true)
        setCompanyData([])
        await fetch(
            `${OPEN_CATS_URL}/companyRouter/getAllCompanies`)
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setCompanyData(result.data)

            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const handleView = (type) => {
        if (type === 'add') {
            setCompanyAdd(true)
            setCompanyView(false)
        }
        if (type === 'view') {
            setCompanyAdd(false)
            setCompanyView(true)
        }
    }

    const addNewCompany = async (e) => {
        e.preventDefault()
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/companyRouter/createNewCompany`,
            {
                method: 'POST',
                body: JSON.stringify({ addedBy: getToken('id'), data: data }),
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => response.json())
            .then((result) => {
                toast.info(result.message)
                handleView('view')
            })
            .catch((error) => {
                setLoader(false)
                handleView('view')
            });
        setLoader(false)
    }

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        if (new URLSearchParams(window.location.search).get('type') === 'add') {
            handleView('add')
        }
        if (new URLSearchParams(window.location.search).get('type') === 'view') {
            handleView('view')
        }
    }, [new URLSearchParams(window.location.search).get('type')])

    const createEdit = (data, keyValue) => {
        setEditData({
            view: true,
            keyValue: keyValue,
            data: data
        })
    }

    const handleUpdateCompany = async (e) => {
        e.preventDefault()
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/companyRouter/updateCompany`, {
            method: 'POST',
            body: JSON.stringify(editData),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                // setMessage(result.message)
                setEditData({
                    view: false,
                    keyValue: null,
                    data: {}
                })
                getAllCompanies()
            })
            .catch((error) => {
                setLoader(true)
                console.error('Error:', error);
            });

    }

    const handleEditChange = (e) => {
        setEditData({
            ...editData,
            data: {
                ...editData.data,
                [e.target.name]: e.target.value
            }
        })
    }

    return (
        props?.showCompany && (
            <div className="ml-4">
                <ToastContainer />
                {/* <div className="mb-5">
                    <div className="mt-2 mb-2">
                        <button type="button" className={companyAdd ? "btn btn-outline-dark active mr-2 btn-sm" : "btn btn-outline-dark mr-2 btn-sm"} onClick={() => handleView('add')}>Add</button>
                        <button type="button" className={companyView ? "btn btn-outline-dark btn-sm active mr-2" : "btn btn-outline-dark btn-sm mr-2"} onClick={() => handleView('view')}>View</button>
                    </div>
                </div> */}
                <div style={{ textAlign: 'center' }}>
                    {
                        loader &&
                        <label style={{ color: 'black', fontSize: '18px', fontWeight: '500' }}>Loading...</label>

                    }
                </div>
                <div>
                    <div style={{ width: '90%', margin: 'auto', marginBottom: '10px', fontSize: '13px' }}>
                        {
                            companyView && companyData?.length > 0 &&
                            <div className="row justify-content-center" style={{ overflow: 'auto' }}>
                                <div className="col-auto">
                                    <label>Hide/Unhide Columns</label>
                                    <div style={{ fontSize: '10px' }}>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="1" /> </label><label className='m-1'>Action</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="2" /> </label><label className='m-1'>Name</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="3" /> </label><label className='m-1'>Active Jobs</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="4" /> </label><label className='m-1'>Jobs</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="5" /> </label><label className='m-1'>Phone</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="6" /> </label><label className='m-1'>City</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="7" /> </label><label className='m-1'>State</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="8" /> </label><label className='m-1'>Country</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="9" /> </label><label className='m-1'>Zip</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="10" /></label><label className='m-1'> Contact</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="11" /></label><label className='m-1'> Owner</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="12" /></label><label className='m-1'> Created Date</label></label>
                                    </div>
                                    <br />
                                    <table id="companyTable" className='table table-hover table-sm'>
                                        <thead>
                                            <tr>
                                                <th style={{ display: 'none' }}>companyId</th>
                                                <th>Action</th>
                                                <th>Name</th>
                                                <th>Active Jobs</th>
                                                <th>Jobs</th>
                                                <th>Phone</th>
                                                <th>City</th>
                                                <th>State</th>
                                                <th>Country</th>
                                                <th>Zip</th>
                                                <th>Contact</th>
                                                <th>Owner</th>
                                                <th>Created Date</th>
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr>
                                                <th style={{ display: 'none' }}>companyId</th>
                                                <th>Action</th>
                                                <th>Name</th>
                                                <th>Active Jobs</th>
                                                <th>Jobs</th>
                                                <th>Phone</th>
                                                <th>City</th>
                                                <th>State</th>
                                                <th>Country</th>
                                                <th>Zip</th>
                                                <th>Contact</th>
                                                <th>Owner</th>
                                                <th>Created Date</th>
                                            </tr>
                                        </tfoot>
                                        <tbody>
                                            {
                                                companyData?.map((x, index) => {
                                                    return <tr key={x.name}>
                                                        <th style={{ display: 'none' }}>{x.companyId}</th>
                                                        <td>
                                                            {
                                                                // editData.view && index === editData.keyValue ?

                                                                //     <>
                                                                //         <label style={{ paddingLeft: '10px', cursor: 'pointer' }}
                                                                //             onClick={() => handleUpdate()}
                                                                //         >
                                                                //             Update
                                                                //         </label>
                                                                //         <label style={{ paddingLeft: '10px', cursor: 'pointer' }} onClick={() => {
                                                                //             setEditData({
                                                                //                 view: false,
                                                                //                 keyValue: null,
                                                                //                 data: {},
                                                                //                 primary_skill: null
                                                                //             })
                                                                //         }}>
                                                                //             Cancel
                                                                //         </label>
                                                                //     </>
                                                                //     :
                                                                <label style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => {
                                                                    createEdit(x, index)
                                                                }}>
                                                                    <i class="bi bi-pencil-square"></i>
                                                                </label>

                                                            }
                                                        </td>
                                                        <td>{x.name}</td>
                                                        <td>{x.activejobs}</td>
                                                        <td>{x.jobs}</td>
                                                        <td>{x.phone}</td>
                                                        <td>{x.city}</td>
                                                        <td>{x.state}</td>
                                                        <td>{x.extra_field_value0}</td>
                                                        <td>{x.zip}</td>
                                                        <td>{`${x.contactFirstName !== null ? x.contactFirstName : ""} ${x.contactLastName !== null ? x.contactLastName : ""}`}</td>
                                                        <td>{`${x.ownerFirstName} ${x.ownerLastName}`}</td>
                                                        <td>{moment(x.dateCreatedSort).format('lll')}</td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div>
                    <Modal
                        isOpen={companyAdd}
                        onRequestClose={() => {
                            history.push('/home?page=company&type=view')
                            handleView('view')
                        }}
                        style={customStyles}
                        shouldCloseOnOverlayClick={false}
                    >
                        <label>New Company</label>
                        <button className="btn btn-danger float-right mb-2" onClick={() => {
                            history.push('/home?page=company&type=view')
                            handleView('view')

                        }}>close</button>
                        <form className="form-group" onSubmit={(e) => addNewCompany(e)}>
                            <div className="form-group">
                                <label>Company Name*</label>
                                <input type="text" name="companyName" className="form-control mt-2 mb-2" placeholder="Company Name" onChange={(e) => handleChange(e)} required />
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label>Primary Phone*</label>
                                    <input type="text" name="primaryPhone" className="form-control mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="Primary Phone" required />
                                </div>
                                <div className="form-group col-6">
                                    <label>Secondary Phone</label>
                                    <input type="text" name="secondaryPhone" className="form-control mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="Secondary Phone" />
                                </div>
                            </div>

                            <div className="form-group">
                                <label>Address*</label>
                                <textarea name="address" className="form-control mt-2 mb-2" placeholder="Address" onChange={(e) => handleChange(e)} required />
                            </div>
                            <div className="row">
                                <div className="form-group col-4">
                                    <label>City*</label>
                                    <input type="text" name="city" className="form-control mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="City" required />
                                </div>
                                <div className="form-group col-4">
                                    <label>State*</label>
                                    <input type="text" name="state" className="form-control mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="State" required />
                                </div>
                                <div className="form-group col-4">
                                    <label>Country*</label>
                                    <input type="text" name="country" className="form-control mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="State" required />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label>Postal Code</label>
                                    <input type="text" name="postalCode" className="form-control mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="Postal Code" />
                                </div>
                                <div className="form-group col-6">
                                    <label>Fax Number</label>
                                    <input type="text" name="faxNumber" className="form-control mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="Fax Number" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label>Web Site</label>
                                    <textarea name="website" className="form-control mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="Web Site" />
                                </div>
                                <div className="form-group col-6">
                                    <label>Hot Company*</label>
                                    <select name="isHot" className="form-control mt-1 mb-2" onChange={(e) => handleChange(e)} required>
                                        <option value="">Select One</option>
                                        <option value={1}>Yes</option>
                                        <option value={0}>No</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Notes</label>
                                <textarea name="notes" className="form-control mt-2 mb-2" placeholder="Notes" onChange={(e) => handleChange(e)} />
                            </div>
                            <div className="form-group">
                                <label>Key Technologies</label>
                                <textarea name="keyTechnologies" className="form-control mt-2 mb-2" placeholder="Key Technologies" onChange={(e) => handleChange(e)} />
                            </div>
                            <div className="form-group">
                                <button className="btn btn-outline-dark btn-sm" type="submit"  >Add Company</button>
                            </div>
                        </form>
                    </Modal>
                </div>
                <div>
                    <Modal
                        isOpen={editData.view}
                        onRequestClose={() => {
                            history.push('/home?page=company&type=view')
                            setEditData({
                                ...editData,
                                view: false
                            })
                        }}
                        style={customStyles}
                        shouldCloseOnOverlayClick={false}
                    >
                        <label>Update Company</label>
                        <button className="btn btn-danger float-right mb-2" onClick={() => {
                            history.push('/home?page=company&type=view')
                            setEditData({
                                ...editData,
                                view: false
                            })

                        }}>close</button>
                        <form className="form-group" onSubmit={(e) => handleUpdateCompany(e)}>
                            <div className="form-group">
                                <label>Company Name*</label>
                                <input type="text" value={editData.data.name} name="name" className="form-control mt-2 mb-2" placeholder="Company Name" onChange={(e) => handleEditChange(e)} required />
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label>Primary Phone*</label>
                                    <input type="text" value={editData.data.phone} name="phone" className="form-control mt-1 mb-2" onChange={(e) => handleEditChange(e)} placeholder="Primary Phone" required />
                                </div>
                                <div className="form-group col-6">
                                    <label>Secondary Phone</label>
                                    <input type="text" value={editData.data.phone2} name="phone2" className="form-control mt-1 mb-2" onChange={(e) => handleEditChange(e)} placeholder="Secondary Phone" />
                                </div>
                            </div>

                            <div className="form-group">
                                <label>Address*</label>
                                <textarea name="address" value={editData.data.address} className="form-control mt-2 mb-2" placeholder="Address" onChange={(e) => handleEditChange(e)} required />
                            </div>
                            <div className="row">
                                <div className="form-group col-4">
                                    <label>Country*</label>
                                    <input type="text" value={editData.data.extra_field_value0} name="extra_field_value0" className="form-control mt-1 mb-2" onChange={(e) => handleEditChange(e)} placeholder="State" required />
                                </div>
                                <div className="form-group col-4">
                                    <label>State*</label>
                                    <input type="text" value={editData.data.state} name="state" className="form-control mt-1 mb-2" onChange={(e) => handleEditChange(e)} placeholder="State" required />
                                </div>
                                <div className="form-group col-4">
                                    <label>City*</label>
                                    <input type="text" value={editData.data.city} name="city" className="form-control mt-1 mb-2" onChange={(e) => handleEditChange(e)} placeholder="City" required />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label>Postal Code</label>
                                    <input type="text" value={editData.data.zip} name="zip" className="form-control mt-1 mb-2" onChange={(e) => handleEditChange(e)} placeholder="Postal Code" />
                                </div>
                                <div className="form-group col-6">
                                    <label>Fax Number</label>
                                    <input type="text" value={editData.data.fax_number} name="fax_number" className="form-control mt-1 mb-2" onChange={(e) => handleEditChange(e)} placeholder="Fax Number" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label>Web Site</label>
                                    <textarea name="webSite" value={editData.data.webSite} className="form-control mt-1 mb-2" onChange={(e) => handleEditChange(e)} placeholder="Web Site" />
                                </div>
                                <div className="form-group col-6">
                                    <label>Hot Company</label>
                                    <select name="isHot" className="form-control mt-1 mb-2" onChange={(e) => handleEditChange(e)} required>
                                        <option value={editData.data.isHot}>{editData.data.isHot === 1 ? "Yes" : "No"}</option>
                                        <option value={1}>Yes</option>
                                        <option value={0}>No</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Notes</label>
                                <textarea name="notes" value={editData.data.notes} className="form-control mt-2 mb-2" placeholder="Notes" onChange={(e) => handleEditChange(e)} />
                            </div>
                            <div className="form-group">
                                <label>Key Technologies</label>
                                <textarea name="keyTechnologies" value={editData.data.keyTechnologies} className="form-control mt-2 mb-2" placeholder="Key Technologies" onChange={(e) => handleEditChange(e)} />
                            </div>
                            <div className="form-group">
                                <button className="btn btn-outline-dark btn-sm" type="submit">{loader ? "Loading.." : "Update Company"}</button>
                            </div>
                        </form>
                    </Modal>
                </div>
            </div >
        )
    )
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '70%',
        height: '70%'
    },
};