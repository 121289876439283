import React, { useEffect, useState } from 'react'
import { OPEN_CATS_URL } from '../../config'
import { getToken } from '../../storage'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import $ from 'jquery'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Select from 'react-select';

export default function Vendor(props) {
    const [data, setData] = useState()
    const [vendorData, setVendorData] = useState([])
    const [loader, setLoader] = useState(false)
    const [vendorListView, setVendorListView] = useState(false)
    const [vendorAddView, setVendorAddView] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [jobTypeData, setJobTypeData] = useState([])
    const [selectedJobType, setSelectedJobType] = useState()
    const [existingJobType, setExistingJobType] = useState()

    const handleView = (type) => {
        if (type === 'add') {
            setVendorAddView(true)
            setVendorListView(false)
            getJobTypesData()
        }
        if (type === 'view') {
            setVendorListView(true)
            setVendorAddView(false)
            getJobTypesData()
            getVendors()

        }
    }

    const handleChange = (e) => {
        setData({
            ...data, [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (selectedJobType !== undefined) {
            setLoader(true)
            await fetch(
                `${OPEN_CATS_URL}/vendorRouter/addVendor`,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        addedBy: getToken('id'),
                        data: data,
                        isEdit: isEdit,
                        job_type: selectedJobType,
                        job_type_name: selectedJobType.map(x => {
                            return x.label
                        }).toString(),
                        existing_job_type: existingJobType
                    }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            )
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === 201) {
                        setLoader(false)
                        toast.success(result.message)
                        getVendors()
                        handleView('view')
                    } if (result.status === 400) {
                        setLoader(false)
                        toast.warning(result.message)
                    }

                })
                .catch((error) => {
                    setLoader(false)
                    console.error('Error:', error);
                });
        } else {
            toast.warning("Please Select Job Type")
        }

        setLoader(false)
    }

    const getJobTypesData = async () => {
        setJobTypeData([])
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/searchRouter/getJobTypes`)
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setJobTypeData(result.data)
            })
            .catch((error) => {

                console.log('Error:', error.response);
                setLoader(false)
            });
        setLoader(false)
    }

    const getVendors = async () => {
        setVendorData([])
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/vendorRouter/getVendors`)
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setVendorData(result.data)
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoader(false)
            });
        setLoader(false)
    }

    useEffect(() => {
        if (vendorData.length > 0) {
            $(document).ready(function () {
                $('#vendorTable tfoot th').each(function () {
                    var title = $(this).text();
                    $(this).html('<input type="text" placeholder="Search ' + title + '" />');
                });
                var table = window.$('#vendorTable').DataTable({
                    "order": [[0, "desc"]],
                    mark: true,
                    "bDestroy": true,
                })
                table.columns().every(function () {
                    var that = this;
                    $('input', this.footer()).addClass('input-class-footer').on('keyup change', function () {
                        if (that.search() !== this.value) {
                            that
                                .search(this.value)
                                .draw();
                        }
                    })
                });
                $('input.toggle-vis').on('change', function (e) {
                    e.preventDefault()
                    var columnIndex = parseInt($(this).attr('data-column'));
                    table.column(columnIndex).visible(!this.checked);
                });
            })
        }
    }, [vendorData])

    const handleUpdateData = (data, options) => {
        setData(data)
        setIsEdit(true)
        setSelectedJobType(options)
        setExistingJobType(options)
        handleView('add')
    }

    const handleRemoveData = async (data) => {
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/vendorRouter/removeVendor`,
            {
                method: 'POST',
                body: JSON.stringify({
                    data: data,
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status === 200) {
                    setLoader(false)
                    toast.success(result.message)
                    getVendors()
                    handleView('view')
                } if (result.status === 400) {
                    setLoader(false)
                    toast.warning(result.message)
                    getVendors()
                    handleView('view')
                }

            })
            .catch((error) => {
                setLoader(false)
                console.error('Error:', error);
            });
        setLoader(false)
    }

    const customAlert = (data) => {
        confirmAlert({
            title: '',
            message: 'Are you sure want to remove vendor ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        handleRemoveData(data)
                    }
                },
                {
                    label: 'No',
                    onClick: () => console.log('Click No')
                }
            ]
        });

    }


    useEffect(() => {
        if (new URLSearchParams(window.location.search).get('type') === 'add' && new URLSearchParams(window.location.search).get('page') === 'vendor') {
            handleView('add')
        }
        if (new URLSearchParams(window.location.search).get('type') === 'view' && new URLSearchParams(window.location.search).get('page') === 'vendor') {
            handleView('view')
        }
    }, [new URLSearchParams(window.location.search).get('type')])

    return (
        props?.showVendor && (
            <div>
                <ToastContainer />
                <div className="ml-4">
                    {
                        vendorListView &&
                        <div>
                            <div style={{ width: '90%', margin: 'auto', marginBottom: '10px', fontSize: '13px' }}>
                                <div className="justify-content-center">
                                    {
                                        vendorData?.length > 0 ?
                                            <div className="row justify-content-center" style={{ overflow: 'auto' }}>
                                                <div className="col-auto">
                                                    <label>Hide/Unhide Columns</label>
                                                    <div style={{ fontSize: '10px' }}>
                                                        <label><label><input type="checkbox" className="toggle-vis" data-column="1" /> </label><label className='m-1'>Action</label></label>
                                                        <label><label><input type="checkbox" className="toggle-vis" data-column="2" /> </label><label className='m-1'>First Name</label></label>
                                                        <label><label><input type="checkbox" className="toggle-vis" data-column="3" /> </label><label className='m-1'>Last Name</label></label>
                                                        <label><label><input type="checkbox" className="toggle-vis" data-column="4" /> </label><label className='m-1'>Email</label></label>
                                                        <label><label><input type="checkbox" className="toggle-vis" data-column="5" /> </label><label className='m-1'>Contact</label></label>
                                                        <label><label><input type="checkbox" className="toggle-vis" data-column="6" /> </label><label className='m-1'>Job Type</label></label>
                                                        <label><label><input type="checkbox" className="toggle-vis" data-column="7" /> </label><label className='m-1'>Created Date</label></label>
                                                        <label><label><input type="checkbox" className="toggle-vis" data-column="8" /> </label><label className='m-1'>Created By</label></label>
                                                        <label><label><input type="checkbox" className="toggle-vis" data-column="9" /> </label><label className='m-1'>Action</label></label>

                                                    </div>
                                                    <br />
                                                    <table id="vendorTable" className='table table-hover table-sm'>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ display: 'none' }}>vendorId</th>
                                                                <th>Company Name</th>
                                                                <th>First Name</th>
                                                                <th>Last Name</th>
                                                                <th>Email</th>
                                                                <th>Contact</th>
                                                                <th>Job Type</th>
                                                                <th>Created Date</th>
                                                                <th>Created By</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tfoot>
                                                            <tr>
                                                                <th style={{ display: 'none' }}>vendorId</th>
                                                                <th>Company Name</th>
                                                                <th>First Name</th>
                                                                <th>Last Name</th>
                                                                <th>Email</th>
                                                                <th>Contact</th>
                                                                <th>Job Type</th>
                                                                <th>Created Date</th>
                                                                <th>Created By</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </tfoot>
                                                        <tbody>
                                                            {
                                                                vendorData?.map(x => {
                                                                    return <tr key={x}>
                                                                        <td style={{ display: 'none' }}>{x.id}</td>
                                                                        <td>{x.company_name}</td>
                                                                        <td>{x.first_name}</td>
                                                                        <td>{x.last_name}</td>
                                                                        <td>{x.email}</td>
                                                                        <td>{x.mobile}</td>
                                                                        <td>{x.job_type}</td>
                                                                        <td>{moment(x.created_date).format('lll')}</td>
                                                                        <td>{x.user_first_name} {x.user_last_name}</td>
                                                                        <td>
                                                                            <button type="button" className='btn btn-outline-info btn-sm mr-2' onClick={() => {
                                                                                var secondArray = x.job_type?.split(',').map(x => {
                                                                                    return { label: x, value: x }
                                                                                })


                                                                                var firstArray = jobTypeData.map(x => {
                                                                                    return {
                                                                                        value: x.id, label: x.job_type
                                                                                    }
                                                                                })

                                                                                var intersection = firstArray.filter(firstArrayItem =>
                                                                                    secondArray?.some(
                                                                                        secondArrayItem => firstArrayItem.label === secondArrayItem.label
                                                                                    )
                                                                                );
                                                                                handleUpdateData(x, intersection)
                                                                            }}>Update</button>
                                                                            <button type="button" className='btn btn-outline-danger btn-sm mr-2' onClick={() => {
                                                                                customAlert(x.id)
                                                                            }}>Remove</button>
                                                                        </td>
                                                                    </tr>
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            :
                                            <center>
                                                {
                                                    loader ?
                                                        "Loading..."
                                                        :
                                                        "No Data Found"
                                                }
                                            </center>
                                    }

                                </div>

                            </div>
                        </div>
                    }
                    {
                        vendorAddView &&
                        <div className='container' style={{ fontSize: '13px' }}>
                            <label><b>Add Vendor Details</b></label>
                            <form className='col-6' onSubmit={(e) => handleSubmit(e)}>
                                <div className="form-group">
                                    <label>Company name</label>
                                    <input value={data?.company_name} type="text" name="company_name" className="form-control form-control-sm" placeholder="Enter Company name" onChange={(e) => handleChange(e)} required />
                                </div>
                                <div className="row">
                                    <div className='col-6'>
                                        <div className="form-group">
                                            <label>First name</label>
                                            <input value={data?.first_name} type="text" name="first_name" className="form-control form-control-sm" placeholder="Enter First name" onChange={(e) => handleChange(e)} required />
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className="form-group">
                                            <label>Last name</label>
                                            <input value={data?.last_name} type="text" name="last_name" className="form-control form-control-sm" placeholder="Enter Last name" onChange={(e) => handleChange(e)} required />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input value={data?.email} type="email" name="email" className="form-control form-control-sm" placeholder="Enter email" onChange={(e) => handleChange(e)} required />
                                </div>
                                <div className="form-group">
                                    <label>Mobile</label>
                                    <input value={data?.mobile} type="text" name="mobile" className="form-control form-control-sm" placeholder="Enter Mobile" onChange={(e) => handleChange(e)} required />
                                </div>
                                <div className="form-group">
                                    <label>Job Types</label>
                                    <Select
                                        isMulti
                                        value={selectedJobType}
                                        onChange={(selectedOption) => setSelectedJobType(selectedOption)}
                                        options={jobTypeData.map(x => {
                                            return {
                                                value: x.id, label: x.job_type
                                            }
                                        })}
                                    />
                                </div>
                                <div>{
                                    isEdit ?
                                        <>
                                            <button type="submit" disabled={loader} class="btn btn-outline-dark btn-sm mr-2">{loader ? "Loading..." : "Update"}</button>
                                            <button type="button" onClick={() => window.location.href = "/home?page=vendor&type=view"} disabled={loader} class="btn btn-outline-dark  btn-sm mr-2">Cancel</button></>
                                        :
                                        <>
                                            <button type="submit" disabled={loader} class="btn btn-outline-dark btn-sm mr-2">{loader ? "Loading..." : "Save"}</button>
                                            <button type="reset" disabled={loader} class="btn btn-outline-dark  btn-sm mr-2">Cancel</button>
                                        </>
                                }
                                </div>
                            </form>
                        </div>
                    }

                </div >
            </div>
        )
    )
}