import React, { useEffect, useState } from 'react'
import ShowCandidates from '../Candidates/showCandidates'
import Navbar from '../Common/Navbar'
import CreateEvent from '../Events/createEvent'
import ExistEvent from '../Events/existEvents'
import JobOrder from '../JobOrder/jobOrder'
import ViewJobOrder from '../JobOrder/viewJobOrder'
import ShowReports from '../Reports'
import Settings from '../Settings'
import { useHistory } from 'react-router-dom'
import Company from '../Company'
import { BUILD_DATE, VERSION } from '../../config'
import Contact from '../Contact'
import List from '../List'
import CareerReports from '../CareerReports'
import ClientSubmit from '../ClientSubmit'
import Vendor from '../Vendor'


export default function Home() {

    const history = useHistory()

    const [logEvent, setLogEvent] = useState(false)
    const [existEvents, setExistEvents] = useState(false)
    const [jobOrder, setJobOrder] = useState(false)
    const [message, setMessage] = useState()
    const [showJobOrder, setShowJobOrder] = useState(false)
    const [showCandidates, setShowCandidates] = useState(false)
    const [showReports, setShowReports] = useState(false)
    const [showSettings, setShowSettings] = useState(false)
    const [access, setAccess] = useState(0)
    const [showCompany, setShowCompany] = useState(false)
    const [showContact, setShowContact] = useState(false)
    const [showList, setShowList] = useState(false)
    const [showCareers, setShowCareers] = useState(false)
    const [showClientSubmit, setShowClientSubmit] = useState(false)
    const [showVendor, setShowVendor] = useState(false)


    useEffect(() => {
        setTimeout(() => {
            setMessage("")
        }, 5000)
    }, [message])

    const handleNewEvent = () => {
        setLogEvent(true)
        setExistEvents(false)
        setJobOrder(false)
        setShowJobOrder(false)
        setShowCandidates(false)
        setShowReports(false)
        setShowSettings(false)
        setShowCompany(false)
        setShowContact(false)
        setShowList(false)
        setShowCareers(false)
        setShowClientSubmit(false)
        setShowVendor(false)
    }

    const handleExistEvent = () => {
        setExistEvents(true)
        setLogEvent(false)
        setJobOrder(false)
        setShowJobOrder(false)
        setShowCandidates(false)
        setShowReports(false)
        setShowSettings(false)
        setShowCompany(false)
        setShowContact(false)
        setShowList(false)
        setShowCareers(false)
        setShowClientSubmit(false)
        setShowVendor(false)
    }

    const handleJobOrder = () => {
        setJobOrder(true)
        setLogEvent(false)
        setExistEvents(false)
        setShowJobOrder(false)
        setShowCandidates(false)
        setShowReports(false)
        setShowSettings(false)
        setShowCompany(false)
        setShowContact(false)
        setShowList(false)
        setShowCareers(false)
        setShowClientSubmit(false)
        setShowVendor(false)
    }

    const handleShowJobOrder = () => {
        setShowJobOrder(true)
        setJobOrder(false)
        setLogEvent(false)
        setExistEvents(false)
        setShowCandidates(false)
        setShowReports(false)
        setShowSettings(false)
        setShowCompany(false)
        setShowContact(false)
        setShowList(false)
        setShowCareers(false)
        setShowClientSubmit(false)
        setShowVendor(false)
    }

    const handleShowCandidates = () => {
        setShowCandidates(true)
        setShowJobOrder(false)
        setJobOrder(false)
        setLogEvent(false)
        setExistEvents(false)
        setShowReports(false)
        setShowSettings(false)
        setShowCompany(false)
        setShowContact(false)
        setShowList(false)
        setShowCareers(false)
        setShowClientSubmit(false)
        setShowVendor(false)
    }

    const handleReports = () => {
        setShowCandidates(false)
        setShowJobOrder(false)
        setJobOrder(false)
        setLogEvent(false)
        setExistEvents(false)
        setShowReports(true)
        setShowSettings(false)
        setShowCompany(false)
        setShowContact(false)
        setShowList(false)
        setShowCareers(false)
        setShowClientSubmit(false)
        setShowVendor(false)
    }

    const handleSettings = () => {
        setShowCandidates(false)
        setShowJobOrder(false)
        setJobOrder(false)
        setLogEvent(false)
        setExistEvents(false)
        setShowReports(false)
        setShowSettings(true)
        setShowCompany(false)
        setShowContact(false)
        setShowList(false)
        setShowCareers(false)
        setShowClientSubmit(false)
        setShowVendor(false)
    }

    const handleCompany = () => {
        setShowCandidates(false)
        setShowJobOrder(false)
        setJobOrder(false)
        setLogEvent(false)
        setExistEvents(false)
        setShowReports(false)
        setShowSettings(false)
        setShowCompany(true)
        setShowContact(false)
        setShowList(false)
        setShowCareers(false)
        setShowClientSubmit(false)
        setShowVendor(false)

    }

    const handleContact = () => {
        setShowCandidates(false)
        setShowJobOrder(false)
        setJobOrder(false)
        setLogEvent(false)
        setExistEvents(false)
        setShowReports(false)
        setShowSettings(false)
        setShowCompany(false)
        setShowContact(true)
        setShowList(false)
        setShowCareers(false)
        setShowClientSubmit(false)
        setShowVendor(false)

    }

    const handleList = () => {
        setShowCandidates(false)
        setShowJobOrder(false)
        setJobOrder(false)
        setLogEvent(false)
        setExistEvents(false)
        setShowReports(false)
        setShowSettings(false)
        setShowCompany(false)
        setShowContact(false)
        setShowList(true)
        setShowCareers(false)
        setShowClientSubmit(false)
        setShowVendor(false)

    }

    const handleCareers = () => {
        setShowCandidates(false)
        setShowJobOrder(false)
        setJobOrder(false)
        setLogEvent(false)
        setExistEvents(false)
        setShowReports(false)
        setShowSettings(false)
        setShowCompany(false)
        setShowContact(false)
        setShowList(false)
        setShowCareers(true)
        setShowClientSubmit(false)
        setShowVendor(false)

    }

    const handleClientSubmit = () => {
        setShowCandidates(false)
        setShowJobOrder(false)
        setJobOrder(false)
        setLogEvent(false)
        setExistEvents(false)
        setShowReports(false)
        setShowSettings(false)
        setShowCompany(false)
        setShowContact(false)
        setShowList(false)
        setShowCareers(false)
        setShowClientSubmit(true)
        setShowVendor(false)

    }

    const handleVendor = () => {
        setShowCandidates(false)
        setShowJobOrder(false)
        setJobOrder(false)
        setLogEvent(false)
        setExistEvents(false)
        setShowReports(false)
        setShowSettings(false)
        setShowCompany(false)
        setShowContact(false)
        setShowList(false)
        setShowCareers(false)
        setShowClientSubmit(false)
        setShowVendor(true)
    }

    useEffect(() => {
        if (new URLSearchParams(window.location.search).get('page') === 'newActivity') {
            handleNewEvent()
        }
        if (new URLSearchParams(window.location.search).get('page') === 'existingActivity') {
            handleExistEvent()
        }
        if (new URLSearchParams(window.location.search).get('page') === 'candidates') {
            handleShowCandidates()
        }
        if (new URLSearchParams(window.location.search).get('page') === 'jobOrders') {
            handleShowJobOrder()
        }
        if (new URLSearchParams(window.location.search).get('page') === 'jobOrderPipeline') {
            handleJobOrder()
        }
        if (new URLSearchParams(window.location.search).get('page') === 'reports') {
            handleReports()
        }
        if (new URLSearchParams(window.location.search).get('page') === 'settings') {
            handleSettings()
        }
        if (new URLSearchParams(window.location.search).get('page') === 'company') {
            handleCompany()
        }
        if (new URLSearchParams(window.location.search).get('page') === 'contact') {
            handleContact()
        }
        if (new URLSearchParams(window.location.search).get('page') === 'list') {
            handleList()
        }
        if (new URLSearchParams(window.location.search).get('page') === 'rcats-careers') {
            handleCareers()
        }
        if (new URLSearchParams(window.location.search).get('page') === 'client-submit') {
            handleClientSubmit()
        }
        if (new URLSearchParams(window.location.search).get('page') === 'vendor') {
            handleVendor()
        }
    }, [new URLSearchParams(window.location.search).get('page')])


    return (
        <div id="main-wrapper">
            <Navbar getAccess={(data) => setAccess(data)} />
            <div className="ml-4" style={{ marginTop: '5%' }}>
                <div className='col-12 row'>
                    <div className="dropdown ml-2">
                        <button className={(logEvent || existEvents) || new URLSearchParams(window.location.search).get('page') === 'newActivity' ? "btn btn-outline-dark active btn-sm dropdown-toggle" : "btn btn-outline-dark btn-sm dropdown-toggle"} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Activity
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <label className="dropdown-item" onClick={() => {
                                history.push('/home?page=newActivity')
                                handleNewEvent()
                            }}>New Activity</label>
                            <label className="dropdown-item" onClick={() => {
                                history.push('/home?page=existingActivity')
                                handleExistEvent()
                            }}>Existing Activity</label>
                        </div>
                    </div>
                    <div className="dropdown ml-2">
                        <button className={showCandidates || new URLSearchParams(window.location.search).get('page') === 'candidates' ? "btn btn-outline-dark active btn-sm dropdown-toggle" : "btn btn-outline-dark btn-sm dropdown-toggle"} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Candidates
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <label className="dropdown-item" onClick={() => {
                                history.push('/home?page=candidates&type=add')
                                handleShowCandidates()
                            }}>Add</label>
                            <label className="dropdown-item" onClick={() => {
                                history.push('/home?page=candidates&type=view')
                                handleShowCandidates()
                            }}>View</label>
                            <label className="dropdown-item" onClick={() => {
                                history.push('/home?page=candidates&type=search')
                                handleShowCandidates()
                            }}>Search</label>
                        </div>
                    </div>
                    <div className="dropdown ml-2">
                        <button className={showJobOrder || new URLSearchParams(window.location.search).get('page') === 'jobOrders' ? "btn btn-outline-dark active btn-sm dropdown-toggle" : "btn btn-outline-dark btn-sm dropdown-toggle"} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Joborders
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <label className="dropdown-item" onClick={() => {
                                history.push('/home?page=jobOrders&type=add')
                                handleShowJobOrder()
                            }}>Add</label>
                            <label className="dropdown-item" onClick={() => {
                                history.push('/home?page=jobOrders&type=view')
                                handleShowJobOrder()
                            }}>View</label>
                        </div>
                    </div>
                    <div className="dropdown ml-2">
                        <button className={jobOrder || new URLSearchParams(window.location.search).get('page') === 'jobOrderPipeline' ? "btn btn-outline-dark active btn-sm" : "btn btn-outline-dark btn-sm "} type="button" onClick={() => {
                            // handleJobOrder()
                            history.push('/home?page=jobOrderPipeline')
                        }}>
                            Pipeline
                        </button>
                    </div>
                    <div className="dropdown ml-2">
                        <button className={showCompany || new URLSearchParams(window.location.search).get('page') === 'company' ? "btn btn-outline-dark active btn-sm dropdown-toggle" : "btn btn-outline-dark btn-sm dropdown-toggle"} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Company
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <label className="dropdown-item" onClick={() => {
                                history.push('/home?page=company&type=add')
                                handleCompany()
                            }}>Add</label>
                            <label className="dropdown-item" onClick={() => {
                                history.push('/home?page=company&type=view')
                                handleCompany()
                            }}>View</label>
                        </div>
                    </div>
                    <div className="dropdown ml-2">
                        <button className={showContact || new URLSearchParams(window.location.search).get('page') === 'contact' ? "btn btn-outline-dark active btn-sm dropdown-toggle" : "btn btn-outline-dark btn-sm dropdown-toggle"} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Contacts
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <label className="dropdown-item" onClick={() => {
                                history.push('/home?page=contact&type=add')
                                handleContact()
                            }}>Add</label>
                            <label className="dropdown-item" onClick={() => {
                                history.push('/home?page=contact&type=view')
                                handleContact()
                            }}>View</label>
                            <label className="dropdown-item" onClick={() => {
                                history.push('/home?page=contact&type=search')
                                handleContact()
                            }}>Search</label>
                        </div>
                    </div>
                    <div className="dropdown ml-2">
                        <button className={showList || new URLSearchParams(window.location.search).get('page') === 'list' ? "btn btn-outline-dark active btn-sm dropdown-toggle" : "btn btn-outline-dark btn-sm dropdown-toggle"} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            List
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <label className="dropdown-item" onClick={() => {
                                history.push('/home?page=list&type=view')
                                handleList()
                            }}>View</label>
                        </div>
                    </div>
                    <div className="dropdown ml-2">
                        <button className={showReports || new URLSearchParams(window.location.search).get('page') === 'reports' ? "btn btn-outline-dark active btn-sm dropdown-toggle" : "btn btn-outline-dark btn-sm dropdown-toggle"} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Reports
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            {/* {
                                (access?.access_level === 300 || access?.access_level === 400 || access?.access_level === 500) &&
                                <label className="dropdown-item" onClick={() => {
                                    history.push('/home?page=reports&type=accountReports')
                                    handleReports()
                                }}>Accounts</label>
                            } */}
                            {
                                // (access?.access_level === 300 || access?.access_level === 400 || access?.access_level === 500) &&
                                <label className="dropdown-item" onClick={() => {
                                    history.push('/home?page=reports&type=reports')
                                    handleReports()
                                }}>Recruiter</label>
                            }

                            <label className="dropdown-item" onClick={() => {
                                history.push('/home?page=reports&type=candidate')
                                handleReports()
                            }}>Candidate</label>
                            <label className="dropdown-item" onClick={() => {
                                history.push('/home?page=reports&type=joborder')
                                handleReports()
                            }}>Joborder</label>
                            <label className="dropdown-item" onClick={() => {
                                history.push('/home?page=reports&type=pipeline')
                                handleReports()
                            }}>Pipeline</label>
                            <label className="dropdown-item" onClick={() => {
                                history.push('/home?page=reports&type=activity')
                                handleReports()
                            }}>Activity</label>
                        </div>
                    </div>
                    <div className="dropdown ml-2">
                        <button className={showCareers || new URLSearchParams(window.location.search).get('page') === 'rcats-careers' ? "btn btn-outline-dark active btn-sm dropdown-toggle" : "btn btn-outline-dark btn-sm dropdown-toggle"} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Careers
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <label className="dropdown-item" onClick={() => {
                                history.push('/home?page=careers&type=view')
                                handleCareers()
                            }}>Reports</label>
                            <label className="dropdown-item" onClick={() => {
                                window.open('/careers')
                            }}>Visit Page</label>
                        </div>
                    </div>
                    <div className="dropdown ml-2">
                        <button className={showClientSubmit || new URLSearchParams(window.location.search).get('page') === 'client-submit' ? "btn btn-outline-dark active btn-sm dropdown-toggle" : "btn btn-outline-dark btn-sm dropdown-toggle"} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Client
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <label className="dropdown-item" onClick={() => {
                                window.location.href = "/home?page=client-submit&type=submit"
                                handleClientSubmit()
                            }}>Submit</label>
                            <label className="dropdown-item" onClick={() => {
                                window.location.href = "/home?page=client-submit&type=view"
                                handleClientSubmit()
                            }}>View</label>

                        </div>
                    </div>
                    <div className="dropdown ml-2">
                        <button className={showVendor || new URLSearchParams(window.location.search).get('page') === 'vendor' ? "btn btn-outline-dark active btn-sm dropdown-toggle" : "btn btn-outline-dark btn-sm dropdown-toggle"} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Vendor
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <label className="dropdown-item" onClick={() => {
                                window.location.href = "/home?page=vendor&type=add"
                                handleVendor()
                            }}>Add</label>
                            <label className="dropdown-item" onClick={() => {
                                window.location.href = "/home?page=vendor&type=view"
                                handleVendor()
                            }}>View</label>
                        </div>
                    </div>
                    {
                        access.access_level === 500 &&
                        <div className="dropdown ml-2">
                            <button className={showSettings || new URLSearchParams(window.location.search).get('page') === 'settings' ? "btn btn-outline-dark active btn-sm dropdown-toggle" : "btn btn-outline-dark btn-sm dropdown-toggle"} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Settings
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <label className="dropdown-item" onClick={() => {
                                    history.push('/home?page=settings&type=job_type_view')
                                    handleSettings()
                                }}>Job Types</label>
                                <label className="dropdown-item" onClick={() => {
                                    history.push('/home?page=settings&type=resume')
                                    handleSettings()
                                }}>Resume Access</label>
                                <label className="dropdown-item" onClick={() => {
                                    history.push('/home?page=settings&type=merge')
                                    handleSettings()
                                }}>Merge Users</label>
                            </div>
                        </div>
                    }


                </div>
                <div className="mt-2">
                    {!logEvent && message}
                </div>
            </div>
            <div style={{ minHeight: '65vh' }}>
                <CreateEvent access={access} logEvent={logEvent || new URLSearchParams(window.location.search).get('page') === 'newActivity'} getMessage={(data) => setMessage(data)} setEvent={() => setLogEvent(!logEvent)} />
                <ExistEvent existEvent={existEvents || new URLSearchParams(window.location.search).get('page') === 'existingActivity'} />
                <JobOrder jobOrder={jobOrder || new URLSearchParams(window.location.search).get('page') === 'jobOrderPipeline'} getMessage={(data) => setMessage(data)} setJobOrder={() => setJobOrder(false)} access={access} />
                <ViewJobOrder showJobOrder={showJobOrder || new URLSearchParams(window.location.search).get('page') === 'jobOrders'} getMessage={(data) => setMessage(data)} setShowJobOrder={() => setShowJobOrder(false)} access={access} />
                <ShowCandidates showCandidates={showCandidates || new URLSearchParams(window.location.search).get('page') === 'candidates'} access={access} getMessage={(data) => setMessage(data)} setShowCandidates={() => setShowCandidates(false)} />
                <ShowReports showReports={showReports || new URLSearchParams(window.location.search).get('page') === 'reports'} access={access} />
                <Settings showSettings={showSettings || new URLSearchParams(window.location.search).get('page') === 'settings'} access={access} />
                <Company showCompany={showCompany || new URLSearchParams(window.location.search).get('page') === 'company'} getMessage={(data) => setMessage(data)} setShowCompany={() => setShowCompany(false)} access={access} />
                <Contact showContact={showContact || new URLSearchParams(window.location.search).get('page') === 'contact'} getMessage={(data) => setMessage(data)} setShowContact={() => setShowContact(false)} access={access} />
                <List showList={showList || new URLSearchParams(window.location.search).get('page') === 'list'} getMessage={(data) => setMessage(data)} setShowList={() => setShowList(false)} access={access} />
                <CareerReports showCareers={showCareers || new URLSearchParams(window.location.search).get('page') === 'rcats-careers'} getMessage={(data) => setMessage(data)} setShowCareers={() => setShowCareers(false)} access={access} />
                <ClientSubmit showClientSubmit={showClientSubmit || new URLSearchParams(window.location.search).get('page') === 'client-submit'} getMessage={(data) => setMessage(data)} setShowClientSubmit={() => setShowClientSubmit(false)} access={access} />
                <Vendor showVendor={showVendor || new URLSearchParams(window.location.search).get('page') === 'vendor'} setShowVendor={() => setShowVendor(false)} access={access} />
            </div>
            <div className='footer'>
                <p style={{ fontSize: '10px', margin: '0px' }}>
                    Build Version {VERSION} Date {BUILD_DATE}
                </p>
                <p style={{ fontSize: '10px' }}>
                    © {new Date().getFullYear()} Novisync Inc.All Rights Reserved
                </p>
            </div>
        </div >
    )
}
